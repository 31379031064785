<div class="orgbrain-style">
  <orgbrain-dialog-title [title]="title" (closeClick)="dialogRef.close(reverseLogic)">
  </orgbrain-dialog-title>

  <div class="d-flex justify-between flex-col">
    <mat-dialog-content>
      <p class="mat-body" *ngIf="!isQuestionAnArray(); else questionArrayTemplate">
        <span [innerHTML]="question"></span>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mb-0 pb-0">
      <button
        mat-button
        class="action-button"
        color="primary"
        id="confirm_dialog_no_button"
        *ngIf="noOptionTitle"
        (click)="dialogRef.close(reverseLogic)"
        cdkFocusInitial
      >
        {{ noOptionTitle }}
      </button>

      <button
        mat-button
        color="primary"
        class="action-button"
        id="confirm_dialog_yes_button1"
        *ngIf="alternativeOptionTitle"
        (click)="dialogRef.close(1)"
      >
        {{ alternativeOptionTitle }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="action-button"
        id="confirm_dialog_yes_button"
        *ngIf="yesOptionTitle"
        (click)="dialogRef.close(!reverseLogic)"
      >
        {{ yesOptionTitle }}
      </button>
    </mat-dialog-actions>
  </div>
  <ng-template #questionArrayTemplate>
    <p class="mat-body" *ngFor="let q of question" [innerHTML]="q"></p>
  </ng-template>
</div>
