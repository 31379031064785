import { Component, Input } from '@angular/core';

// Angular component

@Component({
  selector: 'orgbrain-warning-box',
  template: `<div
    class="d-flex mbx-4 px-4 py-2 items-center w-fit"
    [style.background-color]="getBackgroundColor()"
    style="border-radius: 10px;"
  >
    <span>
      <mat-icon [style.color]="getIconColor()" class="none-sm mrx-4">{{ icon }}</mat-icon>
      <ng-content></ng-content
    ></span>
  </div>`,
})
export class WarningBoxComponent {
  @Input() text: string = '';
  @Input() icon: 'warning' | 'info' | 'error' = 'warning';

  getBackgroundColor(): string {
    switch (this.icon) {
      case 'warning':
        return '#c73c111a';
      case 'info':
        return '#0d6efd1a';
      case 'error':
        return '#dc35451a';
      default:
        return '#c73c111a';
    }
  }

  getIconColor(): string {
    switch (this.icon) {
      case 'warning':
        return '#c73c11';
      case 'info':
        return '#0d6efd';
      case 'error':
        return '#dc3545';
      default:
        return '#c73c11';
    }
  }
}
