import { BaseSteps } from '../../dirents';

export const textMappings = {
  BogdanBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  join: {
    en: 'Join',
    nb: 'Bli med',
    sv: 'Ansluta sig till',
  },
  NOBODY: {
    en: 'Nobody',
    nb: 'Ingen',
  },
  'share-registry': {
    en: 'Suggestion generated from the shareholder registry',
    nb: 'Forslag ble generert med data fra aksjonærregisteret',
    sv: 'Ansluta sig till',
  },
  'tax-office': {
    en: 'Suggestion generated from the Norwegian tax office',
    nb: 'Forslag ble generert med data fra Skatteetaten',
    sv: 'Ansluta sig till',
  },
  'tax-and-registry': {
    en: 'Suggestion generated from the Norwegian tax office and Orgbrain',
    nb: 'Forslag ble generert med data fra Skatteetaten og Orgbrain',
    sv: 'Ansluta sig till',
  },
  newTaskModuleLabel: {
    en: 'Create new task',
    nb: 'Opprett ny oppgave',
    sv: 'Ny uppgift',
  },
  MoreBeneficialOwners: {
    nb: 'Mangler du opplysninger for å kunne identifisere noen av virksomhetens reelle rettighetshavere?',
    en: "Do you lack information to identify some of the organization's beneficial owners?",
    sv: '',
  },
  MoreBeneficialOwnersHelpText: {
    nb: 'For å kunne identifisere reelle rettighetshavere må du ha opplysninger om fødselsnummer eller fødselsdato, navn, bostedsland og statsborgerskap',
    en: 'To identify beneficial owners, you need information about the national identity number or date of birth, name, country of residence and citizenship',
    sv: '',
  },
  UnidentifedBeneficialOwners: {
    en: 'The organization has one or more beneficial owners which can not be identified',
    nb: 'Virksomheten har en eller flere reelle eiere som ikke kan identifiseres',
    sv: '',
  },
  CreateNewMeetingModuleLabel: {
    en: 'New meeting',
    nb: 'Nytt møte',
    sv: '',
  },
  CreateAnnualPlanModuleLabel: {
    en: 'New annual plan',
    nb: 'Ny årsplan',
    sv: '',
  },
  SearchInFiles: {
    en: 'Search in files',
    nb: 'Søk i filer',
    sv: '',
  },
  ChooseMeetingType: {
    en: 'Choose meeting type',
    nb: 'Velg møtetype',
    sv: '',
  },
  MeetingTypeWarning: {
    en: 'NOTE: This can not be changed after the meeting has been created',
    nb: 'OBS: Dette får du ikke endret på etter du har opprettet møtet',
    sv: '',
  },
  UseExternalVideoConferance: {
    en: 'Use external video conferance',
    nb: 'Bruk en annen videokonferansetjeneste',
    sv: '',
  },

  SUBSCRIPTION_PACKAGE: {
    en: 'Subscription',
    nb: 'Abonnement',
    sv: 'Prenumeration',
  },
  DownloadDate: {
    en: 'What date would you like to retrieve the snapshot from?',
    nb: 'Hvilken dato ønsker du å hente øyeblikksbildet fra?',
  },
  DownloadDateTip: {
    en: 'Events on the specified date are included. The situation at the start of the day is available by selecting the previous date.',
    nb: 'Hendelser på angitt dato inkluderes. Situasjonen ved starten av dagen er tilgjengelig ved å velge foregående dato.',
  },
  FileNameText: {
    en: 'Name of the file',
    nb: 'Navn på filen',
  },
  SUBSCRIPTION_PACKAGE_UPGRADE: {
    en: 'Subscription update',
    nb: 'Abonnementsoppdatering',
    sv: 'Prenumerationsuppdatering',
  },
  SUBSCRIPTION_PACKAGE_USER_EXTENSION: {
    en: 'Subscription update: extra users',
    nb: 'Abonnementsoppdatering: ekstra brukere',
    sv: 'Prenumerationsuppdatering: extra användare',
  },
  SUBSCRIPTION_PACKAGE_REMAINDER: {
    en: 'Remainder from previous subscription',
    nb: 'Resten fra forrige abonnement',
    sv: 'Resterande från tidigare prenumeration',
  },
  EmailSent: {
    en: 'Email sent',
    nb: 'E-post sendt',
    sv: 'E-post skickad',
  },
  EmailSendtToYou: {
    en: 'An email has been sent to you',
    nb: 'En e-post er sendt til deg',
    sv: 'Ett e-postmeddelande har skickats till dig',
  },
  NextEmail: {
    en: 'Next email',
    nb: 'Neste e-post',
    sv: 'Nästa e-post',
  },
  PreviousEmail: {
    en: 'Previous email',
    nb: 'Forrige e-post',
    sv: 'Föregående e-post',
  },
  'Subscription has changed': {
    en: 'Subscription has changed',
    nb: 'Abonnementet er endret',
    sv: 'Prenumerationen har ändrats',
  },
  CreatedByOrgbrain: {
    en: 'Retrieved from shareholder registry in Orgbrain for ',
    nb: 'Hentet fra aksjeeierbok i Orgbrain for ',
    sv: 'Hentet fra aksjeeierbok i Orgbrain for ',
  },

  NobodyToInvite: {
    en: 'Nobody to invite',
    nb: 'Ingen å invitere',
  },
  toShareclass: {
    en: 'To shareclass',
    nb: 'Til aksjeklasse',
  },
  fromShareclass: {
    en: 'From shareclass',
    nb: 'Fra aksjeklasse',
  },
  originalValue: {
    en: 'Original value',
    nb: 'Opprinnelig verdi',
  },
  'Users before': {
    en: 'Users before',
    nb: 'Brukere før',
    sv: 'Användare tidigare',
  },
  SIGNATURE_CRIIPTO: {
    en: 'Signature Bank ID',
    nb: 'Signering Bank ID',
    sv: 'Signatur Bank ID',
  },
  'Variable costs': {
    en: 'Variable costs',
    nb: 'Variable kostnader',
    sv: 'Användare tidigare',
  },
  'Users after': {
    en: 'Users after',
    nb: 'Brukere etter',
    sv: 'Användare efter',
  },
  previewTheEmail: {
    en: 'Previewing the email',
    nb: 'Forhåndsvisning av e-posten',
  },
  emailsendt: { en: 'Email is sent', nb: 'E-post er sendt' },
  emailsendtmsg: {
    en: 'A test has now been sent to your email address',
    nb: 'En test er nå send til din e-postadresse',
  },
  sendsMailToYourselfTooltip: {
    en: 'Sends an email to your own email address',
    nb: 'Sender en e-post til din egen e-postadresse',
  },
  sendTestEmail: { en: 'Send test email', nb: 'Send test e-post' },
  openPreview: {
    en: 'Preview email',
    nb: 'Forhåndsvis e-post',
  },
  mattooltipMessageOfTransaction: {
    en: 'Here you can choose whether to send an email notification about the entry in the shareholder register, cf. the Companies Act § 4-10. The message will contain information about transaction type, time, seller, recipient, share class, quantity and new shareholding.',
    nb: 'Her kan du velge om det skal gå ut melding på e-post om innføring i aksjeeierboken, jf. aksjeloven § 4-10. Meldingen vil inneholde opplysninge om transaksjonstype, tidspunkt, avhender, mottaker, aksjeklasse, antall og ny aksjebeholdning.',
  },
  NoticeOfIntroductionToTheShareRegister: {
    en: 'Notification of entry in the shareholder register',
    nb: 'Melding om  innføring i aksjeeierboken',
  },
  SendEmailToSeller: {
    en: 'Send an email to the seller?',
    nb: 'Send epost til selger?',
  },
  SendEmailToBuyer: {
    en: 'Send an email to the recipient??',
    nb: 'Send epost til mottaker?',
  },
  TransactionInfoBox: {
    en: 'Register a transaction (e.g., purchase/sale) that is automatically updated in the shareholder register. You can only register one transaction at a time. (Note: You also have the option to upload spreadsheets with multiple transactions through "Import transactions"). You can choose to send a message to the seller and buyer regarding the entry in the shareholder register.',
    nb: 'Her kan du registrere en transaksjon (for eksempel kjøp/salg) som automatisk blir oppdatert i aksjeeierboken. Du kan kun registrere én transaksjon om gangen (NB: Du har også mulighet til å laste regneark med flere transaksjoner via «Aksjedata»). Du har også mulighet til å velge om det skal sendes ut melding til avhender og mottaker om innføring i aksjeeierboken.',
  },
  seller: {
    en: 'Seller',
    nb: 'Selger',
  },
  TimeOfTransaction: {
    en: 'Time of transaction:',
    nb: 'Transaksjonstidspunkt:',
  },
  warningText1: {
    nb: 'Transaksjonstidspunktet er før siste hendelse.',
    en: 'The transaction date is before the last event.',
  },
  warningText2: {
    nb: 'Dette kan være problematisk dersom denne transaksjonen endrer på forutsetninger som etterfølgende hendelser er avhengig av (f.eks. dersom en aksjonær selger aksjer som allerede er solgt).',
    en: 'This can be problematic if this transaction changes assumptions that subsequent events depend on (e.g., if a shareholder sells shares that have already been sold).',
  },
  haveSnapshotsWarningText1: {
    nb: 'NB: Det finnes et øyeblikksbilde etter transaksjonstidspunktet.',
    en: 'NB: There is a snapshot after the transaction time.',
  },
  haveSnapshotsWarningText2: {
    nb: 'Dette vil føre til at tidspunktet blir ansett som ugyldig dersom antallet overstiger det selger har av tilgjengelige aksjer.',
    en: 'This will cause the time to be considered invalid if the number exceeds what the seller has of available shares.',
  },
  SendPreview: {
    en: 'Send test to me',
    nb: 'Send test til meg',
  },
  BOARD_PORTAL: {
    en: 'Board portal',
    nb: 'Styreportal',
    sv: 'Styrelsens portal',
  },
  INVOICE_PERIOD: {
    en: 'Invoice period',
    nb: 'Fakturaperiode',
    sv: 'Fakturaperiod',
  },
  PER_MNTH: {
    en: 'per month',
    nb: 'pr. mnd.',
    sv: 'pr. mnd.',
  },
  USER_PACKAGE: {
    en: 'Users',
    nb: 'Brukere',
    sv: 'Användare',
  },
  YEARLY_AGREEMENT: {
    en: 'Annual agreement',
    nb: 'Årsavtale',
    sv: 'Årsavtal',
  },
  MONTHLY_AGREEMENT: {
    en: 'Monthly agreement',
    nb: 'Månedlig avtale',
    sv: 'Månadsavtal',
  },
  SUBGROUP_USER_PACKAGE: {
    en: 'Subgroup users',
    nb: 'Undergruppebrukere',
    sv: 'Undergruppsanvändare',
  },
  DISCOUNT_CASH_TOTAL: {
    en: 'Money discount',
    nb: 'Penger rabatt',
    sv: 'Pengar rabatt',
  },
  DISCOUNT_INVOICE: {
    en: 'Discount',
    nb: 'Rabatt',
    sv: 'Rabatt',
  },
  DISCOUNT_PERCENT_TOTAL: {
    en: 'Percentage discount',
    nb: 'Prosentvis rabatt',
    sv: 'Procentuell rabatt',
  },
  DD_PROCESSES_GROUPS: {
    en: 'Due diligence processes',
    nb: 'Due diligence-prosesser',
    sv: 'Due diligence-processer',
  },
  FOLLOW_UP_LABEL: {
    en: 'Follow-up',
    nb: 'Oppfølging',
    sv: 'Uppföljning',
  },
  DECISION_LABEL: {
    en: 'Decision',
    nb: 'Beslutning',
    sv: 'Beslut',
  },
  RISK_AND_COMPLIANCE_LABEL: {
    en: 'Risk and compliance',
    nb: 'Risiko og compliance',
    sv: 'Risk och efterlevnad',
  },
  STRATEGIC_PLANNING_LABEL: {
    en: 'Strategic planning',
    nb: 'Strategisk planlegging',
    sv: 'Strategisk planering',
  },
  DATAROOM_STORAGE: {
    en: 'Dataroom storage',
    nb: 'Datarom lagring',
    sv: 'Datarumslagring',
  },
  SIGNING_DOCUMENTS: {
    en: 'Signing documents',
    nb: 'Signering av dokumenter',
    sv: 'Underteckna dokument',
  },
  NOK: {
    en: 'NOK',
    nb: 'kr',
    sv: 'kr',
  },
  NotificationSettings: {
    en: 'Notification settings',
    nb: 'Varsler',
    sv: '',
  },
  NotificationSettingsInfo: {
    en: 'Here you can change the what notification type you get when certain events in Orgbrain happen. These settings only affect your currently selected organization',
    nb: 'Her kan du stille inn hvilke varsler du får når det skjer hendelser i Orgbrain. Disse innstillingene påvirker bare den organisasjon du er inne på nå',
    sv: '',
  },
  SavedSettings: {
    en: 'Your settings are saved',
    nb: 'Dine innstillinger er lagret',
    sv: '',
  },
  EMAILOption: {
    en: 'Email',
    nb: 'E-post',
    sv: 'kr',
  },
  EMAILOptionTooltip: {
    en: 'Send the notification on email.',
    nb: 'Send varselen på E-post.',
    sv: 'kr',
  },
  BELLOption: {
    en: 'Bell',
    nb: 'Bjelle',
    sv: 'kr',
  },
  BELLOptionTooltip: {
    en: 'Add notification to the bell.',
    nb: 'Legg til varselen i bjellen.',
    sv: 'kr',
  },
  PUSHOption: {
    en: 'Push-notification',
    nb: 'Push-varsel',
    sv: 'kr',
  },
  Label: {
    en: 'Labels',
    nb: 'Merkelapper',
    sv: 'kr',
  },
  NewLabel: {
    en: 'Create label',
    nb: 'Opprett merkelapp',
    sv: 'kr',
  },
  LabelName: {
    en: 'Choose label name',
    nb: 'Velg merkelapp navn',
    sv: 'kr',
  },
  ChooseColor: {
    en: 'Choose label color',
    nb: 'Velg merkelapp farge',
    sv: 'kr',
  },
  CreateLabel: {
    en: 'Create label',
    nb: 'Opprett merkelapp',
    sv: 'kr',
  },

  PUSHOptionTooltip: {
    en: 'Send this notification as a push notification on your computer or phone along with a sound. You have to turn on webpush for this function to work.',
    nb: 'Send denne varselen som en push varsel på datamaskinen eller telefonen din, sammen med en lyd. Du må skru på webpush for at denne funksjonen skal fungere.',
    sv: 'kr',
  },
  BATCHOption: {
    en: 'Email batch',
    nb: 'E-post batch',
    sv: 'kr',
  },
  BATCHOptionTooltip: {
    en: 'Send this along with similar notifications in a batch every 2 hours',
    nb: 'Send sammen med like varsler i en gruppe hver andre time',
    sv: 'kr',
  },
  CreatedConversation: {
    en: ' created a new conversation',
    nb: ' started en ny samtale',
    sv: 'kr',
  },
  CreatedAt: {
    en: 'Fetched from the share register in Orgbrain ',
    nb: 'Hentet fra aksjeeierboken i Orgbrain ',
    sv: 'PDF Opprettet: ',
  },
  DemoteAdmin: {
    en: 'Remove conversation admin privileges',
    nb: 'Fjern samtaleadministrator tillatelser',
    sv: 'kr',
  },
  MakeCaseFromTask: {
    nb: 'Agendapunkt',
    en: 'Agenda item',
  },
  MakeCaseFromTaskTooltip: {
    nb: 'Opprett et nytt agendapunkt fra denne oppgaven',
    en: 'Create a new agenda item from this task',
  },

  DeleteContactConfirm: {
    en: 'This will delete this contact group forever. None of the conversations connected with the contact group will be affected',
    nb: 'Dette vil slette denne kontaktgruppen permanent. Ingen av samtalene knyttet til denne kontaktgruppen vil bli påvirket',
    sv: 'kr',
  },
  imageTextForWebpush: {
    en: '<Image>',
    nb: '<Bilde>',
    sv: '<Bilde>',
  },
  repeatConversationTitle: {
    en: 'Similar Conversation Exists',
    nb: 'Lignende samtale finnes allerede',
    sv: 'Liknande konversation finns redan',
  },
  repeatConversationBody: {
    en: 'A conversation with the same members already exists. Would you like to start a new one or open the existing conversation?',
    nb: 'En samtale med de samme medlemmene finnes allerede. Vil du opprette en ny, eller åpne den eksisterende samtalen?',
    sv: 'En konversation med samma medlemmar finns redan. Vill du skapa en ny eller öppna den befintliga konversationen?',
  },
  StartNewConversation: {
    en: 'Start New Conversation',
    nb: 'Start ny samtale',
    sv: 'Starta ny konversation',
  },
  OpenAlreadyExisting: {
    en: 'Open Conversation',
    nb: 'Åpne samtale',
    sv: 'Öppna konversation',
  },
  SimilarConversations: {
    en: 'Similar Conversations',
    nb: 'Lignende samtaler',
    sv: 'Öppna konversation',
  },

  LeaveCurrentMeetingBeforeJoin: {
    en: 'Leave current call before joining new',
    nb: 'Forlat ditt nåværende videomøte for å bli med i et nytt',
    sv: 'Forlat ditt nåværende videomøte for å bli med i et nytt',
  },
  PromoteAdmin: {
    en: 'Give user conversation admin privileges',
    nb: 'Gi brukeren samtaleadministrator tillatelser',
    sv: 'kr',
  },
  LeaderRequired: {
    en: 'All conversations need atleast one user with conversation admin privileges',
    nb: 'Alle samtaler må ha ihvertfall en bruker med samtaleadministrator tillatelser',
    sv: 'kr',
  },

  ddProcesses: {
    en: 'Due diligence processes',
    nb: 'Due diligence-prosesser',
    sv: 'Due diligence-processer',
  },
  NIF_START: {
    en: 'NIF Standard',
    nb: 'NIF Standard',
    sv: 'NIF Standard',
  },
  NIF_PRO: {
    en: 'NIF Pro',
    nb: 'NIF Pro',
    sv: 'NIF Pro',
  },
  DD_SUBSCRIPTION: {
    en: 'Due Diligence',
    nb: 'Due Diligence',
    sv: 'Due Diligence',
  },
  FOUNDER2024: {
    en: 'Founder',
    nb: 'Gründer',
    sv: 'Grundare',
  },
  SMALL_BUSINESS2024: {
    en: 'Small business',
    nb: 'Lite foretak',
    sv: 'Litet företag',
  },
  STANDARD2024: {
    en: 'Standard',
    nb: 'Standard',
    sv: 'Standard',
  },
  SHAREHOLDER_BASIS: {
    en: 'Shareholder Basis',
    nb: 'Aksjonærer Basis',
  },
  SHAREHOLDER_PRO: {
    en: 'Shareholders Pro',
    nb: 'Aksjonærer Pro',
  },
  ACADEMY_2025: {
    en: 'Academy & Board School',
    nb: 'Academy & Styreskolen',
  },
  BOARD_EVALUATION: {
    en: 'Board evaluation',
    nb: 'Styreevaluering',
  },
  SIGNATURE_PACKAGE: {
    en: 'Signature package',
    nb: 'Signaturpakke',
  },
  SECURITY_PRO: {
    en: 'Security pro',
    nb: 'Sikkerhet pro',
  },
  DISCOUNT_USER_COUNT_GENERAL: {
    en: 'Number of free users',
    nb: 'Antall gratis brukere',
    sv: 'Antal gratisanvändare',
  },
  DISCOUNT_USER_COUNT_SUBGROUP: {
    en: 'Number of free subgroup users',
    nb: 'Antall gratis undergruppebrukere',
    sv: 'Antal gratis undergruppsanvändare',
  },
  DISCOUNT_USER_COUNT_ROLE_BASED: {
    en: 'Number of free users by role',
    nb: 'Antall gratis brukere etter rolle',
    sv: 'Antal lediga användare per roll',
  },
  Removed: {
    en: ' removed ',
    nb: ' fjernet ',
    sv: ' fjernet',
  },
  RemovedUppercase: {
    en: 'Removed ',
    nb: 'Fjernet ',
    sv: 'Fjernet',
  },
  FromGroup: {
    en: ' from the conversation',
    nb: ' fra samtalen',
    sv: '',
  },
  Added: {
    en: ' added ',
    nb: ' la til ',
    sv: '',
  },
  AddedUppercase: {
    en: 'Added ',
    nb: 'La til ',
    sv: '',
  },
  AddedToConversationTitle: {
    en: 'Added in ',
    nb: 'Lagt til i ',
    sv: '',
  },
  AddedToConversation1: {
    en: 'You were added in the conversation',
    nb: 'Du ble lagt til i samtalen',
    sv: '',
  },
  AddedToConversation2: {
    en: 'by',
    nb: 'av',
    sv: '',
  },
  RemovedFromConversationTitle: {
    en: 'Removed from ',
    nb: 'Fjernet fra ',
    sv: '',
  },
  RemovedFromConversation: {
    en: 'You were removed from the conversation',
    nb: 'Du ble fjernet fra samtalen',
    sv: '',
  },

  MentionedInConversation: {
    en: 'has mentioned you in the conversation',
    nb: 'har nevnt deg i samtalen',
    sv: '',
  },
  MentionedInConversationTitle: {
    en: 'mentioned you',
    nb: 'nevnte deg',
    sv: '',
  },
  ToGroup: {
    en: ' to the conversation',
    nb: ' i samtalen',
    sv: '',
  },
  LeftGroup: {
    en: ' left the conversation',
    nb: ' forlot samtalen',
    sv: '',
  },
  RenamedConversation: {
    en: ' renamed the conversation to ',
    nb: ' endret navnet på samtalen til ',
    sv: '',
  },
  RenamedConversationUppercase: {
    en: 'Renamed conversation ',
    nb: 'Endret navnet på samtalen ',
    sv: '',
  },
  ChangeConversationName: {
    en: 'Rename the conversation',
    nb: 'Endre navnet på samtalen',
    sv: '',
  },

  ChangeConversationTopic: {
    en: 'Change conversation topic',
    nb: 'Endre samtaleemne',
    sv: '',
  },

  CreateConversationFromNewsTooltip: {
    en: 'Here you can start a conversation about this message with the rest of the organization.',
    nb: 'Her kan du opprette en samtale om denne meldingen med resten av virksomheten',
    sv: '',
  },

  CreateConversation: {
    en: 'Create conversation',
    nb: 'Start samtale',
    sv: '',
  },
  AddReaction: {
    en: 'Find other reaction',
    nb: 'Finn en annen reaksjon',
    sv: 'Ny konversation',
  },
  ConversationAdmin: {
    en: 'This user has admin permissions in this conversation',
    nb: 'Denne brukeren har admin tilgang i denne samtalen',
    sv: 'Ny konversation',
  },

  Boardchat: { en: 'Board Chat', nb: 'Styrets nettprat (chat)' },
  NewConversation: {
    en: 'New conversation',
    nb: 'Ny samtale',
    sv: 'Ny konversation',
  },
  ConversationName: {
    en: 'Conversation name (if desired)',
    nb: 'Tittel for samtalen (om ønskelig)',
    sv: '',
  },
  Conversations: {
    en: 'Conversations',
    nb: 'Samtaler',
    sv: 'Konversationer',
  },
  StartCall: {
    en: 'Join the call',
    nb: 'Bli med i videomøte',
    sv: 'Bli med i videomøte',
  },
  JoinedCall: {
    en: ' started a call',
    nb: ' startet et videomøte',
    sv: ' ble med i videomøte',
  },
  StartCallUppercase: {
    en: 'Started a call',
    nb: 'Startet et videomøte',
    sv: ' ble med i videomøte',
  },
  CallEndedUppercase: {
    en: 'Call ended',
    nb: 'Videomøtet er avsluttet',
    sv: ' ble med i videomøte',
  },
  CallEnded: {
    en: 'All members have left the call',
    nb: 'Alle medlemmer har forlatt videomøtet',
    sv: ' Videomøtet ble avslutte',
  },

  AddToConversation: {
    en: 'Add to conversation',
    nb: 'Legg til i samtale',
    sv: 'Lägg till i konversation',
  },
  MembersInConversation: {
    en: 'Members in conversation',
    nb: 'Medlemmer i samtalen',
    sv: 'Medlemmar i konversationen',
  },
  LeaveConversation: {
    en: 'Leave conversation',
    nb: 'Forlat samtale',
    sv: 'Lämna konversation',
  },
  RemoveFromGroup: {
    en: 'Remove from conversation',
    nb: 'Fjern fra samtalen',
    sv: 'Ta bort från konversationen',
  },
  AddPeopleToConversation: {
    en: 'Add people to conversation',
    nb: 'Legg til personer i samtalen',
    sv: 'Lägg till personer i konversationen',
  },
  CreateNewConversation: {
    en: 'Create new conversation',
    nb: 'Opprett ny samtale',
    sv: 'Skapa ny konversation',
  },
  MakeTask: {
    en: `Make task`,
    nb: 'Lag oppgave',
    sv: 'Skapa ny konversation',
  },
  MakeTaskTooltip: {
    en: `Here you can create a new task which is based on this message.`,
    nb: 'Her kan du lage en ny oppgave som er basert på denne meldingen.',
    sv: 'Skapa ny konversation',
  },
  Leave: {
    en: 'Leave',
    nb: 'Forlat',
    sv: 'Lämna',
  },
  Remove: {
    en: 'Remove',
    nb: 'Fjern',
    sv: 'Ta bort',
  },
  LeaveConversationText: {
    en: 'Do you want to leave the conversation?',
    nb: 'Vil du forlate samtalen?',
    sv: 'Vill du lämna konversationen?',
  },
  RemoveMembersFromConversationText: {
    en: 'Do you want to remove this member from the conversation?',
    nb: 'Ønsker du å fjerne dette medlemmet fra samtalen?',
    sv: 'Ta bort medlemmar från konversationen?',
  },
  DescriptionHasChanged: {
    en: 'Task has been changed by someone else. If you save now - you will overwrite the changes.',
    nb: 'Oppgaven har blitt endret av noen andre. Hvis du lagrer nå - vil du overskrive endringene.',
  },

  WantToDeleteChatMessage: {
    en: 'Are you sure you want to delete this message?',
    nb: 'Er du sikker på at du vil slette denne meldingen?',
  },
  BeneficialOwner: {
    en: 'Beneficial owner',
    nb: 'Reell rettighetshaver',
  },
  BenficialOwnerEmailSubject: {
    en: 'Registered as beneficial owner',
    nb: 'Registrert som reell rettighetshaver',
  },
  BenficialOwnerBoldText1: {
    en: ' has registered ',
    nb: ' har registret ',
  },
  BenficialOwnerBoldText2: {
    en: ' as a beneficial owner.',
    nb: ' som reell rettighetshaver.',
  },
  BenficialOwnerText00: {
    en: 'In accordance with the regulation to the law on the register of beneficial owners § 2-6, ',
    nb: 'I henhold til forskrift til lov om register over reelle rettighetshavere § 2-6 er ',
  },
  BenficialOwnerText01: {
    en: ' is obliged to notify you of this. ',
    nb: ' forpliktet til å varsle deg om dette. ',
  },
  BenficialOwnerText1: {
    en: 'I henhold til forskrift til lov om register over reelle rettighetshavere § 2-6 er forpliktet til å varsle deg om dette.  The information is reported to the register of beneficial owners. If you believe this is incorrect, please contact the company',
    nb: 'Opplysningene meldes inn til registeret over reelle rettighetshavere. Dersom du mener at dette ikke er korrekt, ber vi deg ta kontakt med selskapet',
  },
  BenficialOwnerText2: {
    en: 'More information about beneficial owners can be found here: https://www.brreg.no/reelle-rettighetshavere/',
    nb: 'Mer informasjon om reelle rettighetshavere finner du her: https://www.brreg.no/reelle-rettighetshavere/',
  },
  OwnerMissingEmail: {
    en: 'Some beneficial owners are missing email addresses. Please add email addresses to all beneficial owners before sending the email',
    nb: 'Noen reelle rettighetshavere mangler e-postadresser. Vennligst legg til e-postadresser på alle reelle rettighetshavere før du sender e-posten',
    sv: 'x6',
  },
  ErrorInSendingEmail: {
    en: 'Error when sending email',
    nb: 'Feil ved sending av e-post',
    sv: 'x6',
  },
  EmailSentToBeneficialOwner: {
    en: 'Email sent to beneficial owner',
    nb: 'E-post sendt til reell rettighetshaver',
    sv: 'x6',
  },
  SigningMethod: {
    en: 'Signing method',
    nb: 'Signeringsmetode',
  },
  completeMeetingAction: {
    en: 'Complete meeting',
    nb: 'Fullfør møtet',
  },
  annualPlanCreated: {
    en: 'Annual plan created',
    nb: 'Årsplan opprettet',
  },
  annualPlanDeleted: {
    en: 'Annual plan deleted',
    nb: 'Årsplan slettet',
  },
  BogdanEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  PaulBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  [BaseSteps._SHARED_WITH_ME_]: {
    en: 'Shared with me',
    nb: 'Delt med meg',
    sv: 'Delat med mig',
  },
  [BaseSteps._SHARED_I_CONTROL_]: {
    en: 'Shares I control',
    nb: 'Delinger jeg kontrollerer',
    sv: 'Delningar jag kontrollerar',
  },
  PaulEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  EivindBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  EivindEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  PederBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  Search: {
    en: 'Search',
    nb: 'Søk',
  },
  NoCurrentContracts: {
    en: 'No contracts are currently active',
    nb: 'Ingen kontrakter er aktive.',
  },
  Home: {
    en: 'Home',
    nb: 'Hjem',
    sv: 'Hem',
  },
  PederEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  ViktorBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  ViktorEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  SondreBegin: {
    en: 'x1',
    nb: 'x2',
    sv: 'x3',
  },
  SondreEnd: {
    en: 'x4',
    nb: 'x5',
    sv: 'x6',
  },
  Next: {
    en: 'Next',
    nb: 'Neste',
  },
  MeetingSubtitle: {
    en: 'Subtitle',
    nb: 'Undertittel',
  },

  proceedDataUnsavedText: {
    nb: 'Er du sikker på om at du vil forkaste endringene dine?',
    en: 'Are you sure you want to discard your changes?',
    sv: 'x6',
  },
  MeetingNr: {
    nb: 'Møte nr.',
    en: 'Meeting No.',
  },
  BasicInformationMeeting: {
    en: 'Meeting information',
    nb: 'Møteinformasjon',
  },
  FormOfMeeting: {
    en: 'Meeting format',
    nb: 'Møteform',
  },
  ExtraSettingsMeeting: {
    en: 'Ekstra settings',
    nb: 'Ekstra detaljer',
  },
  MeetingTitle: {
    en: 'Subtitle',
    nb: 'Undertittel',
  },
  CreateNewMeeting: {
    en: 'Create new meeting',
    nb: 'Opprett nytt møte',
  },
  MeetingStatus: {
    en: 'Møtestatus',
    nb: 'Meeting status',
  },
  Reminder: {
    en: 'Reminder',
    nb: 'Påminnelse',
    sv: 'Påminnelse',
  },
  and: {
    en: 'and',
    nb: 'og',
    sv: 'och',
  },
  of: {
    en: 'of',
    nb: 'av',
    sv: 'av',
  },
  Page: {
    en: 'Page',
    nb: 'Side',
    sv: 'Sida',
  },
  Pages: {
    en: 'Pages',
    nb: 'Sider',
    sv: 'Sidor',
  },
  InviteInvestors: {
    en: 'Invite investors',
    nb: 'Inviter investorer',
    sv: 'Bjud in investerare',
  },
  SendReminder: {
    en: 'Send reminder',
    nb: 'Send påminnelse',
    sv: 'Skicka påminnelse',
  },
  Contact: {
    en: 'Contact',
    nb: 'Kontakt',
    sv: 'Skicka påminnelse',
  },

  'This document package contains': {
    en: 'This document package contains',
    nb: 'Denne dokumentpakken inneholder',
  },
  'Signing of encrypted files is not supported': {
    en: 'Signing of encrypted files is not supported',
    nb: 'Signering av kryptert fil støttes ikke',
  },
  'The original document': {
    en: 'The original document',
    nb: 'Det originale dokumentet',
  },
  'This signature page at the end': {
    en: 'This signature page at the end',
    nb: 'Denne signatursiden på slutten',
  },
  'Signature pages at the end': {
    en: 'Signature pages at the end',
    nb: 'Signatur sidene på slutten',
  },
  'COUNTRY:NO': {
    en: 'Norway',
    nb: 'Norge',
    sv: 'Norge',
  },
  'COUNTRY:SE': {
    en: 'Sweden',
    nb: 'Sverige',
    sv: 'Sverige',
  },
  'COUNTRY:DK': {
    en: 'Denmark',
    nb: 'Danmark',
    sv: 'Danmark',
  },
  'registertype.registerOverReelleRettighetshavereIBelgia': {
    en: 'Belgium',
    nb: 'Belgia',
  },
  'registertype.registerOverReelleRettighetshavereIBulgaria': {
    en: 'Bulgaria',
    nb: 'Bulgaria',
  },
  'registertype.registerOverReelleRettighetshavereIDanmark': {
    en: 'Denmark',
    nb: 'Danmark',
  },
  'registertype.registerOverReelleRettighetshavereIEstland': {
    en: 'Estonia',
    nb: 'Estland',
  },
  'registertype.registerOverReelleRettighetshavereIFinland': {
    en: 'Finland',
    nb: 'Finland',
  },
  'registertype.registerOverReelleRettighetshavereIFrankrike': {
    en: 'France',
    nb: 'Frankrike',
  },
  'registertype.registerOverReelleRettighetshavereIHellas': {
    en: 'Greece',
    nb: 'Hellas',
  },
  'registertype.registerOverReelleRettighetshavereIIrland': {
    en: 'Ireland',
    nb: 'Irland',
  },
  'registertype.registerOverReelleRettighetshavereIIsland': {
    en: 'Iceland',
    nb: 'Island',
  },
  'registertype.registerOverReelleRettighetshavereIItalia': {
    en: 'Italy',
    nb: 'Italia',
  },
  'registertype.registerOverReelleRettighetshavereIKroatia': {
    en: 'Croatia',
    nb: 'Kroatia',
  },
  'registertype.registerOverReelleRettighetshavereIKypros': {
    en: 'Cyprus',
    nb: 'Kypros',
  },
  'registertype.registerOverReelleRettighetshavereILatvia': {
    en: 'Latvia',
    nb: 'Latvia',
  },
  'registertype.registerOverReelleRettighetshavereILiechtenstein': {
    en: 'Liechtenstein',
    nb: 'Liechtenstein',
  },
  'registertype.registerOverReelleRettighetshavereILitauen': {
    en: 'Lithuania',
    nb: 'Litauen',
  },
  'registertype.registerOverReelleRettighetshavereILuxembourg': {
    en: 'Luxembourg',
    nb: 'Luxembourg',
  },
  'registertype.registerOverReelleRettighetshavereIMalta': {
    en: 'Malta',
    nb: 'Malta',
  },
  'registertype.registerOverReelleRettighetshavereINederland': {
    en: 'Netherlands',
    nb: 'Nederland',
  },
  'registertype.registerOverReelleRettighetshavereIPolen': {
    en: 'Poland',
    nb: 'Polen',
  },
  'registertype.registerOverReelleRettighetshavereIPortugal': {
    en: 'Portugal',
    nb: 'Portugal',
  },
  'registertype.registerOverReelleRettighetshavereIRomania': {
    en: 'Romania',
    nb: 'Romania',
  },
  'registertype.registerOverReelleRettighetshavereISlovakia': {
    en: 'Slovakia',
    nb: 'Slovakia',
  },
  'registertype.registerOverReelleRettighetshavereISlovenia': {
    en: 'Slovenia',
    nb: 'Slovenia',
  },
  'registertype.registerOverReelleRettighetshavereISpania': {
    en: 'Spain',
    nb: 'Spania',
  },
  'registertype.registerOverReelleRettighetshavereISverige': {
    en: 'Sweden',
    nb: 'Sverige',
  },
  'registertype.registerOverReelleRettighetshavereITsjekkia': {
    en: 'Czech Republic',
    nb: 'Tsjekkia',
  },
  'registertype.registerOverReelleRettighetshavereITyskland': {
    en: 'Germany',
    nb: 'Tyskland',
  },
  'registertype.registerOverReelleRettighetshavereIUngarn': {
    en: 'Hungary',
    nb: 'Ungarn',
  },
  'registertype.registerOverReelleRettighetshavereIOesterrike': {
    en: 'Austria',
    nb: 'Østerrike',
  },
  EMAIL_ATTACHMENTS: {
    en: 'Email attachments',
    nb: 'E-postvedlegg',
    sv: 'E-postbilagor',
  },
  PROXY_FOLDER: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  ATTACHMENTS: {
    en: 'Attachments',
    nb: 'Vedlegg',
    sv: 'Bilagor',
  },
  DRAFT: {
    en: 'Draft',
    nb: 'Kladd',
    sv: 'Förslag',
  },
  PLANNED: {
    en: 'Planned',
    nb: 'Planlagt',
    sv: 'Planerad',
  },
  CALLED: {
    en: 'Called',
    nb: 'Innkalt',
    sv: 'Kallad',
  },
  ACTIVE: {
    en: 'Active',
    nb: 'Aktivt',
    sv: 'Aktiva',
  },
  ANNUAL_PLAN: {
    en: 'Annual Plan',
    nb: 'Årsplan',
    sv: 'Årlig plan',
  },
  REQUIRES_SIGNATURES: {
    en: 'Signing',
    nb: 'Signering',
    sv: 'Signering',
  },
  COMPLETED: {
    en: 'Completed',
    nb: 'Fullført',
    sv: 'Färdigt',
  },
  CANCELLED: {
    en: 'Cancelled',
    nb: 'Avlyst',
    sv: 'Inställt',
  },
  TEST: {
    en: 'Trial (Expired)',
    nb: 'Prøve (Utløpende)',
    sv: 'Rättegång (Utlöper)',
  },
  TEST2024: {
    en: 'Trial',
    nb: 'Prøve',
    sv: 'Rättegång',
  },
  DEPENDENT: {
    en: 'Dependent',
    nb: 'Avhengig',
    sv: 'Beroende',
  },
  ALLSTATUS: {
    en: 'Alle statuses',
    nb: 'Alle statuser',
  },
  RELEVANTADS: {
    en: 'Relevant ads',
    nb: 'Aktuelle annonser',
  },
  RELEVANTCANDIDATES: {
    en: 'Relevant candidates',
    nb: 'Aktuelle kandidater',
  },
  POSITIVE: {
    en: 'Interesting',
    nb: 'Interessant',
  },
  NEGATIVE: {
    en: 'Not interesting',
    nb: 'Ikke interessant',
  },
  NEUTRAL: {
    en: 'Maybe interesting',
    nb: 'Kanskje interessant',
    sv: 'Kanskje interessant',
  },

  BOARDSCHOOL: {
    en: 'Board School',
    nb: 'Styreskolen',
    sv: 'Styrelseskolan',
  },
  SUSPENDED: {
    en: 'Expired',
    nb: 'Utgått',
    sv: 'Utgånget',
  },
  GRUNDER: {
    en: 'Founder (Expired)',
    nb: 'Gründer (Utløpende)',
    sv: 'Grundare (Utlöper)',
  },
  SMALL_BUSINESS: {
    en: 'Small business (Expired)',
    nb: 'Lite foretak (Utløpende)',
    sv: 'Litet företag (Utlöper)',
  },
  FOUNDER: {
    en: 'Founder package (Expired)',
    nb: 'Gründerpakke (Utløpende)',
    sv: 'Grundarpaket (Utlöper)',
  },
  SMALL_BUSINESS2: {
    en: 'Small business (Expired)',
    nb: 'Lite foretak (Utløpende)',
    sv: 'Småföretagspaket (Utlöper)',
  },
  BASIS: {
    en: 'Standard (Expired)',
    nb: 'Standard (Utløpende)',
    sv: 'Standard (Utlöper)',
  },
  ENTERPRISE: {
    en: 'Enterprise',
    nb: 'Enterprise',
    sv: 'Företag',
  },
  PersonalFiles: {
    en: 'Personal Files',
    nb: 'Personlige filer',
    sv: 'Personliga filer',
  },
  MeetingFiles: {
    en: 'Meeting Files',
    nb: 'Møtefiler',
    sv: 'Mötesfiler',
  },
  all_meeting: {
    en: 'Board',
    nb: 'Styre',
    sv: 'Styrelse',
  },
  ga_meetings: {
    en: 'Owner/Member',
    nb: 'Eier/Medlem',
    sv: 'Ägare / medlem',
  },
  admin: {
    en: 'Admin',
    nb: 'Admin',
    sv: 'Administration',
  },
  buyer: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },
  DataRoomRoot: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },

  Enable: {
    en: 'Enable',
    nb: 'Aktiver',
    sv: 'Gör det möjligt',
  },
  Disable: {
    en: 'Disable',
    nb: 'Deaktiver',
    sv: 'Inaktivera',
  },
  Enabled: {
    en: 'Enabled',
    nb: 'Aktivert',
    sv: 'Aktiverad',
  },
  ChooseTimePoint: {
    en: 'Choose date and time',
    nb: 'Velg dato og tidspunkt',
    sv: 'Aktiverad',
  },
  Users: {
    en: 'Users',
    nb: 'Brukere',
    sv: 'Brukere',
  },
  ReactedWith: {
    en: ' reacted with ',
    nb: ' reagerte med ',
    sv: ' reagerte med ',
  },
  MeetingUserListInfoText: {
    en: ' Here you can enter a historical user status. This can be used, for example, if there have been changes to the board, and there is a need to create a record of the status before the changes occurred. ',
    nb: ' Her kan du legge inn en brukerstatus tilbake i tid. Dette kan for eksempel benyttes dersom det har skjedd endringer i styret, og det er behov for å lage en protokoll med status før endringene skjedde. ',
    sv: 'Aktiverad',
  },
  Disabled: {
    en: 'Disabled',
    nb: 'Deaktivert',
    sv: 'Inaktiverad',
  },
  Yes: {
    en: 'Yes',
    nb: 'Ja',
    sv: 'Ja',
  },
  No: {
    en: 'No',
    nb: 'Nei',
    sv: 'Nej',
  },
  'Meeting type': {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Möte typ',
  },
  PHYSICAL_MEETING: {
    en: 'In-person Meeting',
    nb: 'Fysisk møte',
    sv: 'Fysiskt möte',
  },
  PHONE_MEETING: {
    en: 'Phone Meeting',
    nb: 'Telefonmøte',
    sv: 'Telefonkonferens',
  },
  HYBRID_MEETING: {
    en: 'Hybrid Meeting',
    nb: 'Hybridmøte',
    sv: 'Hybrid möte',
  },
  DIGITAL_MEETING: {
    en: 'Digital Meeting',
    nb: 'Digitalt møte',
    sv: 'Digital möte',
  },
  VIDEO_CONFERENCE: {
    en: 'Video Conference',
    nb: 'Videokonferanse',
    sv: 'Videokonferens',
  },
  WRITTEN_PROCEDURE: {
    en: 'Written Procedure',
    nb: 'Skriftlig behandling',
    sv: 'Skriftligt förfarande',
  },
  OTHER_PROCEDURE: {
    en: 'Other Procedure',
    nb: 'Annen møteform',
    sv: 'Annat förfarande',
  },
  Date: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Datum',
  },
  Location: {
    en: 'Location',
    nb: 'Sted',
    sv: 'Plats',
  },
  'Proposed resolution': {
    en: 'Proposed resolution',
    nb: 'Forslag til vedtak',
    sv: 'Föreslagen resolution',
  },
  LinkToEmission: {
    en: 'Link to emission',
    nb: 'Lenke til kapitalforhøyelse',
    sv: 'Länk till emission',
  },
  For: {
    en: 'For',
    nb: 'For',
    sv: 'För',
  },
  Against: {
    en: 'Against',
    nb: 'Imot',
    sv: 'Mot',
  },
  ReportsClientsTab: {
    en: 'Reports',
    nb: 'Rapporter',
    sv: 'Rapporter',
  },
  'General comments': {
    en: 'General comments',
    nb: 'Generelle kommentarer',
    sv: 'Generella kommentarer',
  },
  Participants: {
    en: 'Participants',
    nb: 'Deltakere',
    sv: 'Deltagarna',
  },
  'Did not participate': {
    en: 'Did not participate',
    nb: 'Deltok ikke',
    sv: 'Deltog inte',
  },
  'Chair of the meeting': {
    en: 'Chair of the meeting',
    nb: 'Møteleder',
    sv: 'Ordförande för mötet',
  },
  'In the event of a tie, the decision is made by a built-in lottery': {
    en: 'In the event of a tie, the decision is made by a built-in lottery',
    nb: 'Ved stemmelikhet, treffes avgjørelsen ved en innebygget loddtrekning',
    sv: 'I händelse av oavgjort fattas beslutet av ett inbyggt lotteri',
  },
  minutesInfoForProcedureTypePhysicalMeeting: {
    en: 'The proceedings were carried out as a meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et møte.',
    sv: 'Förfarandet genomfördes som ett möte.',
  },
  minutesInfoForProcedureTypeHybridMeeting: {
    en: 'The proceedings were carried out as a hybrid meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et hybridmøte.',
    sv: 'Förfarandet genomfördes som ett hybridmöte.',
  },
  minutesInfoForProcedureTypeDigitalMeeting: {
    en: 'The proceedings were carried out as a digital meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et digitalt møte.',
    sv: 'Förfarandet genomfördes som ett digital möte.',
  },

  minutesInfoForProcedureTypePhoneMeeting: {
    en: 'The proceedings were carried out as a phone meeting.',
    nb: 'Styrebehandlingen ble gjennomført per telefon.',
    sv: 'Förfarandet genomfördes som ett telefonmöte.',
  },
  minutesInfoForProcedureTypeVideoConference: {
    en: 'The proceedings were carried out as a video conference.',
    nb: 'Styrebehandlingen ble gjennomført som en videokonferanse.',
    sv: 'Förfarandet genomfördes som en videokonferens.',
  },
  minutesInfoForProcedureTypeWrittenProcedure: {
    en: 'The proceedings were carried out in writing without a meeting.',
    nb: 'Styrebehandlingen ble gjennomført skriftlig og uten møte.',
    sv: 'Förfarandet genomfördes skriftligen utan möte.',
  },
  MeetingVotedOnAndApproved: {
    en: 'The following decision was voted on and approved:',
    nb: 'Følgende forslag ble stemt over og vedtatt:',
    sv: 'Följande beslut röstades om och godkändes:',
  },
  MeetingVotedOnAndRejected: {
    en: 'The following decision was voted on but rejected:',
    nb: 'Følgende forslag ble stemt over, men fikk ikke flertall:',
    sv: 'Följande beslut röstades om men avvisades:',
  },
  MeetingVotePassedAllFor: {
    en: 'There was a unanimous vote in favour of the following decision:',
    nb: 'Følgende forslag ble enstemmig vedtatt:',
    sv: 'Följande beslut röstades enhälligt för:',
  },
  MeetingVotePassedWithAllDecisionsUnanimous: {
    en: 'The following decision was made:',
    nb: 'Det ble fattet følgende vedtak:',
    sv: 'Följande beslut fattades:',
  },
  MeetingVoteRjectedAllAgainst: {
    en: 'There was a unanimous vote against the following decision:',
    nb: 'Følgende forslag ble enstemmig nedstemt:',
    sv: 'Följande beslut röstades enhälligt emot:',
  },
  MeetingThoseForDecision: {
    en: 'The following voted in favour of the decision:',
    nb: 'Følgende stemte for forslaget:',
    sv: 'Följande röstade för beslutet:',
  },
  MeetingThoseAgainstDecision: {
    en: 'The following voted against the decision:',
    nb: 'Følgende stemte imot forslaget:',
    sv: 'Följande röstade emot beslutet:',
  },
  MeetingThoseNeutralDecision: {
    en: 'The following voted neutral:',
    nb: 'Følgende avga ikke stemme:',
    sv: 'Följande röstade neutralt:',
  },
  restrictedGuysText1: {
    en: 'The following did not participate in the proceedings:',
    nb: 'Følgende deltok ikke i behandlingen:',
  },
  restrictedGuysText2: {
    en: 'The following joined the proceedings:',
    nb: 'Følgende tiltrådte i behandlingen:',
  },
  fromNOK: {
    en: 'from NOK',
    nb: 'fra NOK',
    sv: 'från NOK',
  },
  toNOK: {
    en: 'to NOK',
    nb: 'til NOK',
    sv: 'till NOK',
  },
  AccountNumberForEmission: {
    en: 'ACCOUNT NUMBER FOR EMISSION',
    nb: 'KONTONUMMER FOR EMISJONEN',
    sv: 'KONTO NUMMER FÖR EMISSIONEN',
  },
  by: {
    en: 'by',
    nb: 'innen',
    sv: 'innan',
  },
  enterDateAndTime: {
    en: 'Enter date and time',
    nb: 'Skriv inn dato og tid',
    sv: 'Ange datum och tid',
  },
  ShareParValuePointEmission: {
    en: 'The nominal value of the shares is NOK ',
    nb: 'Aksjenes pålydende verdi er NOK ',
    sv: 'Aktiernas nominella värde är NOK ',
  },
  enterAmount: {
    en: 'enter amount',
    nb: 'legg inn beløp',
    sv: 'ange belopp',
  },
  emissionDecisionSuggestionText1: {
    en: 'The capital increase is carried out either on the basis of a general meeting resolution (cf. the Companies Act § 10-1) or a board resolution (cf. the Companies Act § 10-17, provided that the board has a valid authorization). ',
    nb: 'Gjennomføringen av kapitalforhøyelsen skjer enten på bakgrunn av et generalforsamlingsvedtak (jf. aksjeloven § 10-1) eller et styrevedtak (jf. aksjeloven § 10-17, forutsatt at styret har gyldig fullmakt).',
  },
  emissionDecisionSuggestionText2: {
    en: "The following are factors that may be included in the proposed resolution, but which must be adapted to the company's special situation:",
    nb: 'Følgende er momenter som kan inngå i forslaget til vedtak, men som må tilpasses selskapets spesifikke situasjon:',
  },
  emissionDecisionSuggestionText3: {
    en: 'The share capital will increase by NOK ',
    nb: 'Aksjekapitalen økes med NOK ',
    sv: 'Aktiekapitalet ökas med NOK ',
  },
  emissionDecisionSuggestionText4: {
    en: 'The new shares belong to the share class',
    nb: 'De nye aksjene tilhører aksjeklassen ',
    sv: 'De nya aktierna tillhör aktieklassen ',
  },
  emissionDecisionSuggestionText5: {
    en: 'The shares are subscribed for in the minutes by those who appear in the appendix to the minutes.',
    nb: 'Aksjene tegnes i protokollen av de som fremgår av vedlegg til protokollen.',
    sv: 'Aktierna tecknas i protokollet av de som framgår av bilagan till protokollet.',
  },
  emissionDecisionSuggestionText6: {
    en: "The subscription amount should be payed out to the account with account number The subscription amount must be paid into the company's bank account",
    nb: 'Tegningsbeløpet skal innbetales til selskapets bankkonto',
    sv: 'Teckningsbeloppet ska betalas in på bolagets bankkonto',
  },
  emissionDecisionSuggestionText7: {
    en: 'There are only minor administration costs in connection with the capital increase, estimated at around NOK',
    nb: 'Det påløper kun mindre administrasjonskostnader i forbindelse med kapitalforhøyelsen, estimert til rundt NOK ',
    sv: 'Det uppstår endast mindre administrationskostnader i samband med kapitalökningen, uppskattat till cirka NOK ',
  },
  emissionDecisionSuggestionText8: {
    en: 'which are covered by the company',
    nb: 'om dekkes av selskapet',
    sv: 'som täcks av bolaget',
  },
  emissionDecisionSuggestionText9: {
    en: "The shareholders' pre-emptive right to subscribe for the new shares is waived.",
    nb: 'Aksjeeiernes fortrinnsrett til å tegne de nye aksjene frafalles.',
    sv: 'Aktieägarnas företrädesrätt att teckna de nya aktierna upphävs.',
  },
  emissionDecisionSuggestionText10: {
    en: "The new shares are equated with the company's already issued shares. The shares entitle to dividend from and including the current financial year.",
    nb: 'De nye aksjene likestilles med selskapets allerede utstedte aksjer. Aksjene gir rett til utbytte fra og med inneværende regnskapsår.',
    sv: 'De nya aktierna jämställs med bolagets redan utgivna aktier. Aktierna berättigar till utdelning från och med innevarande räkenskapsår.',
  },
  emissionDecisionSuggestionText11: {
    en: 'The share contribution can be disposed of by the company before the capital increase is registered in The Register of Business Enterprises, cf. the Companies Act § 10-13 second paragraph.',
    nb: 'Aksjeinnskuddet kan disponeres av selskapet før kapitalforhøyelsen er registrert i Foretaksregisteret, jf. aksjeloven § 10-13 annet ledd.',
    sv: 'Aktieinsatsen kan disponeras av bolaget innan kapitalökningen är registrerad i Bolagsverket, jfr. aktiebolagslagen § 10-13 andra stycket.',
  },
  MeetingAllUnanimousStatement: {
    en: 'All decisions were passed unanimously.',
    nb: 'Det var enstemmighet i alle avstemningene.',
    sv: 'Alla beslut fattades enhälligt.',
  },
  TheFollowingMustSignRead: {
    en: 'The following must sign that the minutes have been read:',
    nb: 'Følgende skal signere at protokollen er lest:',
    sv: 'Följande måste underteckna att protokollet har lästs:',
  },
  theFollowingWasApproved: {
    en: 'The following was approved',
    nb: 'Følgende ble vedtatt',
    sv: 'Följande godkändes',
  },
  ChatMessageSeen: {
    en: 'Seen',
    nb: 'Sett',
    sv: 'Sett',
  },
  theFollowingWasRejected: {
    en: 'The following was rejected',
    nb: 'Følgende ble nedstemt',
    sv: 'Följande avvisades',
  },
  overviewOfVoting: {
    en: 'Overview of voting',
    nb: 'Oversikt over stemmegiving',
    sv: 'Översikt över omröstning',
  },
  Item: {
    en: 'Item',
    nb: 'Sak',
    sv: 'Fall',
  },
  Shares: {
    en: 'Shares',
    nb: 'Aksjer',
    sv: 'Aktier',
  },
  'In person': {
    en: 'In person',
    nb: 'Personlig',
    sv: 'I person',
  },
  Proxy: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  'Represented by': {
    en: 'Represented by',
    nb: 'Representert ved',
    sv: 'Representerad av',
  },
  'Follow-up case': {
    en: 'Follow-up case',
    nb: 'Oppfølgingssak',
    sv: 'Uppföljningsfall',
  },
  BenficialOwnersInfoHeader1: {
    nb: 'Kort om reelle rettighetshavere',
    en: 'Overview of beneficial owners',
  },
  BenficialOwnersInfoText11: {
    nb: 'Lov om reelle rettighetshavere ',
    en: 'The act on beneficial owners ',
  },
  BenficialOwnersInfoText12: {
    nb: 'og den tilhørende ',
    en: 'and the associated ',
  },
  BenficialOwnersInfoText13: {
    nb: 'forskriften ',
    en: 'regulation ',
  },
  BenficialOwnersInfoText14: {
    nb: 'pålegger virksomheter å dokumentere hvem som er reelle rettighetshavere, og rapportere dette inn til myndighetenes register. Hensikten med registeret er å vite hvem som direkte eller indirekte eier og kontrollerer virksomheter, blant annet som et tiltak for å bekjempe hvitvasking, terrorfinansiering og økonomisk kriminalitet. Du kan lese mer om reelle rettighetshavere på ',
    en: 'requires businesses to document who the beneficial owners are and report this to the government’s registry. The purpose of the registry is to know who directly or indirectly owns and controls businesses, as part of efforts to combat money laundering, terrorist financing, and economic crime. You can read more about beneficial owners at ',
  },
  BenficialOwnersInfoHeader2: {
    nb: 'Reelle rettighetshavere i Orgbrain',
    en: 'Beneficial owners in Orgbrain',
  },
  BenficialOwnersInfoText21: {
    nb: 'Det er laget en',
    en: 'A ',
  },
  BenficialOwnersInfoText22: {
    nb: 'løsning',
    en: 'solution',
  },
  BenficialOwnersInfoText23: {
    nb: 'hos Brønnøysundregistrene som kan brukes til innrapporteringen, men det er ikke alene tilstrekkelig å oppfylle alle lov- og forskriftskrav på dette feltet.',
    en: 'has been developed by the Brønnøysund Register Centre which can be used for reporting, but it is not sufficient on its own to meet all legal and regulatory requirements in this area.',
  },
  BenficialOwnersInfoText3: {
    nb: 'Kunder av Orgbrain kan benytte den innebygde løsningen for reelle rettighetshavere som har en rekke fordeler som forenkler både rapportering og etterlevelse av lovkrav:',
    en: 'Orgbrain customers can use the built-in solution for beneficial owners, which offers a number of advantages that simplify both reporting and compliance with legal requirements:',
  },

  BenficialOwnersInfoPointTitle1: {
    nb: 'Automatisk identifisering: ',
    en: 'Automatic Identification: ',
  },
  BenficialOwnersInfoPoint1: {
    nb: 'Orgbrain bruker aksjeeierboken og data fra Skatteetaten for å identifisere reelle rettighetshavere og generere en oppdatert statusrapport.',
    en: 'Orgbrain uses the shareholder registry and data from the Norwegian Tax Administration to identify beneficial owners and generate an updated status report.',
  },
  BenficialOwnersInfoPointTitle2: {
    nb: 'Oppbevaring av tilleggsinformasjon: ',
    en: 'Storage of Additional Information: ',
  },
  BenficialOwnersInfoPoint2: {
    nb: 'Virksomheten har et juridisk ansvar for å oppbevare tilleggsinformasjon om reelle rettighetshavere. Orgbrain forenkler denne prosessen.',
    en: 'The business has a legal obligation to store additional information about beneficial owners. Orgbrain simplifies this process.',
  },
  BenficialOwnersInfoPointTitle3: {
    nb: 'Rapportering til Brønnøysundregistrene: ',
    en: 'Reporting to the Brønnøysund Register Centre: ',
  },
  BenficialOwnersInfoPoint3: {
    nb: 'Med Orgbrain kan du rapportere reelle rettighetshavere til Brønnøysundregistrene med få klikk, slik at all nødvendig informasjon er samlet på ett sted.',
    en: 'With Orgbrain, you can report beneficial owners to the Brønnøysund Register Centre with just a few clicks, ensuring all necessary information is gathered in one place.',
  },
  BenficialOwnersInfoPointTitle4: {
    nb: 'Informering av rettighetshavere: ',
    en: 'Notification of Beneficial Owners: ',
  },
  BenficialOwnersInfoPoint4: {
    nb: 'Det er et lovkrav å informere personer om at de er reelle rettighetshavere. Med Orgbrain kan du raskt og enkelt sende denne informasjonen via e-post.',
    en: 'It is a legal requirement to inform individuals that they are beneficial owners. With Orgbrain, you can quickly and easily send this information via email.',
  },
  BenficialOwnersInfoPointTitle5: {
    nb: 'Gjenbruk av data: ',
    en: 'Data Reuse: ',
  },
  BenficialOwnersInfoPoint5: {
    nb: 'Ved å kopiere tidligere versjoner kan du enkelt gjenbruke data og fylle ut årets skjema uten å måtte starte fra bunnen av.',
    en: "By copying previous versions, you can easily reuse data and complete this year's form without starting from scratch.",
  },

  CurrentVersion: {
    nb: 'Registrert informasjon',
    en: 'Registered information',
  },
  BeneficialDraft: {
    nb: 'Utkast',
    en: 'Draft',
  },
  BeneficialDone: {
    nb: 'Ferdig',
    en: 'Completed',
  },
  NotifyOwner: {
    nb: 'Varsle rettighetshavere',
    en: 'Notify beneficial owners',
  },
  NewVersion: {
    nb: 'Ny versjon',
    en: 'New version',
  },
  CopyPrevVersion: {
    nb: 'Kopier forrige versjon',
    en: 'Copy previous version',
  },
  Previous: {
    nb: 'Forrige',
    en: 'Previous',
  },
  AddNewBeneficialOwner: {
    nb: 'Reell rettighetshaver',
    en: 'Beneficial owner',
  },
  AddNewBeneficialOwnerTooltip: {
    nb: 'Legg til ny en reell rettighetshaver',
    en: 'Add new beneficial owner',
  },
  AddNewPersonTooltip: {
    nb: 'Legg til ny en ledende person',
    en: 'Add new leading person',
  },
  ExtraInformation: {
    nb: 'Ekstra informasjon (blir ikke sendt inn til altinn)',
    en: 'Extra information (is not sent to altinn)',
  },

  CopyPrevVersionTooltip: {
    nb: 'Kopier den nåværende registrerte informasjonen',
    en: 'Copy the currently registered information',
  },
  SavedVersion: {
    nb: 'Versjonen er lagret',
    en: 'Saved this version',
  },
  OrgbrainSuggestionHeader: {
    nb: 'Reelle rettighetshavere er identifisert',
    en: 'Beneficial owners have been identified',
  },
  OrgbrainSuggestionText: {
    nb: 'Orgbrain har identifisert potensielle reelle rettighetshavere. Ønsker du at disse automatisk legges til på siden, trykk "Fortsett".',
    en: 'Orgbrain has identified potential beneficial owners. If you wish to automatically add them to the page, click "Continue".',
  },
  CreateNewOwner: {
    nb: 'Opprett ny',
    en: 'Create new',
  },
  SaveDraft: {
    nb: 'Lagre utkast',
    en: 'Save draft',
  },
  OrgbrainSuggestionSubText: {
    nb: 'Forslagene systemet gir er basert på informasjon hentet fra aksjeeierregisteret og Skatteetaten. Det gis ingen garanti for at informasjonen som benyttes, eller forslagene som gis, er presise eller fullstendige. Forslagene er kun ment som en hjelp i prosessen med å identifisere reelle rettighetshavere. Hver enkelt bruker må selv gjøre en uavhengig vurdering av hvilke kilder og prosedyrer som best kan gi et korrekt bilde av situasjonen i sin virksomhet.',
    en: 'The suggestions provided by the system are based on information obtained from the shareholder registry and the Norwegian Tax Administration. There is no guarantee that the information used, or the suggestions provided, are accurate or complete. The suggestions are only intended as an aid in the process of identifying beneficial owners. Each user must independently assess which sources and procedures are best suited to provide an accurate representation of the actual situation in their business.',
  },
  OrgbrainSuggestion: {
    nb: 'Dette valget er anbefalt av Orgbrain basert på informasjonen som er tilgjengelig',
    en: 'This option is recommended by Orgbrain based on the information available',
  },
  OrgbrainSuggestions: {
    nb: 'Forslag fra Orgbrain',
    en: 'Suggestions from Orgbrain',
  },
  CreateBeneficialOwners: {
    nb: 'Opprett reelle rettighetshavere',
    en: 'Create beneficial owners',
  },
  BeneficialOwnerStatus: {
    nb: 'Har virksomheten reelle rettighetshavere?',
    en: 'Does the business have beneficial owners?',
  },

  NoHistoryBeneficialOwners: {
    nb: 'Det er ikke lagt inn noen opplysninger.',
    en: 'No information has been provided.',
  },
  AddNewPerson: {
    nb: 'Legg til person',
    en: 'Add new person',
  },
  BeneficialFnr: {
    nb: 'Personnummer/D-nr',
    en: 'National ID number/D-number',
  },
  BeneficialFnrFormat: {
    nb: 'Formater: xxxxxxxxxxx',
    en: 'Formats: xxxxxxxxxxx',
  },
  DateOfBirthFormat: {
    nb: 'Formater: yyyy-mm-dd',
    en: 'Formats: yyyy-mm-dd',
  },
  BeneficialOwns: {
    nb: 'Aksjeandel',
    en: 'Share ownership',
  },
  ReasonForPostion: { nb: 'Grunnlagstype', en: 'Basis type' },
  direct_ownershipBeneficialOwners: { nb: 'Direkte eierskap', en: 'Direct ownership' },
  indirect_ownershipBeneficialOwners: { nb: 'Indirekte eierskap', en: 'Indirect ownership' },
  owner_by_contractBeneficialOwners: {
    nb: 'Gjennom enighet eller avtale',
    en: 'Through agreement or contract',
  },

  NotSent: { nb: ' - Ikke innlevert', en: ' - Not sent' },
  NotifiedOwners: { nb: 'Rettighetshavere ble varslet', en: 'Owners notified' },
  NoBeneficialOwners: {
    nb: 'Ingen reelle rettighetshavere',
    en: 'No beneficial owners',
  },
  NoBeneficialOwnersIdentified: {
    nb: 'Ingen reelle rettighetshavere identifisert',
    en: 'No beneficial owners identified',
  },
  BeneficialOwnersEmailSuccess: {
    nb: 'Reelle rettighetshavere varslet',
    en: 'Beneficial owners notified',
  },
  BeneficialOwnersEmailSuccessText: {
    nb: 'De utvalgte reelle rettighetshaverne er nå varslet.',
    en: 'The selected beneficial owners have now been notified.',
  },
  SuccessSend: {
    nb: 'Innsendelsen var vellykket',
    en: 'The submission was successful',
  },
  SuccessSendText: {
    nb: 'Gratulerer! Meldingen om reelle rettighetshavere er nå innlevert. Du kan finne kvitteringen i Altinn.',
    en: 'Congratulations! The notification of beneficial owners has now been submitted. You can find the receipt in Altinn.',
  },
  SuccessSendTextForIdentifiedState: {
    nb: 'Husk å varsle de som er identifisert som reelle rettighetshavere',
    en: 'Remember to notify those who are identified as beneficial owners',
  },
  ErrorInValidation: {
    nb: 'Feil i validering av reelle rettighetshavere. Kontakt Support',
    en: 'Error in validation of beneficial owners. Contact Support',
  },
  ErrorInValidationObjectReference: {
    nb: 'Altinn oppdaget feil i denne versjonen',
    en: 'Altinn marked an error in this version',
  },
  ErrorInValidationObjectReferenceText: {
    nb: 'Er personnummer på reelle rettighetshavere korrekt fylt ut?',
    en: 'Is the national ID number correctly filled out?',
  },
  NoPartOwnsMoreThan25: {
    nb: 'Ingen fysiskperson eier mer enn 25 % av virksomheten',
    en: 'No individual owns more than 25 % of the business',
  },

  BeneficialOwnerStatusInfo: {
    nb: 'Reell rettighetshaver er en person som eier eller kontrollerer en registreringspliktig virksomhet i siste instans hvis personen har en eller flere av disse posisjonene:',
    en: 'A beneficial owner is a person who owns or controls a registered business ultimately if the person holds one or more of these positions:',
  },
  BeneficialOwnerStatusInfoPoint1: {
    nb: 'Eierskap på mer enn 25 %',
    en: 'Ownership of more than 25 %',
  },
  BeneficialOwnerStatusInfoPoint2: {
    nb: 'Kontroll over mer enn 25 % av stemmerettighetene',
    en: 'Control over more than 25 % of the voting rights',
  },
  BeneficialOwnerStatusInfoPoint3: {
    nb: 'Rett til å utpeke eller avsette over 50 % av styremedlemmene i virksomheten',
    en: 'The right to appoint or dismiss more than 50 % of the board members in the business',
  },
  BeneficialOwnerStatusInfoPoint4: {
    nb: 'Kontroll på annen måte',
    en: 'Control in another way',
  },

  IsPublicCompanyHelpText1: {
    nb: 'Noen virksomheter skal rapportere om en offentlig virksomhet, direkte eller indirekte, kontrollerer minimum 75 % gjennom eierskap eller stemmerettighet. Du finner informasjon om hvem dette gjelder i ',
    en: 'Some businesses must report if a public organization, directly or indirectly, controls a minimum of 75 % through ownership or voting rights. You can find information about who this applies to in ',
  },
  IsPublicCompanyHelpText2: {
    nb: ' under punktene f til l.  Dette gjelder også for utenlandske virksomheter.',
    en: ' under points f to l. This also applies to foreign businesses.',
  },
  IsPublicCompanyHelpTextLink: {
    nb: 'forskrift til lov om register over reelle rettighetshavere § 3-4,',
    en: 'regulation to the act on the register of beneficial owners § 3-4,',
  },

  IsOwnedByPublicOrg: {
    nb: 'Virksomheten er kontrollert av en offentlig myndighet',
    en: 'The business is controlled by a public authority',
  },
  IsOwnedByForeignPublicOrg: {
    nb: 'Virksomheten er kontrollert av en utenlandsk offentlig virksomhet',
    en: 'T he business is controlled by a foreign public organization',
  },
  Draft: { nb: 'Utkast', en: 'Draft' },
  ErrorsInVersion: { nb: 'Kan ikke fullføre', en: 'Cannot complete' },
  NotDoneBeneficialOwnersError: { nb: 'Ikke market som ferdig', en: 'Not marked as done' },
  NoOwnersBeneficialOwnersError: {
    nb: 'Ingen reelle rettighethavere eiere registrert',
    en: 'No beneficial owners registered',
  },
  FnrErrorBeneficialOwnersError: {
    nb: 'Det mangler komplett fødselsnummer for følgende reelle rettighetshavere: ',
    en: 'The following beneficial owners are missing a complete national ID number: ',
  },
  NoOptionSelectedBeneficialOwnersError: {
    nb: 'Det er ikke gitt forklaring på valgt status',
    en: 'No explanation has been provided for the selected status',
  },

  ForeignPersonErrorBeneficialOwnersError: {
    nb: 'Påkrevde feltene på disse utenlandske reell rettighetshaverene er ikke fylt ut: ',
    en: 'The required fields on these foreign beneficial owners are not filled out: ',
  },
  NoPositionsBeneficialOwnersError: {
    nb: 'Ingen posisjoner er satt på disse reell rettighetshaverene: ',
    en: 'No positions selected on these beneficial owners: ',
  },
  NoCriteriaBeneficialOwnersError: {
    nb: 'Ingen grunnlagstyper valgt på disse reell rettighetshaverene: ',
    en: 'No basis types selected on these beneficial owners: ',
  },
  Copy: {
    nb: 'Lag kopi av denne',
    en: 'Create copy of this',
  },
  DeleteBeneficialVersionTitle: {
    nb: 'Slett versjon',
    en: 'Delete version',
  },
  DeleteBeneficialVersion: {
    nb: 'Er du sikker på at du vil slette denne versjonen?',
    en: 'Are you sure you want to delete this version?',
  },
  DeleteBeneficialOwnerTitle: {
    nb: 'Slett reell rettighetshaver',
    en: 'Delete beneficial owner',
  },
  DeleteBeneficialOwner: {
    nb: 'Er du sikker på at du vil slette denne reelle rettighetshaveren?',
    en: 'Are you sure you want to delete this beneficial owner?',
  },
  Finish: {
    nb: 'Ferdig',
    en: 'Complete',
  },
  notificationNotSentBeneficialOwnersError: {
    nb: 'Varsel er ikke sendt til reelle rettighetshavere',
    en: 'Notification has not been sent to beneficial owners',
  },
  altinnNotSentBeneficialOwnersError: {
    nb: 'Reelle rettighetshavere er ikke levert til altinn',
    en: 'Beneficial owners have not been sent to Altinn',
  },
  BeneficialOwnership: { nb: 'Eierskap', en: 'Ownership' },
  BeneficialVotingRights: { nb: 'Stemmerettigheter', en: 'Voting rights' },
  OwnerIncompleteBeneficialOwnersError: {
    nb: 'Rettighetshaveren er ikke fullstendig utfylt',
    en: 'The beneficial owner is not fully completed',
  },
  SendInnToAltinn: {
    nb: 'Send til Altinn',
    en: 'Send to Altinn',
  },
  OtherInfo: {
    nb: 'Annen informasjon',
    en: 'Other information',
  },
  IsNationalPeopleRegister: {
    nb: 'Har personen enten fødselsnummer eller d-nummer?',
    en: 'Does the person have a norwegian national ID number or D-number?',
  },
  OwnershipPercent: {
    nb: 'Hvilken eierandel har personen',
    en: 'What percentage of ownership does the person have',
  },
  BeneficialOwnerPositionsHelpText: {
    nb: 'Dersom en person både har eierskap og/eller stemmerettigheter over 25 % eller rett til å utpeke eller avsette mer enn 50 % av styremedlemmene, skal du velge det førstnevnte. Hvis ingen av disse kriteriene er oppfylt, velger du annen posisjon.',
    en: 'If a person both has ownership and/or voting rights over 25 % or the right to appoint or dismiss more than 50 % of the board members, you should choose the former. If none of these criteria are met, choose other position.',
  },
  ElectBoardmembersHelpText: {
    nb: 'Bestemmelse i vedtekter eller avtaler som gir rett til å utpeke eller avsette over halvparten av styremedlemmene i virksomheten',
    en: 'Provision in articles of association or agreements that gives the right to appoint or dismiss more than half of the board members in the business',
  },

  VotingPercent: {
    nb: 'Hvilken andel av stemmerettighetene har personen',
    en: 'What percentage of voting rights does the person have',
  },
  ReasonForNoBeneficialOwners: {
    nb: 'Er det en eller flere offentlige virksomheter som, direkte eller indirekte, kontrollerer 75 % eller mer av eierandelene eller stemmerettighetene?',
    en: 'Is there one or more public organizations that, directly or indirectly, control 75 % or more of the ownership or voting rights?',
  },
  ReasonIsPublicOrganization: {
    nb: 'Er den offentlige virksomheten utenlandsk?',
    en: 'Is the public organization foreign?',
  },
  isForeignPublicCompany: {
    nb: 'Den offentlige virksomheten er en utenlandsk virksomhet',
    en: 'The public organization is a foreign organization',
  },
  'NoPersonOwnMoreThan25%': {
    nb: 'Ingen person eier mer enn 25 %',
    en: 'No person owns more than 25 %',
  },
  sendToAltinnTooltip: {
    nb: 'Gå til ID-porten for å sende informasjon til Altinn',
    en: 'Go to ID-porten to send information to Altinn',
  },

  NotifyOwnerTooltip: {
    nb: 'Her kan du velge hvem som skal motta varsel på e-post',
    en: 'Here you can choose who will receive a notification by email',
  },
  VotingRightsCriteria: {
    nb: 'Oppgi grunnlaget for stemmerettighet',
    en: 'State the basis for voting rights',
  },
  IntermediateOrganizationsVoting: {
    nb: 'Oppgi de mellomliggende organisasjonene som gir stemmerettigheter',
    en: 'Specify the intermediate organizations that grant voting rights',
  },
  IntermediateOrganizations: {
    nb: 'Oppgi de mellomliggende organisasjonene',
    en: 'Specify the intermediate organizations',
  },
  IntermediateOrganization: {
    nb: 'Mellomliggende organisasjon',
    en: 'Intermediate organization',
  },
  AddNewIntermediateOrg: {
    nb: 'Legg til',
    en: 'Add',
  },
  IsForeignOrganiaztion: {
    nb: 'Er organisasjonen norsk eller utenlandsk',
    en: 'Is the organization norwegian or foreign',
  },
  OrganizationNumber: {
    nb: 'Organisasjonsnummer',
    en: 'Organization number',
  },
  RegistrationNumber: {
    nb: 'Registreringsnummer i hjemlandet',
    en: 'Registration number in home country',
  },
  EnterName: {
    nb: 'Skriv inn navn',
    en: 'Enter name',
  },
  AddressOfOrganization: {
    nb: 'Organisasjonens adresse',
    en: 'Address of the organization',
  },
  ForeignCountry: {
    nb: 'Hva er hjemlandet til organisasjonen',
    en: 'What is the home country of the organization',
  },
  address: {
    nb: 'Adresse',
    en: 'Address',
  },
  LessThan25Percent: {
    nb: 'Mindre enn 25 %',
    en: 'Less than 25 %',
  },
  BeneficialOwnerPositionReason: {
    nb: 'Beskriv kort på hvilken måte personen kontrollerer virksomheten',
    en: 'Briefly describe how the person controls the business',
  },
  OwnerShipOrVotingRights: {
    nb: 'Eierskap og/eller stemmerettigheter, over 25 %',
    en: 'Ownership or voting rights, over 25 %',
  },
  ElectBoardmembers: {
    nb: 'Rett til å utpeke eller avsette mer enn 50 % av styremedlemmene',
    en: 'The right to appoint or dismiss more than 50 % of the board members',
  },
  OtherPosition: {
    nb: 'Annen posisjon',
    en: 'Other position',
  },
  BeneficialVotes: {
    nb: 'Stemmeandel',
    en: 'Voting rights',
  },
  BeneficialSource: {
    nb: 'Kilde',
    en: 'Source',
  },
  BeneficialOwners: {
    nb: 'Reelle rettighetshavere',
    en: 'Beneficial owners',
  },
  SentToAltinn: {
    nb: 'Innsendt',
    en: 'Delivered',
  },
  ChangedBeneficialOwnersVersion: {
    nb: 'Sist endret',
    en: 'Last updated',
  },
  BeneficialVersionNote: {
    nb: 'Legg til eller rediger notat',
    en: 'Add or edit note',
  },
  CreateBeneficialOwner: {
    nb: 'Opprett reell rettighetshaver',
    en: 'Create beneficial owner',
  },
  EditBeneficialOwner: {
    nb: 'Rediger reell rettighetshaver',
    en: 'Edit beneficial owner',
  },
  SendNotifToBeneficalOwnerTitle: {
    nb: 'Varsle reelle rettighetshavere',
    en: 'Notify beneficial owners',
  },
  SendNotifToBeneficalOwnerText: {
    nb: 'Ønsker du å sende varsel til de som er identifisert som reelle rettighetshavere?',
    en: 'Do you want to send a notification to those identified as beneficial owners?',
  },
  CancelEditing: {
    nb: 'Ønsker du å avbryte redigeringen? Informasjon som ikke er lagret vil gå tapt.',
    en: 'Do you want to cancel editing? Unsaved information will be lost.',
  },
  ChooseRecipients: {
    nb: 'Velg mottakere',
    en: 'Choose recipients',
  },
  Description: {
    en: 'Description',
    nb: 'Beskrivelse',
    sv: 'Beskrivning',
  },
  'Responsible for follow-up': {
    en: 'Responsible for follow-up',
    nb: 'Ansvarlig for oppfølging',
    sv: 'Ansvarig för uppföljning',
  },
  'Follow-up date': {
    en: 'Follow-up date',
    nb: 'Oppfølgingsdato',
    sv: 'Uppföljningsdatum',
  },
  'By drawing lots': {
    en: 'By drawing lots',
    nb: 'Ved loddtrekning',
    sv: 'Genom att dra lod',
  },
  Repeat: {
    en: 'Repeat',
    nb: 'Gjenta',
  },
  PriceShareholdersEvents: {
    en: 'Amount per share',
    nb: 'Beløp per aksje',
  },

  'Repeat annually': {
    en: 'Repeat annually',
    nb: 'Gjenta årlig',
  },
  'Repeats annually': {
    en: 'Repeats annually',
    nb: 'Gjentas årlig',
  },
  Elected: {
    en: 'Elected',
    nb: 'Valgt',
    sv: 'Vald',
  },
  'Elected candidates': {
    en: 'Elected candidates',
    nb: 'Valgte kandidater',
    sv: 'Valda kandidater',
  },
  'Elected candidate': {
    en: 'Elected candidate',
    nb: 'Valgt kandidat',
    sv: 'Vald kandidat',
  },
  orderOfCandidatesWSithSameNumberOfVotesDecidedRandomly: {
    en: 'The order of candidates with the same number of votes was decided by drawing lots.',
    nb: 'Rekkefølgen på kandidatene med likt antall stemmer er avgjort av systemet ved en tilfeldig loddtrekning.',
    sv: 'Ordningen på kandidater med samma antal röster bestämdes genom att dra lod.',
  },
  'On election': {
    en: 'On election',
    nb: 'På valg',
    sv: 'Vid valet',
  },
  position_electionResults: {
    en: 'Place',
    nb: 'Plass',
    sv: 'Placera',
  },
  Votes: {
    en: 'Votes',
    nb: 'Stemmer',
    sv: 'Röster',
  },
  'of votes represented': {
    en: 'of votes represented',
    nb: 'av stemmer representert',
    sv: 'av de representerade rösterna',
  },
  Candidate: {
    en: 'Candidate',
    nb: 'Kandidat',
    sv: 'Kandidat',
  },
  'Votes cast': {
    en: 'Votes cast',
    nb: 'Stemmer avgitt',
    sv: 'Avgivna röster',
  },
  'Votes for': {
    en: 'Votes for',
    nb: 'Stemmer for',
    sv: 'Röstar på',
  },
  'Votes against': {
    en: 'Votes against',
    nb: 'Stemmer imot',
    sv: 'Röstar emot',
  },
  'of votes cast': {
    en: 'of votes cast',
    nb: 'av stemmer avgitt',
    sv: 'av avgivna röster',
  },
  'of cast': {
    en: 'of cast',
    nb: 'av avgitt',
    sv: 'av avgivna',
  },
  'Total represented': {
    en: 'Total represented',
    nb: 'Totalt representert',
    sv: 'Totalt representerat',
  },
  Abstained: {
    en: 'Abstained',
    nb: 'Avstått',
    sv: 'Avstod',
  },
  Result: {
    en: 'Result',
    nb: 'Resultat',
    sv: 'Resultat',
  },
  Approved: {
    en: 'Approved',
    nb: 'Vedtatt',
    sv: 'Godkänd',
  },
  Rejected: {
    en: 'Rejected',
    nb: 'Nedstemt',
    sv: 'avvisade',
  },
  voteRuleExplanationStatutoryChange: {
    en: 'in accordance with majority requirement rules',
    nb: 'i overenstemmelse med flertallskrav',
    sv: 'i enlighet med regler för majoritetskrav',
  },
  voteRuleExplanationQualifiedMajority: {
    en: 'in accordance with qualified majority requirement',
    nb: 'i overenstemmelse med krav til kvalifisert flertall',
    sv: 'i enlighet med kvalificerad majoritet',
  },
  voteRuleExplanationUnanimity: {
    en: 'in accordance with unanimity requirement',
    nb: 'i overenstemmelse med krav til enstemmighet',
    sv: 'i enlighet med enhällighetskrav',
  },
  voteRuleExplanationInvestigation: {
    en: 'in accordance with vote approval requirement for proposition for investigation cases',
    nb: 'i overenstemmelse med stemmekrav for forslag om gransking',
    sv: 'i enlighet med kravet på röstgodkännande för förslag till utredningsärenden',
  },
  'Represented directly': {
    en: 'Represented directly',
    nb: 'Representert direkte',
    sv: 'Representeras direkt',
  },
  'Represented by pre-voting': {
    en: 'Represented by pre-voting',
    nb: 'Representert ved forhåndsstemme',
    sv: 'Representeras av förhandsröstning',
  },
  'Represented by joining': {
    en: 'Represented by joining',
    nb: 'Representert ved oppmøte',
    sv: 'Representeras genom att gå med',
  },
  'Voting shares': {
    en: 'Voting shares',
    nb: 'Stemmeberettigede aksjer',
    sv: 'Röstningsandelar',
  },
  'of voting-eligible shares': {
    en: 'of voting-eligible shares',
    nb: 'av stemme&shy;berettigede aksjer',
    sv: 'av röstberättigade aktier',
  },
  'own shares': {
    en: 'own shares',
    nb: 'egne aksjer',
    sv: 'egna aktier',
  },
  'Represented by proxy': {
    en: 'Represented by proxy',
    nb: 'Representert ved fullmakt',
    sv: 'Representeras av ombud',
  },
  'Reminder of meeting for': {
    en: 'Reminder of meeting for',
    nb: 'Påminnelse om møte for',
    sv: 'Påminnelse om möte för',
  },
  'New subgroup': {
    en: 'New subgroup',
    nb: 'Ny undergruppe',
    sv: 'Ny undergrupp',
  },
  MinutesFrom: {
    en: 'Minutes from',
    nb: 'Protokoll fra',
    sv: 'Protokoll från',
  },
  Recipients: {
    en: 'Recipients',
    nb: 'Mottakere',
    sv: 'Mottagare',
  },
  'AllRequirementsFullfilled?companyType=FLI': {
    en: 'All the formal requirements for notice and number of attendance are fulfilled.',
    nb: 'Alle de formelle krav til innkalling og antall deltagere er oppfylt.',
    sv: 'Alla formella krav för uppsägning och antal närvaro är uppfyllda.',
  },
  'AllRequirementsFullfilled?jurisdiction=NO': {
    en: 'The proceeding fulfills the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?companyType=ASA': {
    en: 'The proceeding fulfills the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?jurisdiction=EN': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen.',
  },
  'AllRequirementsFullfilled?jurisdiction=SE': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsFullfilled?jurisdiction=DK': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 124.',
  },
  'AllRequirementsNotFullfilled?companyType=FLI': {
    en: 'The formal requirements for notice and/or number of attendance are not fulfilled.',
    nb: 'De formelle krav til innkalling og/eller antall deltagere er ikke oppfylt.',
    sv: 'De formella kraven för anmälan och / eller antal närvaro uppfylls inte.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=NO': {
    en: 'The proceeding does not meet the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i norska aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?companyType=ASA': {
    en: 'The proceeding does not meet the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=EN': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=SE': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=DK': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 124.',
  },
  'There is quorum, decisions can be taken': {
    en: 'There is quorum, decisions can be taken',
    nb: 'Styret er beslutningsdyktig',
    sv: 'Det finns beslutsförmåga, beslut kan fattas',
  },
  'There is not a quorum, decisions cannot be taken': {
    en: 'There is not a quorum, decisions cannot be taken',
    nb: 'Styret er ikke beslutningsdyktig',
    sv: 'Det finns inte beslutsfattande, beslut kan inte fattas',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was a quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?companyType=ASA': {
    en: 'There was a quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was a quorum, and the requirements listed in the Companies Act for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det var beslutsfattande och kraven i företagslagen för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was a quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen kap. 8 § 21 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was a quorum, and the requirements listed in the Companies Act § 124 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut uppfylldes.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was no quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?companyType=ASA': {
    en: 'There was no quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was no quorum, and the requirements listed in the Companies Act for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det fanns inget beslutsfattande, och kraven i företagslagen för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was no quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det fanns inget beslutsfattande och de krav som anges i aktiebolagslagen kap. 8 § 21 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was no quorum, and the requirements listed in the Companies Act § 124 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut var som sådan inte uppfyllda.',
  },
  isSubsidiaryTextFli: {
    en: 'Shall not exercise voting rights',
    nb: 'Skal ikke utøve stemmerett',
    sv: 'Ska inte utöva rösträtt',
  },
  isSubsidiaryText: {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt',
  },
  'isSubsidiaryText?jurisdiction=NO': {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights, The Norwegian law of stocks (Aksjeloven) § 5-3 (3)',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett, jf. aksjeloven § 5-3 (3)',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt, jfr. den norska aktiebolagslagen § 5-3 (3)',
  },
  cantUpdateMeetingStatusTooltip: {
    en: 'Only administrators can change attendance status after the meeting has started.',
    nb: 'Kun møteadministrator kan endre din deltakerstatus etter at møtet har startet.',
    sv: 'Endast mötesadministratören kan ändra din deltagarestatus efter att mötet har startat.',
  },
  VotingTieTextHelper2: {
    en: "tie; final result is based on the meeting chair's deciding vote",
    nb: 'likt stemmetall; endelig resultat er basert på møteleders avgjørende stemme',
    sv: 'slips; slutresultatet baseras på mötesordförandens avgörande röst',
  },
  'No info': {
    en: 'No info',
    nb: 'Ingen info',
    sv: 'Ingen info',
  },
  'Rendering Failure': {
    en: 'Rendering Failure',
    nb: 'Gjengivelsesfeil',
    sv: 'Rendering misslyckande',
  },
  Send: {
    en: 'Send',
    nb: 'Send',
    sv: 'Skicka',
  },
  Reject: {
    en: 'Reject',
    nb: 'Avvis',
    sv: 'Avvisa',
  },
  Complaint: {
    en: 'Complaint',
    nb: 'Klage',
    sv: 'Klagomål',
  },
  Bounce: {
    en: 'Bounce',
    nb: 'Sprette',
    sv: 'Studsa',
  },
  InvalidUse: {
    en: 'Invalid Use',
    nb: 'Ugyldig bruksstørrelse',
  },
  InvalidUseContract: {
    en: 'Invalid execution',
    nb: 'Ugyldig utøvelse',
  },
  UseAuthorization: {
    en: 'Use authorization',
    nb: 'Bruk fullmakt',
  },
  UseContract: {
    en: 'Register execution of agreement',
    nb: 'Registrer utøvelse av avtale',
  },
  NoAuth: {
    en: 'No authorizations have been made yet. Press the button "New authorization" to create your first authorization. Or you can import your companies earlier authorizations by clicking on the import auths button.',
    nb: 'Ingen fullmakter er foretatt ennå. Trykk på knappen "Ny fullmakt" for å opprette din første fullmakt. Ellers kan importere bedriftens tidligere autorisasjoner ved å klikke på "Importer fra Brønnøysundregistrene".',
  },
  NoAuth2: {
    en: 'No active authorizations registered',
    nb: 'Ingen aktive fullmakter registrert',
  },

  IssueSharesAuth: {
    en: 'Increase share capital',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleAuth: {
    en: 'Issue convertible loans',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesAuth: {
    en: 'Acquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesAuth: {
    en: 'Acquire contractual lien in own shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsAuth: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsAuth: {
    en: 'Distribution of dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  IssueSharesType: {
    en: 'Issue Shares',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleType: {
    en: 'Convertible loan',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesType: {
    en: 'Aquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesType: {
    en: 'Claim shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsType: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsType: {
    en: 'Grant dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  Size: {
    en: 'Size',
    nb: 'Størrelse',
    sv: 'Størrelse sv',
  },
  Remaining: {
    en: 'Remaining',
    nb: 'Rest',
  },
  SizeRemaining: {
    en: 'Size remaining',
    nb: 'Gjenværende størrelse',
    sv: 'Størrelse sv',
  },
  SizeRemainingBeforeUse: {
    en: 'Size before use',
    nb: 'Størrelse før bruk',
    sv: 'Størrelse sv',
  },
  SizeRemainingBeforeUseContract: {
    en: 'Quantity before',
    nb: 'Antall før',
    sv: 'Antall før sv',
  },
  SizeRemainingAfterUse: {
    en: 'Remaining after use',
    nb: 'Gjenværende etter bruk',
    sv: 'Størrelse sv',
  },
  SizeRemainingAfterUseContract: {
    en: 'Quantity after',
    nb: 'Antall etter',
    sv: 'Størrelse sv',
  },
  Used: {
    en: 'Used',
    nb: 'Benyttet',
  },
  UseAmountPlaceholder: {
    en: 'Use size',
    nb: 'Bruksstørrelse',
    sv: 'Størrelse sv',
  },
  UseAmountPlaceholderContract: {
    en: 'Quantity',
    nb: 'Antall',
    sv: 'Størrelse sv',
  },
  UseAmountTooltip: {
    en: 'The size of the usage',
    nb: 'Størrelsen på bruken',
    sv: 'Størrelse sv',
  },
  UseAmountTooltipContract: {
    en: 'The size of the execution',
    nb: 'Størrelsen på utøvelsen',
    sv: 'Størrelse sv',
  },

  UseDate: {
    en: 'Date Used',
    nb: 'Bruksdato',
  },
  UseDatePlaceholder: {
    en: 'Use date',
    nb: 'Bruksdato',
    sv: 'Størrelse sv',
  },
  UseDatePlaceholderContract: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Størrelse sv',
  },
  UseDateTooltipContract: {
    en: 'The date of the execution',
    nb: 'Datoen til utøvelsen',
    sv: 'Størrelse sv',
  },
  UseDateTooltip: {
    en: 'The date of the usage',
    nb: 'Dato til bruken',
    sv: 'Størrelse sv',
  },
  UpdateUse: {
    en: 'Update use',
    nb: 'Oppdater bruk',
    sv: 'Størrelse sv',
  },
  NewUse: {
    en: 'Register use',
    nb: 'Registrer bruk',
    sv: 'UpdateUse sv',
  },
  NewUseContract: {
    en: 'Register execution',
    nb: 'Registrer utøvelse',
    sv: 'UpdateUse sv',
  },
  RegisterContract: {
    en: 'Register',
    nb: 'Registrer',
    sv: 'UpdateUse sv',
  },
  NewUseTitle: {
    en: 'Register use of authorization',
    nb: 'Registrer bruk av fullmakt',
    sv: 'NewUseTitle sv',
  },
  NewUseTitleContract: {
    en: 'Register execution of agreement',
    nb: 'Registrer utøvelse av avtale',
    sv: 'NewUseTitle sv',
  },
  DeleteUseTitle: {
    en: 'Delete use',
    nb: 'Slett bruk',
  },
  DeleteUseText: {
    en: 'Deleting a use cannot be undone. Do you want to delete?',
    nb: 'Sletting av bruk kan ikke angres. Ønsker du å slette?',
  },
  Created: {
    en: 'Created',
    nb: 'Vedtatt',
    sv: 'Størrelse sv',
  },
  BoardAccess: {
    en: 'Only for board',
    nb: 'Bare for styret',
    sv: 'Størrelse sv',
  },
  Public: {
    en: 'Public',
    nb: 'Offentlig',
    sv: 'Størrelse sv',
  },
  PublishedText: {
    en: 'Published',
    nb: 'Publisert',
    sv: 'Størrelse sv',
  },
  ALLTASKS: {
    en: 'All',
    nb: 'Alle',
    sv: 'Størrelse sv',
  },
  COMPLETEDTASKS: {
    en: 'Only complete',
    nb: 'Kun fullført',
    sv: 'Størrelse sv',
  },
  UNFINISHEDTASKS: {
    en: 'Only incomplete',
    nb: 'Kun uferdige',
    sv: 'Størrelse sv',
  },
  xlsx: {
    en: 'Excel (.xlsx)',
    nb: 'Excel (.xlsx)',
    sv: 'Excel (.xlsx)',
  },
  csv: {
    en: 'CSV (.csv)',
    nb: 'CSV (.csv)',
    sv: 'CSV (.csv)',
  },
  DateRange: {
    en: 'Created date range',
    nb: 'Opprettet datointervall',
  },
  DeleteMessageHeader: {
    en: 'Warning',
    nb: 'Advarsel',
  },
  DeleteMessageText: {
    en: 'Are you sure that you want to delete the message. This action cannot be undone.',
    nb: 'Er du sikker på at du vil slette meldingen? Denne handlingen kan ikke angres.',
  },
  Expires: {
    en: 'Expires',
    nb: 'Utløper',
    sv: 'Størrelse sv',
  },
  Registered: {
    en: 'Registered',
    nb: 'Registrert',
    sv: 'Størrelse sv',
  },
  IssuingSharesTitle: {
    en: 'Authorization For Issuing New Shares',
    nb: 'Fullmakt til å forhøye aksjekapital',
    sv: 'Størrelse sv',
  },
  ClaimTitle: {
    en: 'Authorization For Stock Claim',
    nb: 'Fullmakt til å erverve avtalepant i egne aksjer',
    sv: 'Størrelse sv',
  },
  ConvertibleTitle: {
    en: 'Authorization For Convertible Loan',
    nb: 'Fullmakt til å ta opp konvertibelt lån',
    sv: 'Størrelse sv',
  },
  BuySharesTitle: {
    en: 'Authorization For Buying Company Shares',
    nb: 'Fullmakt til å erverve egne aksjer',
    sv: 'Størrelse sv',
  },
  DividendsTitle: {
    en: 'Authorization For Granting Dividends',
    nb: 'Fullmakt til utdeling av utbytte',
    sv: 'Størrelse sv',
  },
  OptionsTitle: {
    en: 'Authorization For Granting Options',
    nb: 'Fullmakt opsjonsprogram',
    sv: 'Størrelse sv',
  },
  AuthBuySharesInfo: {
    en: "The general assembly's decision must contain information on how long the authorization will be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take place. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløpet som kan betales for aksjene, og om hvilke måter erverv og avhendelse av egne aksjer kan skje på. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthClaimInfo: {
    en: "The general assembly's decision must contain information on how long the authorization is to be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløp som kan betales for aksjene, og på hvilke måter erverv og avhendelse av egne aksjer kan skje. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthConvertibleInfo: {
    en: "The General Assembly's decision must contain information on the total loan amount that can be taken out, the amount by which the share capital can be increased, and how long the authorization is to be valid.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om det samlede lånebeløp som kan tas opp, beløpet aksjekapitalen skal kunne forhøyes med, og hvor lenge fullmakten skal gjelde.',
  },
  AuthDividendsInfo: {
    en: 'When the annual accounts for the last year have been approved, the general meeting can authorize the board to decide on the distribution of dividends. The authorization is valid until the next ordinary general meeting.',
    nb: 'Når årsregnskapet for siste år er godkjent, kan generalforsamlingen gi styret fullmakt til å beslutte utdeling av utbytte. Fullmakten gjelder frem til neste ordinære generalforsamling.',
  },
  AuthIssueSharesInfo: {
    en: "The general assembly can authorize the board to increase the share capital by issuing new shares. The general assembly's decision must state the amount by which the share capital is to be increased, and how long the authorization is to be valid. The general assembly's decision on board authorization must be reported to the Companies Register without delay. The board cannot make use of the authorization before it has been registered.",
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I generalforsamlingens beslutning må det stå oppgitt beløpet aksjekapitalen skal forhøyes med, og hvor lenge fullmakten skal gjelde. Generalforsamlingens beslutning om styrefullmakt skal uten opphold meldes til Foretaksregisteret. Styret kan ikke gjøre bruk av fullmakten før den er registrert.',
  },
  AuthOptionsInfo: {
    en: 'The general assembly can authorize the board to increase the share capital by issuing new shares. In connection with such an authorization, the board at the general assembly can obtain an authorization on how much of the authorization can be used in connection with an option program.',
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I forbindelse med en slik fullmakt kan styret på generalforsamling få en fullmakt på hvor mye av fullmakten som kan benyttes i forbindelse med et opsjonsprogram.',
  },
  AuthBuySharesReadMore: {
    en: 'Read more in sections 9-2 and 9-4 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMore: {
    en: 'Read more in section 9-5 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 9-5.',
  },
  AuthConvertibleReadMore: {
    en: 'Read more in section 11-8 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 11-8.',
  },
  AuthDividendsReadMore: {
    en: 'Read more in section 8-2 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 8-2.',
  },
  AuthIssueSharesReadMore: {
    en: 'Read more in chapter 10 of the Norwegian Company Act.',
    nb: 'Les mer i aksjeloven kapittel 10.',
  },
  AuthBuySharesReadMoreASA: {
    en: 'Read more in section 9-2 and 9-4 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMoreASA: {
    en: 'Read more in section 9-5 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 9-5.',
  },
  AuthConvertibleReadMoreASA: {
    en: 'Read more in section 11-8 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 11-8.',
  },
  AuthDividendsReadMoreASA: {
    en: 'Read more in section 8-2 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 8-2.',
  },
  AuthIssueSharesReadMoreASA: {
    en: 'Read more in the Norwegian public Limited Liability Companies Act, chapter 10.',
    nb: 'Les mer i allmennaksjeloven kapittel 10.',
  },

  ImportAuthorizations: {
    en: 'Import from Brønnøysund Registers',
    nb: 'Importer fra Brønnøysundregistrene',
    sv: 'Størrelse sv',
  },
  IssueSharesAuthCreated: {
    en: 'Issue shares authorization created',
    nb: 'Fullmakt til å øke aksjekapitalen ble registrert',
    sv: 'Størrelse sv',
  },
  ConvertibleLoanAuthCreated: {
    en: 'Convertible loan authorization created',
    nb: 'Fullmakt til å ta opp konvertibelt lån ble registrert',
    sv: 'Størrelse sv',
  },
  BuySharesAuthCreated: {
    en: 'Buy shares authorization created',
    nb: 'Fullmakt til å erverve egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  ClaimAuthCreated: {
    en: 'Claim authorization created',
    nb: 'Fullmakt til å erverve avtalepant på egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  DividendsAuthCreated: {
    en: 'Dividends authorization created',
    nb: 'Utbytte fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  OptionsAuthCreated: {
    en: 'Options authorization created',
    nb: 'Opsjons fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  AuthDeleted: {
    en: 'Authorization deleted',
    nb: 'Fullmakt slettet',
    sv: 'Størrelse sv',
  },
  AuthSizePlaceholder: {
    en: 'Authorization Size (NOK)',
    nb: 'Fullmaktens størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  AuthSizeTooltip: {
    en: 'The size of the authorization (NOK)',
    nb: 'Størrelsen på fullmakten (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizePlaceholder: {
    en: 'Loan size (NOK)',
    nb: 'Lånets størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizeTooltip: {
    en: 'The size of the loan (NOK)',
    nb: 'Størrelsen på lånet (NOK)',
    sv: 'Størrelse sv',
  },
  AuthStartDate: {
    en: 'Date given',
    nb: 'Dato gitt',
  },
  AuthStartDateTooltip: {
    en: 'The starting date of the authorization',
    nb: 'Datoen fullmakten er gitt',
  },
  AuthExpirationDate: {
    en: 'Date expires',
    nb: 'Utløpsdato',
  },
  AuthExpirationDateTooltip: {
    en: 'The expiration date of the authorization',
    nb: 'Utløpsdatoen til fullmakten',
  },
  RegistrationDate: {
    en: 'Registered in Brreg',
    nb: 'Registrert i Brreg',
    sv: 'Størrelse sv',
  },
  RegistrationDateTooltip: {
    en: 'The Registration date in the Foretaksregister',
    nb: 'Datoen fullmakten ble registrert i Foretaksregistert',
    sv: 'Størrelse sv',
  },
  Notes: {
    en: 'Notes',
    nb: 'Notater',
    sv: 'Størrelse sv',
  },
  NorwegianCompany: {
    en: 'Norwegian',
    nb: 'Norsk',
    sv: 'Størrelse sv',
  },
  ForeignCompany: {
    en: 'Foreign',
    nb: 'Utenlandsk',
    sv: 'Størrelse sv',
  },

  NotesTooltip: {
    en: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    nb: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    sv: 'Størrelse sv',
  },
  NotesPlaceholder: {
    en: 'Here you can enter notes for the authorization',
    nb: 'Her kan du skrive inn notater for fullmakten',
    sv: 'Størrelse sv',
  },
  EmailNoInfo: {
    en: 'No information about the invitation is available. Has an invitation been sent via email to this user?',
    nb: 'Ingen informasjon om invitasjonen er tilgjengelig. Er det sendt invitasjon på e-post til denne brukeren?',
  },
  Delivery: {
    en: 'Invitation has been sent via email. Click on the icon for more information.',
    nb: 'Invitasjon er sendt på e-post. Klikk på ikonet for ytterligere informasjon.',
  },
  NewUserDelivery: {
    en: 'Invitation has been sent via email.',
    nb: 'Invitasjon er sendt på e-post.',
  },
  NewUserPending: {
    en: 'Pending registeration email',
    nb: 'Venter på registrerings e-post',
  },
  NewUserRegistered: {
    en: 'This user is registered.',
    nb: 'Denne brukeren er registrert.',
  },

  EmailRecipients: {
    en: 'Email recipients',
    nb: 'E-postmottakere',
  },
  EmailRecipientsTooltip: {
    en: 'Here you can add recipients of the email by clicking the "Add people" button and entering their information ',
    nb: 'Her kan du legge til mottakere av e-posten ved å klikke på knappen "Legg til personer" og legge inn deres informasjon',
  },
  AddRecipients: {
    en: 'Add recipients',
    nb: 'Legg til mottakere',
  },
  Open: {
    en: "The invitation has been opened in the user's email program. Click on the icon for more information.",
    nb: 'Invitasjonen er åpnet i brukerens e-postprogram. Klikk på ikonet for ytterligere informasjon.',
    sv: 'Invitasjonen er åpnet i brukerens e-postprogram. Klikk på ikonet for ytterligere informasjon.',
  },
  Click: {
    en: 'The user has clicked the button in the invitation to join the meeting. Click on the icon for more information.',
    nb: 'Brukeren har trykket på knappen i invitasjonen for å gå til møtet. Klikk på ikonet for ytterligere informasjon.',
    sv: 'Brukeren har trykket på knappen i invitasjonen for å gå til møtet. Klikk på ikonet for ytterligere informasjon.',
  },
  Library: {
    en: 'Library',
    nb: 'Bibliotek',
    sv: 'Bibliotek',
  },
  'New item': {
    en: 'New item',
    nb: 'Ny sak',
    sv: 'Nytt ämne',
  },
  SelectMeeting: {
    en: 'Select meeting',
    nb: 'Velg møte',
    sv: 'Välj möte',
  },
  MinutesCreated: {
    en: 'Minutes created',
    nb: 'Agendapunkt opprettet',
    sv: 'Protokoll skapad',
  },
  durationLabel: {
    nb: 'Beregnet tid (min)',
    en: 'Estimated time (min)',
  },
  CreateMinutcase: {
    en: 'Create agenda item for meeting',
    nb: 'Opprett agendapunkt til møte',
    sv: 'Skapa minnesmapp för möte',
  },
  CreateMinutcaseFromTaskButtonText: {
    en: 'Create',
    nb: 'Opprett',
    sv: 'Skapa minnesmapp', //TODO: Translate
  },
  NoMeetingsAvailable: {
    en: "You don't have any future meetings that this agenda item can be linked to.",
    nb: 'Du har ingen fremtidige møter som dette agendapunktet kan knyttes til.',
    sv: 'Du har ingen fremtidige møter som dette agendapunktet kan knyttes til.',
  },
  MeetingInformation: {
    en: 'Meeting information',
    nb: 'Møteinformasjon',
    sv: 'Mötesinformation',
  },

  MeetingDate: {
    en: 'Meeting date',
    nb: 'Møtedato',
    sv: 'Mötesdatum',
  },
  CaseCreated: {
    nb: 'Styresak er lagret!',
    en: 'Board case saved!',
  },
  OpenMeeting: {
    en: 'Open meeting',
    nb: 'Åpne møtet',
    sv: 'Öppna mötet',
  },

  LinkToTask: {
    en: 'Link to task',
    nb: 'Lenke til oppgave',
    sv: 'Länk till uppgift',
  },
  'Add new agenda item': {
    en: 'Add new agenda item',
    nb: 'Legg til nytt agenda punkt',
    sv: 'Lägg till nytt ämne',
  },
  AgendaSuggestions: {
    en: 'About agenda suggestions',
    nb: 'Om agendaforslag',
    sv: 'Om agendaforslag',
  },
  ManageAgendaSuggestions: {
    en: 'Manage agenda suggestions',
    nb: 'Administrer agendaforslag',
    sv: 'Administrer agendaforslag',
  },
  ManageAgendaSuggestionsInfo: {
    en: 'Agenda suggestions are templates for new agenda items in a meeting. Orgbrain has several agenda suggestions ready to use. In addition, you can create custom agenda suggestions for your organization. The agenda suggestions will appear when you edit the agenda for a meeting.',
    nb: 'Agendaforslag er maler for nye agendapunkter i et møte. Orgbrain har mange agendaforslag klare til bruk. I tillegg kan du lage egendefinerte agendaforslag for din organisasjon. Agendaforslagene dukker opp når du redigerer agendaen for et møte.',
    sv: '',
  },
  DeleteEmissionPlanQuestion2: {
    en: 'You are about to delete a capital increase. This cannot be undone. Do you want to delete?',
    nb: 'Du er i ferd med å slette en kapitalforhøyelse. Dette kan ikke angres. Ønsker du å slette? ',
    sv: 'Du håller på att ta bort en kapitalökning. Detta kan inte ångras. Vill du ta bort?',
  },
  FilterByTopic: {
    en: 'Filter',
    nb: 'Filtrer etter tema',
    sv: 'Filtrer etter tema',
  },
  MeetingType: {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Møtetype',
  },
  MeetingTypeInfoBox: {
    en: 'Select the type of meetings the agenda item applies to. The agenda item will only appear in these meetings.',
    nb: 'Velg hvilken type møter agendapunktet gjelder for. Agendapunktet vil kun vises på disse møtene.',
    sv: 'sv',
  },
  MaximumAlternatives: {
    en: 'Maximum alternatives',
    nb: 'Antall alternativer',
    sv: 'Antall alternativer',
  },
  MaximumElected: {
    en: 'Election',
    nb: 'Personvalg',
    sv: 'Antall posisjoner',
  },
  ProposedSolution: {
    en: 'Proposed solution',
    nb: 'Forslag til vedtak',
    sv: 'Løsning',
  },
  HelpText: {
    en: 'Helptext',
    nb: 'Hjelpetekst',
    sv: 'Hjelpetekst',
  },
  CancelEdit: {
    en: 'Cancel editing',
    nb: 'Avbryt redigering',
    sv: 'Avbryt redigering',
  },
  SetConversationHistoryText: {
    en: 'How much of the conversation history do you want to share with the user?',
    nb: 'Hvor mye av samtale historien vil du dele med brukeren?',
    sv: 'Hvor mye av samtale historien vil du dele med brukeren?',
  },

  BeginningOfConversationText: {
    en: 'This is the beginning of the conversation',
    nb: 'Dette er begynnelsen på samtalen',
    sv: '',
  },
  ConversationHistory: {
    en: 'Change the access this user has to the conversation history',
    nb: 'Endre tilgangen denne brukeren har til samtalehistorikken',
    sv: '',
  },
  ReadAccessDate: {
    en: 'Share conversation history',
    nb: 'Del samtale historie',
    sv: 'Del samtale historie',
  },
  CompleteHistory: {
    en: 'Share complete history',
    nb: 'Del fullstendig samtalehistorikk',
    sv: 'Del samtale historie',
  },
  PeriodHistory: {
    en: 'Share last month',
    nb: 'Del siste måneden',
    sv: 'Del samtale historie',
  },
  NoneHistory: {
    en: `Don't share any history`,
    nb: 'Ikke del noen samtalehistorikk',
    sv: 'Del samtale historie',
  },
  CustomHistory: {
    en: 'Choose date',
    nb: 'Velg dato',
    sv: 'Del samtale historie',
  },

  EmojiSearch: {
    en: 'Search',
    nb: 'Søk',
    sv: 'Söka',
  },
  EmojiNoResults1: {
    en: '',
    nb: '',
    sv: '',
  },
  EmojiNoResults2: {
    en: 'This emoji does not exist',
    nb: 'Denne emojien finnes ikke',
    sv: 'Denna emoji finns inte',
  },
  EmojiPick: {
    en: 'Pick an emoji',
    nb: 'Velg en emoji',
    sv: 'Välj en emoji',
  },
  EmojiAddCustom: {
    en: 'Add custom emoji',
    nb: 'Legg til tilpasset emoji',
    sv: 'Lägg till anpassad emoji',
  },

  EmojiCategoriesActivity: {
    en: 'Activity',
    nb: 'Aktivitet',
    sv: 'Aktivitet',
  },
  EmojiCategoriesCustom: {
    en: 'Custom',
    nb: 'Tilpasset',
    sv: 'Anpassad',
  },
  EmojiCategoriesFlags: {
    en: 'Flags',
    nb: 'Flagg',
    sv: 'Flaggor',
  },
  EmojiCategoriesFoods: {
    en: 'Food & Drink',
    nb: 'Mat & Drikke',
    sv: 'Mat & Dryck',
  },
  EmojiCategoriesRecent: {
    en: 'Frequently used',
    nb: 'Ofte brukt',
    sv: 'Ofta använd',
  },
  EmojiCategoriesNature: {
    en: 'Animals & Nature',
    nb: 'Dyr & Natur',
    sv: 'Djur & Natur',
  },
  EmojiCategoriesObjects: {
    en: 'Objects',
    nb: 'Objekter',
    sv: 'Objekt',
  },
  EmojiCategoriesPeople: {
    en: 'Smileys & People',
    nb: 'Smileys & Mennesker',
    sv: 'Smileys & Människor',
  },
  EmojiCategoriesPlaces: {
    en: 'Travel & Places',
    nb: 'Reise & Steder',
    sv: 'Resor & Platser',
  },
  EmojiCategoriesSearch: {
    en: 'Search Results',
    nb: 'Søkeresultater',
    sv: 'Sökresultat',
  },
  EmojiCategoriesSymbols: {
    en: 'Symbols',
    nb: 'Symboler',
    sv: 'Symboler',
  },
  EmojiSkinsChoose: {
    en: 'Choose default skin tone',
    nb: 'Velg standard hudtone',
    sv: 'Välj standard hudfärg',
  },
  EmojiSkins1: {
    en: 'Default',
    nb: 'Standard',
    sv: 'Standard',
  },
  EmojiSkins2: {
    en: 'Light',
    nb: 'Lys',
    sv: 'Ljus',
  },
  EmojiSkins3: {
    en: 'Medium-Light',
    nb: 'Mellom-Lys',
    sv: 'Mellan-Ljus',
  },
  EmojiSkins4: {
    en: 'Medium',
    nb: 'Medium',
    sv: 'Medium',
  },
  EmojiSkins5: {
    en: 'Medium-Dark',
    nb: 'Mellom-Mørk',
    sv: 'Mellan-Mörk',
  },
  EmojiSkins6: {
    en: 'Dark',
    nb: 'Mørk',
    sv: 'Mörk',
  },
  ImportantInfo: {
    en: 'Important information',
    nb: 'Viktig informasjon',
  },
  ConversationsIntroHeader: {
    en: 'Introduction to conversations',
    nb: 'Introduksjon til samtaler',
    sv: 'Del samtale historie',
  },
  ConversationsInfoText1: {
    en: 'Conversations is a feature where conversation groups can be created, allowing users to discuss specific topics or send private messages to each other. Only those with the necessary permissions can create a new conversation. A user can be a member of several conversations at the same time. Only members of a conversation group have access to it. After a conversation group is created, new users can be invited to join.',
    nb: 'Samtaler er en funksjon der det opprettes samtalegrupper hvor brukerne kan diskutere spesifikke temaer eller sende private meldinger til hverandre. Kun de som har fått nødvendige rettigheter, kan opprette en ny samtale. En bruker kan være medlem av flere samtaler samtidig. Kun medlemmer av en samtalegruppe har tilgang til den. Etter at en samtalegruppe er opprettet, kan nye brukere inviteres inn.',
    sv: 'Del samtale historie',
  },
  ConversationsInfoText2: {
    en: 'Start using conversations by creating a new one by clicking on the "New Conversation"-icon at the top left corner, or select an existing conversation on the left. To get the most out of conversations, we recommend going to the settings on your profile and adjusting your notification settings so you can be notified about important updates in the organization.',
    nb: 'Ta i bruk samtaler ved å opprette en ny samtale ved å klikke på "Ny samtale"-ikonet øverst i venstre hjørne eller velg en allerede eksisterende samtale til venstre. For å få mest nytte ut av samtaler anbefaler vi å gå inn på innstillingene på profilen din og tilpasse varslene dine slik at du kan bli varslet om nødvendige oppdateringer i virksomhetene dine.',
    sv: 'Del samtale historie',
  },
  ConversationsInfoText3: {
    en: 'Administrators can create contact groups, and this feature allows users with the investor or owner role to contact the organization through a contact group. To create a contact group, click on "Contact Groups" at the top left corner and then on "New Contact Group". Fill in the name and select the members who will represent the organization.',
    nb: 'Administrator kan opprette kontaktgrupper, denne funksjonen gir brukere med investor eller eier rollen tilgang til å kontakte virksomheten gjennom en kontaktgruppe. For å opprette en kontaktgruppe klikk på "Kontaktgrupper" øverst i venstre hjørne og deretter på "Ny kontaktgruppe". Fyll ut navn og velg de medlemmene som skal representere virksomheten.',
    sv: 'Del samtale historie',
  },
  ChatMessageMentionSetting: {
    en: 'When you are mentioned in a conversation or chat',
    nb: 'Når du blir nevnt i en samtale eller chat',
  },
  ChatMessageReplySetting: {
    en: 'When someone replies to your message in a conversation or chat',
    nb: 'Når noen svarer på meldingen din i en samtale eller chat',
  },
  UnreadChatMessagesSetting: {
    en: 'When there are unread messages in a conversation or chat that you are part of',
    nb: 'Når det er uleste meldinger i en samtale eller chat du er del av',
  },

  PrimaryResponsible: {
    en: 'This user is the primary responsible for this task',
    nb: 'Denne brukeren er hovedansvarlig for oppgaven',
  },
  AddedToConversationSetting: {
    en: 'When you are added to a conversation',
    nb: 'Når du blir lagt til i en samtale',
  },
  RemovedFromConversationSetting: {
    en: 'When you are removed from a conversation',
    nb: 'Når du blir fjernet fra en samtale',
  },
  NewCallInConversationSetting: {
    en: 'When someone starts a new call in a conversation',
    nb: 'Når noen starter et nytt videomøte i en samtale',
  },
  AddedAdminToUppercase: {
    en: 'Added admin to ',
    nb: 'Ga admin til ',
  },
  RemovedAdminFromUppercase: {
    en: 'Removed admin from ',
    nb: 'Fjernet admin fra ',
  },
  AddedAdminTo: {
    en: ' added admin to ',
    nb: ' ga admin til ',
  },
  RemovedAdminFrom: {
    en: ' removed admin from ',
    nb: ' fjernet admin fra ',
  },
  ConversationsInfoHeader1: {
    en: 'What is conversations:',
    nb: 'Hva er samtaler:',
    sv: 'Melding til ',
  },
  ConversationsInfoHeader2: {
    en: 'Use:',
    nb: 'Bruke:',
    sv: 'Melding til ',
  },
  ConversationsInfoHeader3: {
    en: 'For admin:',
    nb: 'For administrator:',
    sv: 'Melding til ',
  },
  MessagePlaceholder: {
    en: 'Message ',
    nb: 'Melding til ',
    sv: 'Melding til ',
  },
  ConversationsInfoBoxText: {
    en: 'Conversations is a feature where conversation groups can be created, allowing users to discuss specific topics or send private messages to each other. Only those with the necessary permissions can create a new conversation. A user can be a member of several conversations at the same time. Only members of a conversation group have access to it. After a conversation group is created, new users can be invited to join.',
    nb: 'Samtaler er en funksjon der det opprettes samtalegrupper hvor brukerne kan diskutere spesifikke temaer eller sende private meldinger til hverandre. Kun de som har fått nødvendige rettigheter, kan opprette en ny samtale. En bruker kan være medlem av flere samtaler samtidig. Kun medlemmer av en samtalegruppe har tilgang til den. Etter at en samtalegruppe er opprettet, kan nye brukere inviteres inn.',
    sv: 'Hjelpetekst',
  },

  HelptextInfoBox: {
    en: 'Here you can optionally include help text that assists users in better understanding the matter. This could, for example, be references to a law paragraph or best practices.',
    nb: 'Her kan du eventuelt legge inn hjelpetekst som hjelper brukerne å forstå saken bedre. Dette kan for eksempel være henvisninger til en lovparagraf eller beste praksis.',
    sv: 'sv',
  },
  MinuteNotesInfoBox: {
    en: 'Here you insert the text from the agenda item that should be included in the meeting minutes.',
    nb: 'Her legger du inn teksten fra agendapunktet som skal inn i møteprotokollen.',
    sv: 'sv',
  },
  DescInfoBox: {
    en: 'Here you write the text for the agenda item that should be included in the meeting notice.',
    nb: 'Her skriver du inn tekst til agendapunktet som skal med i innkallingen til møtet.',
    sv: ' sv',
  },
  DecisionInfoBox: {
    en: 'Here you write the proposed resolution to be included in the meeting notice. This text will also appear in the draft minutes.',
    nb: 'Her skriver du inn forslag til vedtak som kommer med i innkallingen til møtet. Denne teksten vil også vises i utkast til protokoll.',
    sv: 'sv',
  },
  CategoryInfoBox: {
    en: 'Assign the agenda item to the appropriate category from the menu. This category determines the processing procedure.',
    nb: 'Gi agendapunktet riktig kategori fra menyen. Denne kategorien bestemmer saksbehandlingsprosessen.',
    sv: 'sv',
  },
  TitleInfoBox: {
    en: 'Give the agenda item a title that briefly describes the matter.',
    nb: 'Gi agendapunktet en tittel som kort beskriver saken.',
    sv: 'sv',
  },
  ContactList: {
    en: 'Contact groups',
    nb: 'Kontaktgrupper',
    sv: '',
  },
  StartContactConversation: {
    en: 'Start conversation with the contact',
    nb: 'Start samtale med kontakten',
    sv: '',
  },
  StartConversationAdmin: {
    en: 'Start conversation',
    nb: 'Start samtale',
    sv: '',
  },
  ContactDialogInfo: {
    en: `Here you can start a conversation with the contacts listed below.`,
    nb: `Her kan du starte en samtale med kontaktene som er oppført i listen nedenfor.`,
    sv: '',
  },

  ContactDialogInfoAdmin: {
    en: `Here you can create different groups that users can contact if they wish to reach out to the organization. When creating the group, you choose who will be the contacts. For example, you can create a group called "The Board" where all board members are added as contacts, allowing shareholders to send inquiries and start a conversation or dialogue. Only those who are listed as contacts in the group, along with the user initiating contact, will be able to see the conversation.`,
    nb: `Her kan du opprette ulike grupper som brukerne kan kontakte dersom de ønsker å komme i kontakt med virksomheten. Når du oppretter gruppen, velger du hvem som skal være kontaktpersoner. For eksempel kan du opprette en gruppe som heter "Styret"' hvor alle styremedlemmer legges inn som kontaktpersoner, slik at aksjonærene kan sende henvendelser og starte en samtale eller dialog. Kun de som er lagt inn som kontaktpersoner i gruppen, samt brukeren som tar kontakt, vil kunne se samtalen.`,
    sv: '',
  },
  CreateContactList: {
    en: 'Contact groups',
    nb: 'Kontaktgrupper',
    sv: '',
  },
  OpenContactList: {
    en: 'Open contact list',
    nb: 'Åpne kontakt listen',
    sv: '',
  },
  CreateContactGroup: {
    en: 'Create contact group',
    nb: 'Opprett kontaktgruppe',
    sv: '',
  },
  EditContactGroup: {
    en: 'Edit contact group',
    nb: 'Rediger kontaktgruppe',
    sv: '',
  },
  ContactName: {
    en: 'Name of contact group',
    nb: 'Navn på kontaktgruppen',
    sv: '',
  },
  AddPeopleContact: {
    en: 'Add people to contact group',
    nb: 'Legg til i kontaktgruppen',
    sv: '',
  },
  CreateNewContact: {
    en: 'New contact group',
    nb: 'Ny kontaktgruppe',
    sv: '',
  },
  createConversationWithContactGroup: {
    en: 'Start conversation with the contact group',
    nb: 'Start samtale med kontaktgruppen',
    sv: '',
  },
  startConversationWithContactTitle: {
    en: `Send message`,
    nb: `Send melding`,
    sv: '',
  },
  Preferences: {
    en: `Preferences`,
    nb: `Preferanser`,
    sv: '',
  },
  startConversationWithContact: {
    en: `You can start a conversation or send a message to the contact group by writing your inital message in the field below and clicking "Send"`,
    nb: `Du kan starte en samtale eller sende en melding til kontaktgruppen ved å fylle ut meldingen din i feltet under og deretter klikke "Send"`,
    sv: '',
  },
  ContactConversation: {
    en: 'Contact conversations',
    nb: 'Kontaktsamtaler',
    sv: '',
  },
  InviteToCall: {
    en: 'Invite to call',
    nb: 'Inviter til videomøte',
    sv: '',
  },
  Invited: {
    en: ' invited ',
    nb: ' inviterte ',
    sv: '',
  },
  ToCall: {
    en: ' to a call.',
    nb: ' til et videomøte.',
    sv: '',
  },

  Members: {
    en: 'Members',
    nb: 'Medlemmer',
    sv: '',
  },
  Category: {
    en: 'Category',
    nb: 'Kategori',
    sv: 'Kategori',
  },
  now: {
    en: 'now',
    nb: 'nå',
    sv: 'nu',
  },
  minuteShortFormat: {
    en: 'minute',
    nb: 'minutt',
    sv: 't',
  },
  hourShortFormat: {
    en: 'hour',
    nb: 'time',
    sv: 't',
  },
  hoursShortFormat: {
    en: 'hours',
    nb: 'timer',
    sv: 't',
  },
  dayShortFormat: {
    en: 'day',
    nb: 'dag',
    sv: 't',
  },
  daysShortFormat: {
    en: 'days',
    nb: 'dager',
    sv: 't',
  },
  timeAgo: {
    en: '',
    nb: '',
    sv: 't',
  },
  DeleteConversation: {
    en: 'Delete the entire conversation',
    nb: 'Slett hele samtalen',
    sv: 't',
  },
  DeleteConversationText: {
    en: 'You are about to delete the entire conversation, are you sure?',
    nb: 'Du er i ferd med å slette hele samtalen, er du sikker?',
    sv: 't',
  },
  MessageHistoryTooltip: {
    en: 'The date shows the history that has been shared with the user',
    nb: 'Datoen viser samtalehistorikken som er delt med brukeren',
    sv: 't',
  },
  FilterAgenda: {
    en: 'Find agenda suggestions',
    nb: 'Finn agendaforslag',
    sv: 'Kategori',
  },
  NewAgenda: {
    en: 'Make new agenda suggestion',
    nb: 'Lag nytt agendaforslag',
    sv: 'Kategori',
  },
  DeleteEmissionPlanQuestion: {
    en: 'You are about to delete a capital increase. This cannot be undone. Do you want to delete?',
    nb: 'Du er i ferd med å slette en kapitalforhøyelse. Dette kan ikke angres. Ønsker du å slette? ',
    sv: 'Du håller på att ta bort en kapitalökning. Detta kan inte ångras. Vill du ta bort?',
  },
  OrgbrainSignFooter: {
    en: 'This document is signed with Orgbrain eSign\n- a standard electronic signature compliant\nwith EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign\n- en standard elektronisk signatur i samsvar\nmed EU-forordning 910/2014 (eIDAS).',
  },
  SearchMessages: {
    en: 'Search for messages',
    nb: 'Søk på meldinger',
  },
  IncludeShareNumbersTooltip: {
    en: 'Do you want to include share numbers in the PDF. NOTE: This option is only shown for companies with valid share numbers',
    nb: 'Ønsker du å inkludere aksjenummer i PDF-en. OBS: Dette valget vises kun om selskapet ditt har gyldige aksjenummer',
  },
  IncludeShareNumbers: {
    en: 'Include share numbers',
    nb: 'Inkluder aksjenummer',
  },
  NEW: {
    en: 'NEW',
    nb: 'NY',
  },
  owners: {
    en: 'owners',
    nb: 'eiere',
  },
  SearchGlobal: {
    en: 'Search for conversations, people and messages',
    nb: 'Søk på samtaler, personer og meldinger',
  },
  ConversationSearch: {
    en: 'Conversations search',
    nb: 'Samtale søk',
  },
  SearchConversations: {
    en: 'Conversations search',
    nb: 'Samtale søk',
  },

  NewName: {
    en: 'New conversation name',
    nb: 'Nytt samtale navn',
  },
  SearchMessagesText: {
    en: 'No messages, use the search field to find messages in this conversation',
    nb: 'Ingen meldinger her, bruk søkefeltet for å finne meldinger i denne samtalen',
  },
  OrgbrainSignQRFooter: {
    en: 'For more information see\n \nOr scan the QR code',
    nb: 'For mer informasjon se\n \nEller skann QR koden',
  },
  DeleteContact: {
    en: 'Delete contact group',
    nb: 'Slett kontaktgruppen',
  },

  OrgbrainSignHeader: {
    en: 'This document is signed with Orgbrain eSign - a standard electronic\nsignature compliant with EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign - en standard elektronisk\nsignatur i samsvar med EU-forordning 910/2014 (eIDAS).',
  },
  NoContacts: {
    en: 'No contacts are added to this organization',
    nb: 'Ingen kontakter er lagt til denne virksomheten',
  },

  SignicatRequestHeader: {
    en: 'Orgbrain Signing Request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskTitle: {
    en: 'Orgbrain signing request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskMessage: {
    en: 'Please sign this document',
    nb: 'Vennligst signer dette dokumentet',
  },
  NOLang: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  ENLang: {
    en: 'English',
    nb: 'Engelsk',
  },
  SELang: {
    en: 'Swedish',
    nb: 'Svensk',
  },
  INFORMATION: {
    en: 'Information',
    nb: 'Informasjon',
  },
  DISCUSSION: {
    en: 'Discussion',
    nb: 'Diskusjon',
  },
  DECISION: {
    en: 'Decision',
    nb: 'Beslutning',
  },
  ELECTION: {
    en: 'Election',
    nb: 'Valg',
  },
  FormalitiesTopic: {
    en: 'Formalities',
    nb: 'Formalia',
  },
  moveShares: {
    nb: 'Endre aksjeklasse',
    en: 'Change share class',
  },
  refreshShareNumbers: {
    nb: 'Regenerer aksjenummer',
    en: 'Regenerate sharenumbers',
  },
  refreshShareNumbersTooltip: {
    nb: 'Automatisk gi aksjene nye nummer basert på nåværende status i aksjeeierboken',
    en: 'Automatically assign new numbers to the shares based on the current status in the shareholder register.',
  },
  RefreshShareNumberEventAdded: {
    nb: 'Hendelsen regenerer aksjenummer har blitt opprettet.',
    en: 'The event regenerate sharenumbers has been created.',
  },
  ReportingTopic: {
    en: 'Reporting',
    nb: 'Rapportering',
  },
  Representative: {
    en: 'Representative',
    nb: 'Representant',
  },
  ShareholdersEvents: {
    en: 'shareholders',
    nb: 'aksjonærer',
  },
  ShareholderEvents: {
    en: 'Shareholder',
    nb: 'Aksjonær',
  },
  EconomyTopic: {
    en: 'Economy',
    nb: 'Økonomi',
  },
  toBuyer: {
    en: ' to ',
    nb: ' til ',
  },
  Timestamp: {
    en: 'Timestamp',
    nb: 'Tidspunkt',
  },

  PersonnelTopic: {
    en: 'Personnel',
    nb: 'Personale',
  },
  AppointmentsTopic: {
    en: 'Appointments',
    nb: 'Avtaler',
  },
  SupervisionTopic: {
    en: 'Supervision',
    nb: 'Tilsyn',
  },
  ShareholdersTopic: {
    en: 'Owners/Members',
    nb: 'Eiere/Medlemmer',
  },
  CEOTopic: {
    en: 'CEO',
    nb: 'Daglig leder',
  },
  StrategyTopic: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ProxyTopic: {
    en: 'Power of attorney',
    nb: 'Fullmakter',
  },
  StatutesTopic: {
    en: 'Statutes',
    nb: 'Vedtekter',
  },
  PAUSE: {
    en: 'Pause',
    nb: 'Pause',
  },
  NO_CATEGORY: {
    en: 'No category',
    nb: 'Ingen kategori',
  },
  ALTERNATIVE_OPTIONS: {
    en: 'Alternative options',
    nb: 'Alternative valg',
  },
  NoMeetingType: {
    en: 'Unmarked meetings',
    nb: 'Umarkerte møter',
  },

  InviteLink: {
    en: 'Invitation link',
    nb: 'Invitasjonskoblingen',
  },
  LinkDuration: {
    en: 'Duration for the invitation link',
    nb: 'Varighet for invitasjonskoblingen:',
  },
  EmailMessage: {
    en: 'Email message',
    nb: 'E-postmelding',
  },

  NewMessageFrom: {
    en: 'New message from',
    nb: 'Ny melding fra',
  },
  ClickHereToViewTheMessage: {
    en: 'Click here to view the message',
    nb: 'Klikk her for å se meldingen',
  },
  HereLink: {
    en: 'here',
    nb: 'her',
  },
  'Email address update': {
    en: 'Email address update',
    nb: 'Oppdatering av e-postadresse',
  },
  'Please use the code below to confirm your new email address': {
    en: 'Please use the code below to confirm your new email address',
    nb: 'Vennligst bekreft din nye e-postadresse ved å benytte koden under',
  },
  resetPassword: {
    en: 'Reset password',
    nb: 'Tilbakestille passord',
  },
  addInvestorsFromSpreadsheet: {
    en: 'Add investors from spreadsheet',
    nb: 'Legg til investorer fra regneark',
  },
  Move_shares: {
    en: 'Change shareclass',
    nb: 'Endre aksjeklasse',
  },
  weReceivedRequest: {
    en: 'We have received a request to reset the password for',
    nb: 'Vi har mottatt en forespørsel om å tilbakestille passordet for',
  },
  pleaseUseLink: {
    en: 'Please use the following link',
    nb: 'Bruk følgende lenke',
  },
  HelloEmailText: {
    en: 'Hi',
    nb: 'Hei',
  },
  InvitedToSurveyText: {
    en: 'You have been invited to respond to a survey for {{ORG}}.',
    nb: 'Du er blitt invitert til å svare på en undersøkelse for {{ORG}}.',
  },
  ReminderForSigningRequest: {
    en: 'Reminder for signing request',
    nb: 'Påminnelse om signeringsforespørsel',
  },

  GaveActionPointResponsibility: {
    en: 'gave you a new task or question titled',
    nb: 'har tildelt deg, eller lagt til informasjon, i oppgaven',
  },
  Task: {
    en: 'Task',
    nb: 'Oppgave',
  },
  WithDeadline: {
    en: 'With deadline:',
    nb: 'Frist:',
  },
  ClickBelowTask: {
    en: 'Click the button below to view the task.',
    nb: 'Klikk på knappen under for å se oppgaven.',
  },
  HasSavedInformationTask: {
    en: 'has entered new information in the task',
    nb: 'har lagt inn ny informasjon i oppgaven',
  },
  GoToTask: {
    en: 'Go to Task',
    nb: 'Gå til oppgave',
  },

  SharesHaveBeenTransferredToYou: {
    en: 'Shares have been transferred to you',
    nb: 'Aksjer har blitt overført til deg',
  },
  MessageAboutTransferOfSharesIn: {
    nb: 'Melding om overføring av aksjer i',
    en: 'Message about transfer of shares in',
  },

  TheFollowingHasBeenRegisteredOn: {
    en: 'The following has been registered on ',
    nb: 'Følgende er blitt registrert på ',
  },
  InTheShareholderRegisterOf: {
    en: ' in the shareholder register of ',
    nb: ' i aksjeeierboken til ',
  },
  Recipient: {
    en: 'Recipient',
    nb: 'Mottaker',
  },
  BestRegards: {
    en: 'Best regards',
    nb: 'Vennlig hilsen',
  },
  Sender: {
    en: 'Sender',
    nb: 'Avsender',
  },
  Hi: {
    en: 'Hi',
    nb: 'Hei',
  },
  Time: {
    en: 'Time',
    nb: 'Tidspunkt',
  },
  NewInventory: {
    en: 'New inventory',
    nb: 'Ny beholdning',
  },
  ShareClass: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  Amount: {
    en: 'Amount',
    nb: 'Antall',
  },
  SharesWithClass: {
    en: 'shares, with class',
    nb: 'aksjer, med aksjeklasse',
  },
  HasBeenRegisteredToYou: {
    en: 'has been registered on you in an share issue',
    nb: 'har blitt registrert på deg i en kapitalforhøyelse',
  },
  ShareWithClass: {
    en: 'share, with class',
    nb: 'aksje, med aksjeklasse',
  },
  OfYourSharesWithClass: {
    en: 'of your shares, with class',
    nb: 'av dine aksjer, med aksjeklasse',
  },
  in: {
    en: 'in',
    nb: 'i',
  },
  HasBeenTransferredToYouFrom: {
    en: 'has been transferred to you from',
    nb: 'har blitt overført til deg fra',
  },
  HasBeenTransferredTo: {
    en: 'has been transferred to',
    nb: 'har blitt overført til',
  },
  WeWantToInformYouThat: {
    en: 'We would like to inform you that',
    nb: 'Vi vil med dette opplyse deg at',
  },
  HasBeenRegisteredAsBeneficialOwner: {
    en: 'has been registered as a beneficial owner with',
    nb: 'er registrert som reell rettighetshaver hos',
  },
  HasDecided: {
    nb: 'har vedtatt å tildele',
    en: 'has decided to award',
  },
  AsFollows: {
    nb: 'som følger',
    en: 'as follows',
  },
  PayoutDate: {
    en: 'Payout date',
    nb: 'Utbetalingsdato',
  },
  PayoutPerShare: {
    en: 'Payout per share',
    nb: 'Utbetaling per aksje',
  },
  TotalPayout: {
    en: 'Total payout',
    nb: 'Total utbetaling',
  },
  TotalPaidOut: {
    en: 'Paid out total',
    nb: 'Utbetaling totalt',
  },
  PayoutAccount: {
    en: 'Payout account',
    nb: 'Utbetalingskonto',
  },
  HeadlineTransaction1: {
    en: 'Decision on award of',
    nb: 'Vedtak om tildeling av',
  },
  HeadlineTransaction2: {
    en: 'from',
    nb: 'fra',
  },
  Shareholderreciver: {
    en: 'Shareholder',
    nb: 'Aksjonær',
  },
  Shareownerreciver: {
    en: 'Shareowner',
    nb: 'Aksjeeier',
  },
  NumberOfNewShares: {
    en: 'New shares',
    nb: 'Nye aksjer',
  },
  NewShareholding: {
    en: 'New shareholding',
    nb: 'Ny beholdning',
  },
  AwardTime: {
    en: 'Award time',
    nb: 'Tidspunkt',
  },
  Regards: {
    en: 'Regards',
    nb: 'Hilsen',
  },

  NumberOfShares: {
    en: 'Your current shareholding',
    nb: 'Aksjebeholdning',
  },
  BankNrNotSet: {
    nb: 'OBS! Det ser ut som vi ikke har registrert hvilken bankkonto utbetalingen skal skje til. Vennligst ta kontakt med oss slik at vi kan få registrert dette.',
    en: 'OBS! It seems like we have not registered which bank account the payout should be made to. Please contact us so that we can register this.',
  },
  ByBeneficialOwnerMeaning: {
    en: 'The law defines a beneficial owner as a natural person or persons who ultimately owns or controls a legal person, event, entity or other association.',
    nb: 'Med reell rettighetshaver menes i loven den eller de fysiske personene som i siste instans eier eller kontrollerer en juridisk person, arrangement, enhet eller annen sammenslutning.',
  },
  YouHaveBeenRegisteredAsABeneficialOwner: {
    en: 'You have been registered as a beneficial owner',
    nb: 'Du har blitt registrert som reell rettighetshaver',
  },
  ReadMoreHere: {
    en: 'Read more',
    nb: 'Les mer',
  },
  InvitationToVoteForMeetingTime: {
    en: 'Invitation to find a meeting time',
    nb: 'Invitasjon til å finne møtetidspunkt',
  },
  Svarfrist: {
    en: 'Reply before',
    nb: 'Svarfrist',
  },
  VoteTimeButtonText: {
    en: 'Vote on time',
    nb: 'Stem på tidspunkt',
  },
  BodyTextEmailForMeetingTime: {
    en: 'You have been invited to provide input on a meeting time for a scheduled meeting {{meetingName}} in {{orgName}}. Press the button below to give your feedback.',
    nb: 'Du har blitt invitert til å gi innspill til møtetidspunkt for et planlagt møte {{meetingName}} i {{orgName}}. Trykk på knappen under for å gi din tilbakemelding.',
  },
  TransactionType: {
    en: 'Transaction',
    nb: 'Transaksjon',
  },
  DeleteEvent: {
    en: 'Delete event',
    nb: 'Slett hendelse',
  },
  TransactionSeller: {
    en: 'Seller',
    nb: 'Selger',
  },
  TransactionBuyer: {
    en: 'Buyer',
    nb: 'Kjøper',
  },
  Price: {
    en: 'Price',
    nb: 'Pris',
  },
  NumStocks: {
    en: 'Number of stocks',
    nb: 'Antall akjser',
  },
  TotalPrice: {
    en: 'Total price',
    nb: 'Total pris',
  },
  EventsDeleteSubheader1: {
    en: 'This event will be deleted',
    nb: 'Denne hendelsen vil bli slettet',
  },
  TransferenceTo: {
    en: 'Transference to',
    nb: 'Overføring til',
  },
  TransferenceFrom: {
    en: 'Transference from',
    nb: 'Overføring fra',
  },
  TODO: {
    en: 'To-do',
    nb: 'Skal gjøres',
  },
  DOING: {
    en: 'Ongoing',
    nb: 'Pågår',
  },
  DONE: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CRITICAL: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  HIGH: {
    en: 'High',
    nb: 'Høy',
  },
  MEDIUM: {
    en: 'Medium',
    nb: 'Middels',
  },
  LOW: {
    en: 'Low',
    nb: 'Lav',
  },
  MAYBE: {
    en: 'No priority',
    nb: 'Ingen',
  },
  parValue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  Options: {
    en: 'Options',
    nb: 'Alternativer',
  },
  Instruction: {
    en: 'Instruction',
    nb: 'Instruksjon',
  },
  AttachmentTo: {
    en: 'Attachment to:',
    nb: 'Vedlegg til:',
  },
  MeetingBook: {
    en: 'Meeting book',
    nb: 'Møtebok',
  },
  sharedFolderTooltip: {
    en: 'A shared folder is a feature where you, as an administrator, can create a folder which can be shared. The folder can be shared with other organizations in the same corporate structure or with other organizations where you are the administrator. The organization that creates the folder is the owner and covers any storage and signing costs.',
    nb: 'Delt mappe er en funksjon der du som administrator kan opprette en mappe som kan deles med andre organisasjoner som er i samme konsernstruktur eller øvrige organisasjoner der du er administrator. Det er organisasjonen som oppretter mappen som er eier av mappen, og som dekker eventuelle kostnader ved lagring og signering.',
  },
  Tasks: {
    nb: 'Oppgaver',
    en: 'Tasks',
  },
  tasks: {
    nb: 'oppgaver',
    en: 'tasks',
  },
  TasksSelectDocumentType: {
    en: 'Download tasks',
    nb: 'Last ned oppgaver',
  },
  AnErrorOccured: {
    en: 'An error occured',
    nb: 'Det oppstod en feil',
  },
  NewTask: {
    en: 'New task',
    nb: 'Ny oppgave',
  },
  DownloadTasks: {
    en: 'Download tasks',
    nb: 'Last ned oppgaver',
  },
  TasksModuleHelper1: {
    nb: 'Her kan du lage oppgaver/aktiviteter med tidsfrist og prioritet som kan tildeles brukere i portalen. Du kan også opprette private oppgaver som kun du har tilgang til. For bedre prosjektstyring kan du registrere avhengigheter mellom oppgaver. Det er nyttig i tilfeller der en oppgave må fullføres (f.eks "Bygg grunnmur") før en annen ("Sett opp vegger") kan starte. En oppgave kan også settes opp til å bestå av flere underoppgaver. Det passer når alle underoppgavene må gjøres før oppgaven ferdigstilles.',
    en: 'Here you can create tasks/activities with deadlines and priorities that can be assigned to users in the portal. You can also create private tasks that only you have access to. For better project management, you can register dependencies between tasks. This is useful in cases where one task must be completed (e.g., "Build the foundation") before another ("Set up walls") can begin. A task can also be set up to consist of several subtasks. This is ideal when all subtasks need to be completed before the main task is finished.',
  },
  Complete: {
    en: 'Complete',
    nb: 'Fullfør',
  },
  TasksModuleHelper2: {
    nb: 'Du kan velge mellom listevisning eller diagramvisning for å organisere oppgavene. I Gantt-diagrammet kan du enkelt flytte oppgaver og justere tidsplanen visuelt. I tillegg kan du starte en dialog med brukere som har tilgang til en oppgave, noe som gjør det enkelt å samarbeide og dele oppdateringer direkte i systemet.',
    en: 'You can choose between a list view or a diagram view to organize the tasks. In the Gantt chart, you can easily move tasks and adjust the schedule visually. Additionally, you can start a dialogue with users who have access to a task, making it easy to collaborate and share updates directly in the system.',
  },
  MeetingsAndTasks: {
    en: 'Meetings and tasks',
    nb: 'Møter og oppgaver',
  },
  Meetings: {
    en: 'Meetings',
    nb: 'Møter',
  },
  CircularDependency: {
    en: 'This task can not be linked with the selected task',
    nb: 'Denne oppgaven kan ikke bli koblet sammen med den valgte oppgaven',
  },
  IncompleteTasks: {
    en: 'Incomplete tasks',
    nb: 'Uferdige oppgaver',
  },
  CompletedTasks: {
    en: 'Completed tasks',
    nb: 'Fullførte oppgaver',
  },
  MyIncompleteTasks: {
    en: 'My incomplete tasks',
    nb: 'Mine uferdige oppgaver',
  },
  MyCompletedTasks: {
    en: 'My completed tasks',
    nb: 'Mine fullførte oppgaver',
  },
  AllTasks: {
    en: 'All',
    nb: 'Alle',
  },
  SearchTasks: {
    en: 'Search',
    nb: 'Søk',
  },
  ListViewTasks: {
    en: 'List view',
    nb: 'Listevisning',
  },
  DiagramViewTasks: {
    en: 'Chart view',
    nb: 'Diagramvisning',
  },
  StatusTasks: {
    en: 'Status',
    nb: 'Status',
  },
  PriorityTasks: {
    en: 'Priority',
    nb: 'Prioritet',
  },
  TitleTasks: {
    en: 'Title',
    nb: 'Tittel',
  },
  ResponsibleTasks: {
    en: 'Responsible',
    nb: 'Ansvarlig',
  },
  CreateNews: {
    en: 'Create news',
    nb: 'Opprett melding',
  },
  EditNews: {
    en: 'Edit news',
    nb: 'Rediger melding',
  },

  ExtraResponsibleTasks: {
    en: 'Extra responsible',
    nb: 'Andre ansvarlige',
  },
  DeadlineTasks: {
    en: 'Deadline',
    nb: 'Frist',
  },
  LinkCopiedText: {
    en: 'The link is now ready to be pasted and shared with others who have portal access.',
    nb: 'Lenken er nå klar for å limes inn og deles med andre med portaltilgang.',
  },
  TaskLinkTooltip: {
    en: 'Copy the task link to the clipboard.',
    nb: 'Kopier oppgavelenken til utklippstavlen.',
  },
  EditTask: {
    en: 'Edit',
    nb: 'Rediger',
  },
  Delete: {
    en: 'Delete',
    nb: 'Slett',
  },
  LabelTooltip: {
    en: 'Add and create labels to be able to sort and seperate tasks',
    nb: 'Legg til og opprett merkelapper for å kunne sortere og gjenkjenne oppgaver',
  },

  SelectTasks: {
    en: 'Select tasks',
    nb: 'Velg oppgaver',
  },
  SelectTask: {
    en: 'Select task',
    nb: 'Velg oppgave',
  },
  SubTask: {
    en: 'Subtasks',
    nb: 'Underoppgaver',
  },
  SubTaskTooltip: {
    en: 'Add and create subtasks for this task',
    nb: 'Legg til og opprett underoppgaver for denne oppgaven',
  },
  DependentOnThis: {
    en: 'Tasks that are dependent on this',
    nb: 'Oppgaver som er avhengig av denne',
  },

  CreateDependentTask: {
    en: 'Create dependent task',
    nb: 'Opprett avhengig oppgave',
  },
  CreateDependentTaskTooltip: {
    en: 'Create a new task and automatically set it as dependant on this task.',
    nb: 'Opprett en ny oppgave og automatisk gjør den avhengig av denne oppgaven.',
  },
  AddDependentTask: {
    en: 'Add dependent task',
    nb: 'Legg til avhengig oppgave',
  },
  AddDependentTaskTooltip: {
    en: 'Make an existing task dependent on this task.',
    nb: 'Gjør en eksisterende oppgave avhengig av denne oppgaven.',
  },
  CreateSubtask: {
    en: 'Create subtask',
    nb: 'Opprett underoppgave',
  },
  CreateSubtaskTooltip: {
    en: 'Create a new task and automatically set it as a subtask for this task.',
    nb: 'Opprett en ny oppgave og automatisk legg den til som underoppgave på denne oppgaven.',
  },
  AddSubtask: {
    en: 'Add subtask',
    nb: 'Legg til underoppgave',
  },
  AddSubtaskTooltip: {
    en: 'Add an existing task as a subtask for this task.',
    nb: 'Legg til en eksisterende oppgave som underoppgave på denne oppgaven.',
  },
  'Org.nr': {
    en: 'Org.nr',
    nb: 'Org.nr',
  },
  EditUser: {
    en: 'Edit user',
    nb: 'Rediger bruker',
  },
  YouHaveNotBeenInvitedToAnySurvey: {
    en: 'You have not been invited to any surveys',
    nb: 'Du er ikke invitert til noen spørreundersøkelse',
  },
  TransfereUser: {
    en: 'Transfere user',
    nb: 'Overfør bruker',
  },
  DeleteContent: {
    en: 'Delete content',
    nb: 'Slett innhold',
  },
  PleaseConfirmPermanentDelete: {
    en: 'You are about to delete chosen content permanently. this action cannot be undone. Do you wish to delete permanently?',
    nb: 'Du er i ferd med å slette valgte innhold permanent. Dette kan ikke angres. Ønsker du å slette permanent?',
  },

  Menu: {
    en: 'Menu',
    nb: 'Meny',
  },
  rolesText: {
    en: 'Roles',
    nb: 'Roller',
  },
  OrgOrgbrainWatermarkinginfo: {
    en: 'Administrators can set settings to include a watermark on documents downloaded from the portal. This makes it easier to trace documents that have been downloaded to the person who performed the download.',
    nb: 'Det er mulig for administrator å sette innstillingene slik at det blir lagt inn et «vannmerke» («watermark») på dokumenter som lastes ned fra portalen. Dette gjør at dokumenter på avveie lettere kan spores til den som har utført nedlastingen.',
  },
  OrgEditUserInfoBox: {
    en: 'The administrator can assign and revoke access to the portal. The role(s) the users are assigned determines what they have access to. When inviting new users, make sure that the "Send invitation by email" box is checked, ensuring the new user receives the message. If you forget to do this, simply check the "Send invitation by email" box and save, and the recipient will receive an email. In the email sent, the individual will be asked to register their personal user account.',
    nb: 'Her kan administrator tildele og fjerne tilganger til portalen. Rollen(e) brukerne blir tildelt bestemmer hva de får tilgang til. Ved invitasjon av nye brukere, pass på å at feltet “Send invitasjon på e-post” er fylt ut slik at den nye brukeren mottar meldingen. Dersom du glemmer dette, er det bare å krysse av “Send invitasjon på e-post” og så lagre, da vil mottakeren motta e-post. I e-posten som sendes vil vedkommende bli bedt om å registrere sin personlige brukerkonto.',
  },
  ResendSendEmail: {
    en: 'Resend email',
    nb: 'Send E-post på nytt',
  },
  userIdentityRequirementsHelp1: {
    en: 'Manual verification should be conducted before adding a new user, to ensure good user control within the portal. The users themselves must have control and access to the email with which they are invited.',
    nb: 'For å ha god kontroll på brukere som inviteres inn i portalen, bør det gjennomføres manuell kontroll før ny bruker leggs til. Portalen krever minimum at brukeren må ha kontroll på e-postadressen som de inviteres inn med.',
  },
  userIdentityRequirementsHelp2: {
    en: 'If the organization desires increased control, they can require that all users must further confirm their identity using one of the methods outlined, or else the user will be denied access to the organization. It is possible to choose both methods, but the user only needs to confirm their identity once. An administrator can override an identification by verifying the user manually.',
    nb: 'Dersom organisasjonen ønsker økt kontroll, kan det legges inn et krav om at alle brukerne må bekrefte sin identitet ytterligere med en av metodene som er angitt her, ellers blir brukeren nektet tilgang til organisasjonen. Det er mulig å velge begge metodene, men brukeren trenger kun å bekrefte sin identitet én gang. Det er mulig for en administrator å overstyre en identifisering ved å verifisere brukeren manuelt.',
  },
  userIdentityRequirementsHelp3: {
    en: 'Note: The organization will be billed NOK 5 per user who confirms their identity. See the terms of use for more information.',
    nb: 'NB: Organisasjonen faktureres kr 5 pr. bruker som bekrefter identiteten. Se brukervilkårene for ytterligere opplysninger.',
  },
  ImportOrganizationInfo: {
    en: 'Import from Brønnøysund',
    nb: 'Import fra Brønnøysund',
  },
  AskForAssistance: {
    en: 'Expert assistance',
    nb: 'Få eksperthjelp',
  },
  //I would like to be contacted by phone
  IWouldLikeToBeContactedAtPhone: {
    en: 'I would like to be contacted by phone',
    nb: 'Jeg ønsker å bli kontaktet på telefon',
  },
  EraseTooltip: {
    en: 'Erase drawings',
    nb: 'Viske ut tegninger',
  },
  CommentAndDrawTooltip: {
    en: 'Draw with note',
    nb: 'Tegne med notat',
  },
  DrawTooltip: {
    en: 'Draw freely',
    nb: 'Tegne fritt',
  },
  NoteTooltip: {
    en: 'Write a note',
    nb: 'Skriv notat',
  },
  askForAssistanceButton: {
    en: 'Get expert assistance',
    nb: 'Be om bistand',
  },
  CanAlsoContactOnEmail: {
    en: 'You can also reach us by email: support@orgbrain.no',
    nb: 'Du kan også nå oss på e-post: support@orgbrain.no',
  },
  SupportRequestFrom: {
    en: 'Support request from',
    nb: 'Forespørsel fra',
  },
  HasRequestedAssistanceOnPhone: {
    // has requested assistance. Contact the the user on
    en: 'has requested assistance. Contact the the user on phone',
    nb: 'har bedt om konsulentbistand. Kontakt brukeren på telefon',
  },
  GetAssistanceText1: {
    en: 'Are your days busy?',
    nb: 'Har du travle dager?',
  },
  GetAssistanceText2: {
    en: 'Get help with performing your tasks in the portal from a consultant who is an expert on the',
    nb: 'Få hjelp med utføre dine oppgaver i portalen av en konsulent som er ekspert på',
  },
  GetAssistanceText3: {
    nb: 'programvaren.',
    en: 'software.',
  },
  GetAssistanceText4: {
    nb: 'Pris: kr 400 for hvert påbegynt kvarter',
    en: 'Price: kr 400 for each commenced quarter',
  },

  GetAssistanceTooltip: {
    en: 'Are your days busy? Get help with performing your tasks in the portal from a consultant who is an expert on the software.',
    nb: 'Har du travle dager? Få hjelp med utføre dine oppgaver i portalen av en konsulent som er ekspert på programvaren.',
  },
  Parvalue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  NewUser: {
    en: 'New user',
    nb: 'Ny bruker',
  },
  Rights: {
    en: 'Rights',
    nb: 'Rettigheter',
  },
  Access: {
    en: 'Access',
    nb: 'Tilgang',
  },
  hideSigningTextForRoles: {
    nb: 'Utelat følgende tekst fra protokollen: "Følgende skal signere at protokollen er lest:" (OBS: Dette øker potensielt ansvaret for de som signerer.)',
    en: 'Exclude the following text from the minutes: "The following shall sign that the minutes have been read:" (NOTE: This potentially increases the responsibility for those who sign.)',
  },

  CouldNotDelete: {
    nb: 'Kunne ikke slettes',
    en: 'Could not delete',
  },
  ItIsNotPossibleToDeleteAllAdmins: {
    nb: 'Det er ikke mulig å slette alle med administratorrettigheter fra en undergruppe. Dersom du ønsker å slette undergruppen, ta kontakt med Orgbrain på support@orgbrain.no',
    en: 'It is not possible to delete all administrators from a subgroup. If you want to delete the subgroup, contact Orgbrain at support@orgbrain.no',
  },
  NewSubGroupCreated: {
    nb: 'Ny gruppe er opprettet',
    en: 'New subgroup created',
  },
  YouCanFindTheNewSubgroup: {
    nb: 'Den nye gruppen finner du som en underenhet til din organisasjon. Du kan gå til den ved å klikke på nedtrekksmenyen for organisasjoner øverst til høyre i portalen.',
    en: 'The new subgroup is found as a subunit to your organization. You can go to it by clicking on the drop-down menu for organizations at the top right of the portal.',
  },
  ChangesToPremissionsDiffersFromParent: {
    en: 'Changes have been made. There are now <b>deviations</b> in access from the folder above',
    nb: 'Det er foretatt endringer. Det er nå <b>avvik</b> i tilgang fra mappen over',
  },
  AccessControlWarningContainer: {
    en: 'Users marked in red have been given access to this level without having access to the level above. You must also grant access to the folders above for the access to take effect.',
    nb: 'Brukere som er merket rødt, er blitt gitt tilgang til dette nivået uten å ha fått tilgang til nivået over. Du må også gi tilgang på mappene over for at tilgangen skal ha effekt.',
  },
  CreateQuestionnaire: {
    en: 'Create a New Survey',
    nb: 'Opprett spørreskjema',
  },
  ExtraContacts: {
    en: 'Extra contacts: ',
    nb: 'Ekstra kontakter: ',
  },
  ExtraContactsHelpText: {
    en: 'Must be filled inn with yes to signal that the contact is an extra contact for the row above it. It is possible to have more than one extra contact for the same row, by filling in yes for each row above it will connect to the first with nothing.',
    nb: 'Må fylles inn med ja for å signalisere at kontakten er en ekstra kontakt for raden over. Det er mulig å ha flere ekstra kontakter for samme rad, ved å fylle inn ja for hver rad over vil den koble seg til den første med ingenting.',
  },
  DateSent: {
    en: 'Date sent',
    nb: 'Sendt dato',
  },
  Contract: {
    en: 'Contract',
    nb: 'Kontrakt',
  },
  dueDiligenceInfoBox: {
    en: 'Here you have the opportunity to limit which users should have access to the content of folders and files. Access you set here will be reused in all subfolders. You also have the opportunity to limit access further to fewer users in subfolders.',
    nb: 'Her har du mulighet til å avgrense hvilke brukere som skal ha tilgang til innholdet i mapper og filer. Tilganger du setter her vil gjenbrukes i alle underliggende mapper. Du har også mulighet til å avgrense tilgangen ytterligere til færre brukere i underliggende mapper.',
  },
  SendPaymentInformation: {
    en: 'Send payment information',
    nb: 'Send betalingsinformasjon',
  },
  EmissionInviteInvestors: {
    en: 'Invite investors',
    nb: 'Inviter investorer',
  },
  SubmitAndSendEmail: {
    en: 'Submit and send email',
    nb: 'Gjennomfør og send e-post',
  },
  SubmitAndSendEmailTooltipDividend: {
    en: 'Here you can complete the assignment and choose who should receive notification of the assignment by e-mail.',
    nb: 'Her kan du gjennomføre tildelingen og velge hvem som skal motta melding om tildelingen på e-post.',
  },
  SendMessage: {
    en: 'Send message',
    nb: 'Send melding',
  },
  Address: {
    en: 'Address',
    nb: 'Adresse',
  },

  VpsNumber: {
    en: 'VPS number',
    nb: 'VPS-nummer',
  },
  LeiNumber: {
    en: 'LEI number',
    nb: 'LEI-nummer',
  },
  CopyForm: {
    en: 'Copy form',
    nb: 'Kopier skjema',
  },
  dobfield: {
    en: 'Date of Birth (6 digits)',
    nb: 'Fødselsdato (6 siffer)',
  },
  PNRLable: {
    nb: 'P. nr. (5 siffer) / F.nr (11 siffer)',
    en: 'SSN (5 digits) / B.nr (11 digits)',
  },

  helpTextAnnualPlan: {
    en: 'By choosing "Based on previous meetings", a proposal for an annual plan will be made based on the meetings that have been held in the last 12 months. The meetings will be held on the same day of the week as the year before, adjusted for any official holidays. By choosing "Completely new annual plan", you will get the opportunity to set up a completely new annual plan based on your adjustments.',
    nb: 'Ved valg av «Basert på tidligere møter» vil det bli laget forslag til årsplan basert på møtene som har vært de siste 12 måneder. Møtene blir lagt på samme ukedag som året før, justert for eventuelle offisielle fridager. Ved valg av «Helt ny årsplan» vil du få muligheten til å sette opp helt ny årsplan basert på dine tilpasninger.',
  },

  BusinessAdress: {
    nb: 'Forretningsadresse',
    en: 'Business address',
  },
  NewClaim: {
    en: 'New claim',
    nb: 'Ny pant',
  },

  DateEntered: {
    en: 'Date entered',
    nb: 'Dato innført',
  },
  PostalAddress: {
    nb: 'Postadresse',
    en: 'Postal address',
  },
  AdditionalInformation: {
    en: 'Additional information',
    nb: 'Ytterligere opplysninger',
  },
  DescriptionTask: {
    en: 'Description',
    nb: 'Beskrivelse',
  },
  sentbyYou: {
    en: 'You: ',
    nb: 'Deg: ',
  },

  YouHaveNoSubgroups: {
    en: 'You have no subgroups',
    nb: 'Du har ingen undergrupper',
  },
  PleaseAddSubgroupName: {
    nb: 'Opprett ny undergruppe',
    en: 'Create new subgroup',
  },
  CreateAdminUser: {
    nb: 'Legg til første administrator',
    en: 'Create first administrator',
  },
  Meeting: {
    en: 'Meeting',
    nb: 'Møte',
  },
  AssociatedFile: {
    en: 'Associated file',
    nb: 'Tilknyttet fil',
  },
  HidePath: {
    en: 'Hide path',
    nb: 'Skjul filsti',
  },
  ShowPath: {
    en: 'Show path',
    nb: 'Vis filsti',
  },
  SubTasks: {
    en: 'Subtasks',
    nb: 'Underoppgaver',
  },
  ParentTask: {
    en: 'Parent task',
    nb: 'Foreldreoppgave',
  },
  DependsOn: {
    en: 'Depends on',
    nb: 'Avhengig av',
  },
  Comments: {
    en: 'Comments',
    nb: 'Kommentarer',
  },
  StartTask: {
    en: 'Start',
    nb: 'Start',
  },
  ModifiedTasks: {
    en: 'Modified',
    nb: 'Endret',
  },
  ProgressTask: {
    en: 'Progress',
    nb: 'Fremdrift',
  },
  ShowFollowupInCalendarForResponsible: {
    en: 'The user responsible for the task will see the task in the calendar.',
    nb: 'Den som er satt som ansvarlig for oppgaven, vil se oppgaven i sin kalenderen.',
  },
  ShowInCalendarForResponsible: {
    en: 'Show in calendar for responsible',
    nb: 'Vis i kalenderen for ansvarlig',
  },
  ShowFollowupInCalendarForEverybody: {
    en: 'Those who have access to the the task will see the it in the calendar.',
    nb: 'De som har tilgang til oppgaven vil se den i kalenderen.',
  },
  ShowInCalendarForEverybody: {
    en: 'Show in calendar for everyone',
    nb: 'Vis i kalenderen for alle',
  },
  MessagesSearch: {
    en: 'Conversation messages',
    nb: 'Samtalemeldinger',
  },
  startConversationWithUser: {
    en: 'Start a new private conversation with this user.',
    nb: 'Start en ny privat samtale med denne brukeren.',
  },
  SendNotificationEmail: {
    en: 'Send notification by email',
    nb: 'Send varsling på e-post',
  },
  Notify: {
    en: 'Notify',
    nb: 'Varsle',
  },
  SendToResponsibleTooltip: {
    en: 'Those assigned to be responsible for the task will receive an bell notification along with an email whenever a change is saved or a comment is added.',
    nb: 'De som er satt som ansvarlige for oppgaven, vil motta en varsling på e-post og i bjellen hver gang det lagres en endring eller legges til en kommentar.',
  },
  UndoTaskMoveTooltip: {
    en: 'Undo changes on task dates',
    nb: 'Angre på flytting av oppgave',
  },
  ResponsibleTasksTooltip: {
    en: 'Here you can add more people responsible for this task.',
    nb: 'Her kan du legge til flere ansvarlige for denne oppgaven.',
  },
  AnnuallyRepeatingTaskTooltip: {
    en: 'Whether this task should be repeated annually. When the deadline has passed, a copy of the task is created one year in the future. If no deadline is set, the copy is created when the start time has passed.',
    nb: 'Angi om oppgaven skal gjentas årlig. Når fristen er passert, opprettes en kopi av oppgaven ett år frem i tid. Om ingen frist er satt, opprettes kopien når starttidspunktet er passert.',
  },
  Calendar: {
    en: 'Calendar',
    nb: 'Kalender',
  },

  CalendarDates: {
    en: 'Calendar dates',
    nb: 'Kalender datoer',
  },

  ResponsibleTooltip: {
    en: 'Add responsible connected to this task',
    nb: 'Legg til ansvarlige som er knyttet til oppgaven',
  },
  Responsible: {
    en: 'Responsible',
    nb: 'Ansvarlig',
  },
  AddResponsible: {
    en: 'Add responsible',
    nb: 'Legg til ansvarlige',
  },
  DependsOnTootip: {
    en: 'Add a task this one depends on',
    nb: 'Legg til en oppgave som denne er avhengig av',
  },
  MovedTask: {
    en: 'Moved task: ',
    nb: 'Flyttet oppgave: ',
  },
  DependsOnTask: {
    en: 'Dependencies',
    nb: 'Avhengigheter',
  },
  AddDependentTasks: {
    en: 'Add dependency',
    nb: 'Legg til en forutsetning',
  },
  CalendarTooltipTask: {
    en: 'Make changes to calendar settings',
    nb: 'Gjør endringer på kalender innstillinger',
  },
  EmailTooltipTask: {
    en: 'Make changes to email settings',
    nb: 'Gjør endringer på e-post innstillinger',
  },
  OrderedAmount: {
    en: 'Ordered amount',
    nb: 'Bestilt beløp',
  },
  DateOfBirth: {
    en: 'Date of birth',
    nb: 'Fødselsdato',
  },
  OrderedShares: {
    en: 'Ordered shares',
    nb: 'Bestilte aksjer',
  },
  SendToResponsible: {
    en: 'Send to responsible',
    nb: 'Send til ansvarlige',
  },
  SendToMorePeople: {
    en: 'Send to more people',
    nb: 'Send til flere',
  },
  IncludeTheDescriptionTooltip: {
    nb: 'Hvis du ikke huker av her, vil kun tittelen på oppgaven sendes i e-posten, slik at mottakerne må følge lenken til Orgbrain for å se beskrivelsen av oppgaven.',
    en: 'If this box is not checked, only the title of the task will be visible in the email and the recipients must follow a link to Orgbrain to see the description of the task.',
  },
  IncludeDescriptionInEmail: {
    en: 'Include the description in the email',
    nb: 'Inkluder beskrivelsen i e-posten',
  },
  Cancel: {
    en: 'Cancel',
    nb: 'Avbryt',
  },
  Save: {
    en: 'Save',
    nb: 'Lagre',
  },
  TaskPriority: {
    en: 'Task priority',
    nb: 'Oppgaveprioritet',
  },
  TaskStatus: {
    en: 'Task status',
    nb: 'Oppgavestatus',
  },
  TaskProgress: {
    en: 'Task progress',
    nb: 'Oppgavefremdrift',
  },
  AuthToBoard: {
    en: 'Board Authorizations',
    nb: 'Styrefullmakter',
  },
  Title: {
    en: 'Title',
    nb: 'Tittel',
  },
  TitleTooltip: {
    en: 'Give the authorization a title',
    nb: 'Her gir du fullmakten en tittel',
  },

  ThisIsAPrivateTask: {
    en: 'This is a private task',
    nb: 'Dette er en privat oppgave',
  },
  Private: {
    en: 'Private',
    nb: 'Privat',
  },
  Download: {
    en: 'Download',
    nb: 'Last ned',
  },

  nameSort: {
    en: 'Alphabetically',
    nb: 'Alfabetisk',
  },
  ShareRegistryAt: {
    en: 'Show status at ',
    nb: 'Viser status per ',
  },
  RegistryFor: {
    en: 'Share register for ',
    nb: 'Aksjeeierbok for ',
  },
  ExportOption: {
    en: 'What do you wish to do?',
    nb: 'Hva ønsker du å gjøre?',
  },

  SendEmail: {
    en: 'Send email',
    nb: 'Send på e-post',
  },
  Sort: {
    en: 'Sort',
    nb: 'Sorter',
  },
  Percent: {
    en: 'Percent',
    nb: 'Prosent',
  },
  SelectRecipients: {
    en: 'Add recipients',
    nb: 'Velg mottakere',
  },
  AddEmails: {
    en: 'Add external recipients',
    nb: 'Legg til eksterne mottakere',
  },
  NoRecipentsSelected: {
    en: 'No valid emails found in selected.',
    nb: 'Det ble ikke funnet noen gyldige eposter i valgte.',
  },
  EmailsSent: {
    en: 'Email has been sent to recipients.',
    nb: 'E-post har blitt sendt til mottakere.',
  },
  ChooseOrgbrainUsers: {
    en: 'Choose Orgbrain users',
    nb: 'Velg Orgbrain-brukere',
  },
  IncludeCreatedAtTime: {
    en: 'Show creation date in the document',
    nb: 'Vis opprettelsesdato i dokumentet',
  },
  IncludeCompanyLogo: {
    en: 'Show company logo in the document',
    nb: 'Vis selskapets logo i dokumentet',
  },
  IncludeDistributionDiagram: {
    en: 'Show distribution diagram',
    nb: 'Vis fordelingsdiagram',
  },
  DontIncludeSensitiveOption: {
    en: 'Remove sensitive informasjon',
    nb: 'Fjern sensitiv informasjon',
  },
  Filename: {
    en: 'File name',
    nb: 'Filnavn',
  },
  ExportSettings: {
    en: 'Download settings',
    nb: 'Nedlastningsinnstillinger',
  },
  ExportRegistrySubject: {
    en: 'Shareholder register for ',
    nb: 'Aksjeeierboken for ',
  },
  ExportRegistryText1: {
    en: ' has shared their shareholder register with you. The shareholder register has been created and entered in Orgbrain',
    nb: ' har delt sin aksjeeierbok med deg. Aksjeeierboken er opprettet og ført i fagsystemet levert av Orgbrain.',
  },
  ExportRegistryText2: {
    en: 'You will find the shareholder register attached to this email',
    nb: 'Du finner aksjeeierboken som vedlegg i denne e-posten',
  },
  ExportRegistryText3: {
    en: 'Best regards ',
    nb: 'Med vennlig hilsen ',
  },
  ExportRegistryText4: {
    en: '(Shared through Orgbrain - easy and professional shareholder management)',
    nb: '(Delt via Orgbrain - enkel og profesjonell aksjonæradministrasjon)',
  },

  StartTime: {
    en: 'Start time',
    nb: 'Starttid',
  },

  DeletedMessage: {
    en: 'This message has been deleted',
    nb: 'Denne meldingen har blitt slettet',
  },
  ChooseTask: {
    en: 'Choose tasks',
    nb: 'Velg oppgaver',
  },
  Selected: {
    en: 'Selected',
    nb: 'Valgt',
  },
  DeleteTask: {
    en: 'Delete task',
    nb: 'Slett oppgave',
  },
  'Deleting a task cannot be undone. Do you want to delete this task permanently?': {
    en: 'Deleting a task cannot be undone. Do you want to delete this task permanently?',
    nb: 'Sletting av en oppgave kan ikke angres. Ønsker du å slette denne oppgaven permanent?',
  },

  UntitledTask: {
    en: 'Untitled task',
    nb: 'Oppgave uten tittel',
  },
  Undo: {
    en: 'Undo',
    nb: 'Angre',
  },
  UndoTaskMove: {
    en: 'Undo move',
    nb: 'Angre flytting',
  },

  TaskCompleted: {
    en: 'Task completed',
    nb: 'Oppgave fullført',
  },
  UnlinkFile: {
    en: 'Do you want to disconnect this task from a data room file?',
    nb: 'Vil fjerne koblingen mellom denne oppgaven og dataromfilen?',
  },
  UnlinkFileWarningText: {
    en: 'The file in attachments will not be deleted automatically.',
    nb: 'Filen i vedlegg vil ikke bli slettet automatisk.',
  },
  Ok: {
    en: 'Ok',
    nb: 'Ok',
  },
  DisabledTaskTooltip: {
    en: 'You do not have permission to modify this task',
    nb: 'Du har ikke tillatelse til å endre denne oppgaven',
  },
  Critical: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  High: {
    en: 'High',
    nb: 'Høy',
  },
  Medium: {
    en: 'Medium',
    nb: 'Middels',
  },
  Low: {
    en: 'Low',
    nb: 'Lav',
  },
  Maybe: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  None: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  Ongoing: {
    en: 'Ongoing',
    nb: 'Pågår',
  },
  NotDone: {
    en: 'Not done',
    nb: 'Ikke gjort',
  },
  Done: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CreateATask: {
    en: 'Create a task',
    nb: 'Opprett en oppgave',
  },
  Create: {
    en: 'Create',
    nb: 'Opprett',
  },
  SaveAndContinue: {
    en: 'Save and continue',
    nb: 'Lagre og fortsett',
  },
  MakeSubTask: {
    en: 'Parent task',
    nb: 'Foreldreoppgave',
  },
  MakeSubTaskTooltip: {
    en: 'Choose a new parent task to make this task a subtask',
    nb: 'Velg en ny foreldreoppgave for å gjøre denne oppgaven til en underoppgave.',
  },
  RemoveParentTask: {
    en: 'Remove Parent Link',
    nb: 'Fjern Foreldrekobling',
  },
  RemoveParentTaskTooltip: {
    en: 'Remove the link between this task and its parent',
    nb: 'Fjern koblingen mellom denne oppgaven og dens foreldreoppgave.',
  },
  TaskSaved: {
    en: 'Saved task',
    nb: 'Lagret oppgave',
  },
  Back: {
    en: 'Back',
    nb: 'Tilbake',
  },
  MakeTaskDependent: {
    en: 'Add dependency',
    nb: 'Legg til avhengighet',
  },
  MakeTaskDependentTooltip: {
    en: 'Add a task which this task is dependent on',
    nb: 'Legg til en oppgave som denne oppgaven er avhengig av',
  },

  CreatedBy: {
    en: 'Created by',
    nb: 'Opprettet av',
  },
  PrivateTaskTooltip: {
    nb: 'Dersom du markerer en oppgave som privat, vil oppgaven kun være synlig for deg.',
    en: 'By marking the task as private, the task will only be visible to you.',
  },
  Warning: {
    en: 'Warning',
    nb: 'Advarsel',
  },

  CloseTaskEditWarning: {
    nb: 'Du har lagt inn ny informasjon som ikke er lagret. Ønsker du å fortsette uten å lagre eller gå tilbake for å lagre?',
    en: 'You have entered new information that has not been saved. Do you want to continue without saving or go back to save?',
  },
  CloseOwnerWarning: {
    nb: 'Du har lagt inn ny informasjon som ikke er lagret. Ønsker du å fortsette uten å lagre eller gå tilbake for å lagre?',
    en: 'You have entered new information that has not been saved. Do you want to continue without saving or go back to save?',
  },
  Continue: {
    nb: 'Fortsett',
    en: 'Continue',
  },
  GoBack: {
    nb: 'Gå tilbake',
    en: 'Go back',
  },
  TaskNotChanged: {
    nb: 'Oppgaven er ikke endret',
    en: 'The task is not changed',
  },
  NoUndoneTasks: {
    nb: 'Ingen uferdige oppgaver',
    en: 'No undone tasks',
  },
  EmailWillContainTextWarning: {
    en: 'An email will be sent. The e-mail will contain the text you have entered. Be careful about adding sensitive information to the message',
    nb: 'En e-post vil bli sendt. E-posten vil inneholde teksten du har skrevet inn. Vær forsiktig med å legge til sensitiv informasjon i meldingen',
  },
  EmailsWillBeSent: {
    en: 'Emails will be sent',
    nb: 'E-poster vil bli sendt',
  },
  instructionsTobeGivenPowerOfAthorizedInfo: {
    en: 'Here you can choose to enter instructions on how you want the recipient of the power of attorney to vote on one or more of the cases that are under consideration.',
    nb: 'Her kan du velge å legge inn en instruksjon på hvordan du ønsker at mottakeren av fullmakten skal stemme på en eller flere av de sakene som er til behandling.',
  },
  signingPowerOfAthorizedInfo: {
    en: 'If an administrator registers a power of attorney on behalf of others, the administrator signs to confirm that the power of attorney has been received.',
    nb: 'Dersom en administrator registrerer en fullmakt på vegne av andre, signerer administrator for å bekrefte at fullmakt er mottatt.',
  },
  SendNotification: {
    en: 'Send notification',
    nb: 'Send varsel',
  },
  Notification: {
    en: 'Notifications',
    nb: 'Varsler',
  },

  signedBy: {
    en: 'Signed by',
    nb: 'Signert av',
  },
  LongTextInTheBottomProxy: {
    en: 'If the person who has received power of attorney don`t have access to participate digitally using Orgbrain`s portal, the person must either attend by personal appearance or otherwise stated by the company.',
    nb: 'Dersom den som er blitt tildelt fullmakt ikke er gitt tilgang til å delta digitalt via portalen, må vedkommende som er gitt fullmakt delta ved personlig oppmøte eller på annen måte oppgitt av selskapet.',
  },
  AreYouSure: {
    en: 'Are you sure?',
    nb: 'Er du sikker?',
  },
  DeleteTasks: {
    en: 'This will delete all selected tasks',
    nb: 'Dette vil slette alle oppgaver som du har valgt',
  },
  ConfirmSendingEmails: {
    en: 'Send email notification',
    nb: 'Send varsel på e-post',
  },
  TaskAreYouSure: {
    en: 'You are about to send an email notification to the specified individuals that an update has been made to the task.\nAre you sure?',
    nb: 'Du er i ferd med å sende et varsel på e-post til de angitte personene om at det er foretatt en oppdatering på oppgaven.\nEr du sikker?',
  },
  Select: {
    en: 'Select',
    nb: 'Velg',
  },
  deleteTask: {
    en: 'Delete task',
    nb: 'Slett oppgaven',
  },
  openTaskToolbar: {
    en: 'Open and close the toolbar',
    nb: 'Åpne og lukke verktøylinjen',
  },
  Close: {
    en: 'Close',
    nb: 'Lukk',
  },
  SendPaymentInformationToInvestorsTooltip: {
    en: 'Here you will find a draft of a notification about payment that can be sent to the selected investors',
    nb: 'Her vil du finne utkast til en melding om innbetaling som kan sendes til de utvalgte investorene',
  },
  NoTasks: {
    en: 'No tasks yet',
    nb: 'Det finnes ingen oppgaver enda',
  },
  NoCompletedTasks: {
    en: 'There are no completed tasks',
    nb: 'Det finnes ingen fullførte oppgaver',
  },
  NoIncompleteTasks: {
    en: 'There are no incomplete tasks',
    nb: 'Det finnes ingen uferdige oppgaver',
  },
  YouHaveNoTasks: {
    en: 'You have no tasks',
    nb: 'Du har ingen oppgaver',
  },
  YouHaveNoIncompleteTasks: {
    en: 'You have no incomplete tasks',
    nb: 'Du har ingen uferdige oppgaver',
  },
  YouHaveNoCompletedTasks: {
    en: 'You have no completed tasks',
    nb: 'Du har ingen fullførte oppgaver',
  },
  YouHaveNoPrivateTasks: {
    en: 'You have no private tasks',
    nb: 'Du har ingen private oppgaver',
  },
  ThereAreNoTasks: {
    en: 'No tasks yet',
    nb: 'Det finnes ingen oppgaver enda',
  },
  PrivateTasks: {
    en: 'Private tasks',
    nb: 'Private oppgaver',
  },
  NoemissionsYet: {
    en: 'There are no ongoing share issues',
    nb: 'Du har ingen pågående kapitalforhøyelser',
  },
  CopyShareIssueEmission: {
    en: 'Clone previous share issue',
    nb: 'Klone tidligere kapitalforhøyelse',
  },
  NewShareIssueMenu: {
    en: 'Create',
    nb: 'Opprett',
  },
  CopyEmissionTooltip: {
    en: 'Create a new share issue using a previous one as a template.',
    nb: 'Opprett en ny kapitalforhøyelse ved å bruke en tidligere som mal.',
  },
  CreateEmissionTooltip: {
    en: 'Create an entirely new share issue.',
    nb: 'Opprett en helt ny kapitalforhøyelse.',
  },
  NoEmissionsFound: {
    en: 'No previous or ongoing share issues were found.',
    nb: 'Ingen tidligere eller pågående kapitalforhøyelser ble funnet.',
  },
  SelectShareIssueEmission: {
    en: 'Select previous share issue',
    nb: 'Velg tidligere kapitalforhøyelse',
  },
  ShareIssueDeletionSuccess: {
    en: 'The share issue is now deleted',
    nb: 'Kapitalforhøyelsen er nå slettet',
  },
  ShareIssueDeletionFailed: {
    en: 'Error: deletion failed',
    nb: 'Error: sletting feilet',
  },

  NoInvestorsInvited: {
    en: 'No investors invited',
    nb: 'Ingen investorer er invitert',
  },
  ShareRegisterInfo4: {
    en: 'looks like right now that provide a detailed history of the current membership situation.',
    nb: 'ser ut som akkurat nå som gir en detaljert historikk over den aktuelle medlemssituasjonen.',
  },
  NewContract: {
    en: 'New contract',
    nb: 'Ny kontrakt',
  },
  ContractOwner: {
    en: 'Contract owner',
    nb: 'Kontraktsinnehaver',
  },
  History: {
    en: 'History',
    nb: 'Historikk',
  },
  TaxIncentive: {
    en: 'Tax Incentive',
    nb: 'Skatteinsentiv',
  },
  ImportContracts: {
    en: 'Import contracts',
    nb: 'Importer kontrakter',
  },
  ExportContracts: {
    en: 'Export contracts',
    nb: 'Eksporter kontrakter',
  },
  andContractInformation: {
    en: 'and contract information',
    nb: 'og kontraktsinformasjon',
  },

  Proportion: {
    en: 'Proportion',
    nb: 'Andel',
  },
  ContractTitle: {
    en: 'Contract title',
    nb: 'Avtaletittel',
  },
  NameOfShareclass: {
    en: 'Name of share class',
    nb: 'Navn på aksjeklasse',
  },
  ImportContractTitleText: {
    en: 'Is the title of the contract, and is used to identify the contract. This is a required field and is used to identify exisitng contracts in the registry.',
    nb: 'Er tittelen på kontrakten, og brukes til å identifisere kontrakten. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret.',
  },
  contractedAmount: {
    en: 'Contracted amount',
    nb: 'Kontraktbeløp',
  },
  ImportContractedAmountText: {
    en: 'Is the contracted amount of the contract, and is used as the amount field in contracts. This is a required field and is used to sett the amount in a contract.',
    nb: 'Er kontraktsbeløpet i kontrakten, og brukes som beløpsfelt i kontrakter. Dette er et obligatorisk felt og brukes til å sette beløpet i en kontrakt.',
  },
  contractType: {
    en: 'Contract type',
    nb: 'Kontraktstype',
  },
  ImportContractTypeText: {
    en: 'Is the type of the contract, and is used to identify the contract type. This is an required field and is used to identify exisitng contracts in the registry and sett the contract type.',
    nb: 'Er kontraktstypen, og brukes til å identifisere kontraktstypen. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret og sette kontraktstypen.',
  },
  contractStatus: {
    en: 'Contract status',
    nb: 'Kontrakt status',
  },
  ImportContractStatusText: {
    en: 'Is the status of the contract, and is used to identify the contract status. This is an optional field and is used to sett contract status.',
    nb: 'Er kontrakten status, og brukes til å identifisere kontrakten status. Dette er et valgfritt felt og brukes til å sette kontrakten status.',
  },
  contractExpiryDate: {
    en: 'Contract expiry date',
    nb: 'Kontrakt utløpsdato',
  },
  ImportContractExpiryDateText: {
    en: 'Is the expiry date of the contract, and is used to identify the contract expiry date. This is an optional field and is used to sett contract expiry date.',
    nb: 'Er kontraktens utløpsdato, og brukes til å identifisere kontrakten utløpsdato. Dette er et valgfritt felt og brukes til å sette kontrakten utløpsdato.',
  },
  strikePrice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  StrikePriceTable: {
    en: 'Strike price',
    nb: 'Kurs',
  },
  ReadMessageMenu: {
    en: 'Seen by',
    nb: 'Sett av',
  },
  ChatSeenBy: {
    en: 'Seen by: ',
    nb: 'Sett av: ',
  },
  CurrentUserText: {
    en: 'You',
    nb: 'Deg',
  },
  ImportStrikePriceText: {
    en: 'Is the strike price of the contract, and is used to identify the contract strike price. This is a required field and is used to sett contract strike price.',
    nb: 'Er innløsningskursen på kontrakten, og brukes til å identifisere kontrakten innløsningskurs. Dette er et obligatorisk felt og brukes til å sette kontrakten innløsningskurs.',
  },
  totalLoan: {
    en: 'Total loan',
    nb: 'Lånebeløp',
  },
  ImportTotalLoanText: {
    en: 'Is the total loan of the contract, and is used to identify the contract total loan in the loan contract type. This is a optional field and is used in loans to sett contract total loan amount.',
    nb: 'Er lånebeløpet på kontrakten, og brukes til å identifisere kontrakten lånebeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes i lån til å sette kontrakten lånebeløp.',
  },
  effectiveInterestRate: {
    en: 'Effective interest rate',
    nb: 'Effektiv lånerente',
  },
  ImportEffectiveInterestRateText: {
    en: 'Is the effective interest rate of the contract, and is used to identify the contract effective interest rate in the loan contract type. This is a optional field and is only used in loans to sett contract interest rate.',
    nb: 'Er den effektive lånerenten på kontrakten, og brukes til å identifisere kontrakten effektive lånerente i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten effektive lånerente.',
  },
  convertibleShareOfLoan: {
    en: 'Convertible share of loan',
    nb: 'Konverteringsbeløp',
  },
  ImportConvertibleShareOfLoanText: {
    en: 'Is the convertible share of loan of the contract, and is used to identify the contract convertible share of loan in the loan contract type. This is a optional field and is only used in loans to sett contract convertible share of loan.',
    nb: 'Er konverteringsbeløpet på kontrakten, og brukes til å identifisere kontrakten konverteringsbeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten konverteringsbeløp.',
  },
  contractTerms: {
    en: 'Contract terms',
    nb: 'Betingelser',
  },
  ImportContractTermsText: {
    en: 'Is the contract terms of the contract, and is used to identify the contract terms in the loan contract type. This is a optional field and is only used in loans to sett contract terms.',
    nb: 'Er betingelsene på kontrakten, og brukes til å identifisere kontrakten betingelser i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betingelser.',
  },
  contractPaymentStructure: {
    en: 'Contract payment structure',
    nb: 'Lånets betalingsstruktur',
  },
  ImportContractPaymentStructureText: {
    en: 'Is the contract payment structure of the contract, and is used to identify the contract payment structure in the loan contract type. This is a optional field and is only used in loans to sett contract payment structure.',
    nb: 'Er betalingsstrukturen på kontrakten, og brukes til å identifisere kontrakten betalingsstruktur i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betalingsstruktur.',
  },
  InTheImport: {
    en: 'in the import',
    nb: 'i importen',
  },
  Strikeprice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  ContractInvestor: {
    en: 'Investor',
    nb: 'Investor',
  },
  ActiveMeeting: {
    en: 'Active meeting',
    nb: 'Aktivt møte',
  },

  NoShareClasses: {
    en: 'No share classes',
    nb: 'Ingen aksjeklasser',
  },
  CreateShareClassToSolvetheproblem: {
    en: 'You need to create a shareclass or import to use this functionality',
    nb: 'Du må opprette en aksjeklasse eller importere for å bruke denne funksjonaliteten',
  },
  CreateShareclass: {
    en: 'Create share class',
    nb: 'Opprett aksjeklasse',
  },
  MoreOptions: {
    en: 'More options',
    nb: 'Flere valg',
  },
  NotificationViewTooltip: {
    en: 'Use this button to switch between showing notifications sorted by time or by organization',
    nb: 'Bruk denne knappen for å veksle mellom å vise varsler sortert etter tid eller etter organisasjon',
  },
  FilterNotifications: {
    en: 'Filter your notifications by their type',
    nb: 'Filtrer varsler dine etter typene deres',
  },
  Filters: {
    en: 'Filters',
    nb: 'Filtere',
  },
  PublishMessageTooltip: {
    en: 'Make the message publicly available',
    nb: 'Gjør meldingen offentlig tilgjenlig',
  },
  RestoreMessageTooltip: {
    en: 'The message was not saved correctly, press to restore an unsaved draft',
    nb: 'Meldingen var ikke lagret riktig, trykk for å gjenopprette et ulagret utkast',
  },
  Restore: {
    en: 'Restore',
    nb: 'Gjenopprett',
  },
  Publish: {
    en: 'Publish',
    nb: 'Publiser',
  },
  outOf: {
    en: 'out of',
    nb: 'av',
  },
  PreviousEmails: {
    en: 'previous email',
    nb: 'forrige e-post',
  },
  FirstEmail: {
    en: 'First email',
    nb: 'Første e-post',
  },

  LastEmail: {
    en: 'Last email',
    nb: 'Siste e-post',
  },
  TurnOffFilter: {
    en: 'Turn off the filter',
    nb: 'Skru av filter',
  },
  ShowLess: {
    en: 'Show less',
    nb: 'Vis mindre',
  },
  parvalueTooltip: {
    nb: 'Legg inn pålydende beløp per aksje som angitt i vedtektene',
    en: 'Enter the par value per share as stated in the articles of association',
  },
  NoRegisteredShareclass: {
    en: 'There are no registered',
    nb: 'Det er ingen registrerte',
  },
  ReadMoreOnEmissions: {
    en: 'Read more about share issues in orgbrain',
    nb: 'Les mer om kapitalforhøyelser i orgbrain',
  },
  CreateEmission: {
    en: 'Create share issue',
    nb: 'Opprett kapitalforhøyelse',
  },
  sharePrice: {
    nb: 'Aksjekurs',
    en: 'Share price',
  },

  NewCloneShareIssue: {
    en: 'New/clone share issue',
    nb: 'Ny/klone kapitalforhøyelse',
  },
  PointZeroNewShareIssueInfo: {
    en: 'Go to "Create" to initiate a brand new capital increase, or to create a new one by cloning a previous capital increase as a template.',
    nb: 'Gå til "Opprett" for å lage en helt ny kapitalforhøyelse, eller for å opprette en ny ved å klone en tidligere kapitalforhøyelse som mal.',
  },
  PointTwoNewShareIssueInfo: {
    en: 'Make an order form based on a template and add potentially relevant attachments to investors',
    nb: 'Lag en bestillingsblankett basert på mal og legg til eventuelle relevante vedlegg til investorene.',
  },
  CanAddShareclass: {
    en: 'You can add',
    nb: 'Du kan legge til',
  },
  AttachFileTask: {
    en: 'Attachments',
    nb: 'Vedlegg',
  },
  NotesBeneficialOwners: {
    en: 'Notes and attachments',
    nb: 'Notater og vedlegg',
  },

  NotSentToAltinnBeneficialOwners1: {
    en: 'All businesses have a legal responsibility to store additional information about beneficial owners. Here you can easily store extra documentation that will not be sent to altinn.',
    nb: 'Myndighetene kan etterspørre dokumentasjon på vurderingene som er gjort i forbindelse med rapporteringen av reelle rettighetshavere. Det er derfor viktig at virksomheten kan dokumentere grunnlaget for identifiseringen, slik at den kan bistå myndighetene ved forespørsel om ytterligere informasjon.',
  },
  NotSentToAltinnBeneficialOwners2: {
    en: 'In this section, you can easily store documentation. This information is not part of the automatic reporting to Altinn.',
    nb: 'I denne seksjonen kan du enkelt lagre dokumentasjon. Denne informasjonen er ikke en del av den automatiske rapporteringen til Altinn.',
  },
  NoBeneficialOwnersWithEmailTitle: {
    en: 'No beneficial owners with email',
    nb: 'Ingen reelle rettighetshavere med e-post',
  },
  NoBeneficialOwnersWithEmail: {
    en: 'You have not added a email address to any beneficial owners. To notify beneficial owners, add an email address.',
    nb: 'Du har ikke lagt til e-postadresse til noen reelle rettighetshavere. For å varsle reelle rettighetshavere, legg til en e-postadresse.',
  },
  AttachFile: {
    en: 'Attachments',
    nb: 'Vedlegg',
  },
  AttachFileTaskTooltip: {
    en: 'Add attachments to the task',
    nb: 'Legg til vedlegg på oppgaven',
  },
  AttachFileMessageTooltip: {
    en: 'Add attachments to the message',
    nb: 'Legg til vedlegg på meldingen',
  },
  Upload: {
    en: 'Upload',
    nb: 'Last opp',
  },
  Dataroom: {
    en: 'Dataroom',
    nb: 'Datarom',
  },

  UploadHelpShareclass: {
    en: 'by uploading data in the',
    nb: 'ved å laste opp data i',
  },
  OrCreateShareclass: {
    en: 'Alternatively, you can create a',
    nb: 'Alternativt kan du opprette en',
  },
  ManuallyShareclass: {
    en: 'manually by pressing "New',
    nb: 'manuelt ved å trykke "Ny',
  },
  WhereManuallyShareClass: {
    en: 'at the top right corner of this page',
    nb: 'øverst i høyre hjørne på denne siden',
  },
  ThisActionCannotBeUndoneDialog: {
    nb: 'Denne handlingen kan ikke angres.',
    en: 'This action cannot be undone.',
    sv: 'Denna åtgärd kan inte ångras.',
  },
  shareClasses: {
    en: 'share classes',
    nb: 'aksjeklasser',
  },
  shareClass: {
    en: 'share class',
    nb: 'aksjeklasse',
  },
  shareRegistry: {
    en: 'share registry',
    nb: 'aksjeregister',
  },
  shareCategories: {
    en: 'share categories',
    nb: 'aksjekategorier',
  },
  shareCategory: {
    en: 'share category',
    nb: 'aksjekategori',
  },
  noBeneficialOwnersIdentified: {
    nb: 'Virksomheten må dokumentere hva som er gjort for å identifisere dem, jf. lov om register over reelle rettighetshavere § 4 tredje ledd. Dokumentasjonen med begrunnet vurdering, skal være tilgjengelig for offentlige myndigheter og rapporteringspliktige.',
    en: 'The company must document what has been done to identify them, cf. the Act on the register of beneficial owners § 4 third paragraph. The documentation with reasoned assessment must be available to public authorities and reporting entities.',
  },
  noBeneficialOwnersIdentifiedPointPublic: {
    nb: 'Offentlige myndigheter, se ',
    en: 'Public authorities, see ',
  },
  noBeneficialOwnersIdentifiedPointPrivate: {
    nb: 'Rapporteringspliktige, se ',
    en: 'Reporting entities, see ',
  },
  noBeneficialOwnersIdentifiedPointPublicLink: {
    nb: 'forskrift til lov om reelle rettighetshavere § 3-11.',
    en: 'regulation to the Act on beneficial owners § 3-11.',
  },
  noBeneficialOwnersIdentifiedPointPrivateLink: {
    nb: 'lov om tiltak mot hvitvasking og terrorfinansiering § 4.',
    en: 'Act on measures against money laundering and terrorist financing § 4.',
  },
  NoneIdentifiedBeneficialOwners: {
    nb: 'Du har valgt at virksomheten har reelle rettighetshavere som den ikke kan identifisere',
    en: 'You have choosen that the business has beneficial owners which it can not identify',
  },
  unitRegister: {
    en: 'unit register',
    nb: 'enhetsregister',
  },
  GenerateSharenumbers: {
    en: 'Generate share numbers',
    nb: 'Generer aksjenummer',
  },
  sectionCategories: {
    en: 'section categories',
    nb: 'seksjonskategorier',
  },
  sectionCategory: {
    en: 'section category',
    nb: 'seksjonskategori',
  },
  sectionOwnerRegister: {
    en: 'section owner register',
    nb: 'seksjonseierregister',
  },
  memberCategories: {
    en: 'member categories',
    nb: 'medlemskategorier',
  },
  memberCategory: {
    en: 'member category',
    nb: 'medlemskategori',
  },
  membershipRegister: {
    en: 'membership register',
    nb: 'medlemsregister',
  },
  Action: {
    en: 'Action',
    nb: 'Handling',
  },
  SendInvitation: {
    en: 'Send invitation',
    nb: 'Send invitasjon',
  },
  SendInvitationEmissionHelpText: {
    en: 'Invite the investor to sign an order form where they can fill out the investment amount.',
    nb: 'Inviter investoren til å signere en tegningsblankett hvor de kan fylle ut investeringsbeløpet.',
  },
  PrefillShares: {
    en: 'Prefill shares',
    nb: 'Forhåndsutfyll aksjer',
  },
  PrefillSharesHelpText: {
    en: 'Register the investor as ordering a specific investment amount (useful if a paper order form has been received)',
    nb: 'Registrer investoren som bestiller et spesifikt investeringsbeløp (nyttig hvis en papirordre er mottatt)',
  },
  AddInvestorHelpText: {
    en: 'Here you can add, prefill shares, or invite a single investor manually. If the investor does not exist in the system from before, you can create a new investor by clicking "New investor".',
    nb: 'Her kan du legge til, fylle ut aksjer for, eller invitere en enkelt investor manuelt. Eksisterer ikke investoren i systemet fra før, kan du lage en ny investor ved å trykke på "Ny investor".',
  },
  Invitations: {
    en: 'Invitations',
    nb: 'Invitasjoner',
  },
  Add: {
    en: 'Add',
    nb: 'Legg til',
  },
  Modified: {
    en: 'Modified',
    nb: 'Endret',
  },
  NewAuthorization: {
    en: 'New authorization',
    nb: 'Ny fullmakt',
  },
  AuthorizationsModuleInfo: {
    en: 'The board can register authorizations granted by the general meeting to the board. Using the "New Authorization" button, you can either add authorizations manually or import all authorizations registered in the Brønnøysund Registers.',
    nb: 'Her kan styret registrere fullmakter som generalforsamlingen har gitt styret. Via knappen "Ny fullmakt" kan du enten legge til fullmakter manuelt eller importere alle fullmakter som er registrert i Brønnøysundregistrene.',
  },
  Summary: {
    en: 'Summary',
    nb: 'Oversikt',
  },
  OrderedInRelationToMinimum: {
    en: 'Ordered in relation to the minimum requirement of',
    nb: 'Bestilt i forhold til minstekravet på',
  },
  shares: {
    en: 'shares',
    nb: 'aksjer',
  },
  ContractOwnerAlt: {
    en: 'Owner',
    nb: 'Innehaver',
  },
  Active: {
    en: 'Active',
    nb: 'Aktive',
  },
  AuthorizationImportError: {
    en: 'Error while importing authorizations',
    nb: 'Feil ved import av fullmakter',
  },
  AuthorizationImportSuccess: {
    en: 'Authorizations imported',
    nb: 'Fullmakter importert',
  },
  DeleteAuthorization: {
    en: 'Delete authorization',
    nb: 'Slett fullmakt',
  },
  DeleteAuthorizationPrompt: {
    en: 'Are you sure you want to delete this authorization?',
    nb: 'Er du sikker på at du vil slette denne fullmakten?',
  },
  InvestorExistsErrorEmission: {
    en: 'This investor already exists, you might want to uncheck new investor and search for them instead',
    nb: 'Denne investoren finnes allerede, du vil kanskje fjerne haken for ny investor og heller søke etter dem',
  },
  SimilarNameEmissionWarning: {
    en: 'There is an investor with a similar name in the registry. If this is the investor you want to add, uncheck new investor and search for this person',
    nb: 'Det finnes en investor med lignende navn i registeret. Hvis dette er investoren du vil legge til fjern hukingen fra ny investor og søk etter denne personen',
  },
  All: {
    en: 'All',
    nb: 'Alle',
  },
  Liquidation: {
    en: 'Liquidation',
    nb: 'Likvidasjon',
  },

  Equal_partial_liquidation: {
    en: 'Equal partial liquidation',
    nb: 'Likvidasjon lik fordeling',
  },
  Unequal_partial_liquidation: {
    en: 'Unequal partial liquidation',
    nb: 'Likvidasjon ulik fordeling',
  },
  Taxable_merger: {
    en: 'Taxable merger',
    nb: 'Skattepliktig fusjon',
  },
  Taxable_demerger: {
    en: 'Taxable demerger',
    nb: 'Skattepliktig fisjon',
  },
  Cancellation_of_treasury_shares_to_cover_losses_and_allocations_to_funds: {
    en: 'Cancellation of treasury shares to cover losses and allocations to funds',
    nb: 'Sletting av egne aksjer for å dekke tap og overføringer til fond',
  },
  Taxable_redemption_in_connection_with_TaxFree_merger_Or_demerger: {
    en: 'Taxable redemption in connection with TaxFree merger Or demerger',
    nb: 'Skattepliktig innløsning i forbindelse med skattefri fusjon eller fisjon',
  },
  Relocation_of_company_to_and_from_Norway: {
    en: 'Relocation of company to and from Norway',
    nb: 'Flytting av selskap til og fra Norge',
  },
  OnlyVisibleToBoard: {
    en: 'To board only',
    nb: 'Til styret',
  },
  discountType: {
    en: 'Rabatttype',
    nb: 'Discount type',
  },
  YourContract: {
    en: 'Your contract',
    nb: 'Din kontrakt',
  },
  Entity: {
    en: 'Entity',
    nb: 'Entitet',
  },
  Maturity: {
    en: 'Maturity',
    nb: 'Forfall',
  },
  Status: {
    en: 'Status',
    nb: 'Status',
  },
  Strike: {
    en: 'Strike',
    nb: 'Strike',
  },
  LastSharePrice: {
    en: 'Last share price',
    nb: 'Siste aksjekurs',
  },
  EstimatedValueOfPosition: {
    en: 'Estimated position value',
    nb: 'Estimert verdi på posisjon',
  },
  YourPosition: {
    en: 'Your position',
    nb: 'Din posisjon',
  },
  StrikeRelativeToSharePrice: {
    en: 'Strike relative to share price',
    nb: 'Strike i forhold til aksjekurs',
  },
  NumberOfOwners: {
    en: 'Number of owners',
    nb: 'Antall eiere',
  },
  TotalNumberOfShares: {
    en: 'Total number of shares',
    nb: 'Totalt antall aksjer',
  },
  EstimatedValueOfCompany: {
    en: 'Estimated company value',
    nb: 'Estimert verdi på selskap',
  },
  Company: {
    en: 'Company',
    nb: 'Selskap',
  },
  YouHaveNoPositions: {
    en: 'You have no positions',
    nb: 'Du har ingen posisjoner',
  },
  YouHaveNoContracts: {
    en: 'You have no agreements',
    nb: 'Du har ingen avtaler',
  },
  Percentage: {
    en: 'Percentage',
    nb: 'Prosent',
  },
  OrderForms: {
    en: 'Order forms',
    nb: 'Bestillingsblanketter',
  },
  InvestorPortfolioInfo: {
    en: 'See your investments, financial agreements, and order forms for ongoing capital increases.',
    nb: 'Her kan du se dine investeringer, finansielle kontrakter og bestillingsblanketter for pågående kapitalforhøyelser.',
  },
  Organization: {
    en: 'Organization',
    nb: 'Organisasjon',
  },
  RemainingAmountContract: {
    en: 'Remaining',
    nb: 'Gjenværende',
  },
  RemainingTooltip: {
    en: 'Here you enter the number of shares that is remaining in the agreement',
    nb: 'Her skriver du inn antall aksjer som er igjen i avtalen',
  },
  //BOARDMATCH
  CouldNotGenerateCompanyvalue: {
    en: 'Could not generate company value',
    nb: 'Kunne ikke generere selskapsverdi',
  },
  Error: {
    en: 'Error',
    nb: 'Feil',
  },
  Boardmatch: {
    en: 'Boardmatch',
    nb: 'Styrematch',
  },
  BoardmatchCandidate: {
    en: 'Boardmatch Me',
    nb: 'Styrematch meg',
  },
  BoardmatchOrg: {
    en: 'Boardmatch Org',
    nb: 'Styrematch org',
  },
  AboutBoardmatch: {
    en: 'About',
    nb: 'Om Styrematch',
  },

  RedirectHeader: {
    en: 'Redirect',
    nb: 'Omdirigering',
  },
  RedirectMessage: {
    en: 'Are you sure you want to redirect to the website below:',
    nb: 'Er du sikker på at du vil omdirigeres til nettstedet nedenfor:',
  },
  MenuCandidate: {
    en: 'Candidate',
    nb: 'Deg som kandidat',
  },
  MenuOrg: {
    en: 'Organization',
    nb: 'Din organisasjon',
  },
  City: {
    en: 'City',
    nb: 'Poststed',
  },
  PublicAdvertTooltip: {
    en: 'By publishing your advertisement, you will gain access to and be matched with relevant candidates',
    nb: 'Ved å publisere annonsen deres, vil dere få tilgang til og bli matchet med relevante kandidater',
  },
  CandidatePublishProfileTooltip: {
    en: 'By publishing your profile, you will gain access to and be matched with relevant positions',
    nb: 'Ved å publisere profilen din, vil du få tilgang til og bli matchet med relevante stillinger',
  },
  CandidateAnonymousProfileTooltip: {
    en: 'By making your profile anonymous, your name or your LinkedIn profile will not be visible when someone views your profile',
    nb: 'Ved å gjøre profilen anonym, vil ikke navnet ditt eller LinkedIn-profilen din være synlig når noen ser på din profil',
  },
  CandidateProfileInfoBox: {
    en: 'This is where you put in information about your personal profile. It is up to you how much information you wish to include, but we recommend that you put in as much as possible to make it easier to find a good match. By making your profile anonymous, your name or your LinkedIn profile will not be visible when someone views your profile.',
    nb: 'Her legger du inn opplysninger om din personlige profil. Det er opp til deg hvor mye informasjon du ønsker å inkludere, men vi anbefaler at du legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en passende match. Ved å gjøre profilen anonym, vil ikke navnet ditt eller LinkedIn-profilen din være synlig når noen ser på din profil.',
  },
  OrgProfileInfoBox: {
    en: 'This is where you register information about your business and what type of candidate you seek. It is up to the business how much information they give, but we recommend that you put in as much as possible to make it easier to find a good match. If you are seeking candidates for multiple roles, you have to create a new advertisement for every role you are searching for.',
    nb: 'Her registrerer dere opplysninger om virksomheten og hvilken type kandidat dere søker. Det er opp til virksomheten å velge hvor mye informasjon man legger inn, men vi anbefaler at dere legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en god match. Hvis dere er ute etter kandidater til ulike verv, må det opprettes en ny annonse for hvert verv dere søker etter.',
  },

  BoardmatchSubheader: {
    en: 'We match your profile with the right company',
    nb: 'Vi matcher din profil med den rette virksomheten',
  },
  BoardmatchSubheaderOrg: {
    en: 'We match your advertisement with the right candidate',
    nb: 'Vi matcher din annonse med den rette kandidaten',
  },
  BoardmatchDescription: {
    en: 'Boardmatch is a feature that connects candidates seeking positions for boards, committees, advisory boards, and other corporate roles with companies looking for such positions.',
    nb: 'Styrematch er en funksjon som kobler kandidater som er ute etter verv i styrer, komiteer, advisory board og andre selskapsverv, med virksomheter som er ute etter slike verv.',
  },
  BoardmatchDescription2: {
    en: 'When you create a profile, you gain access to advertisements from companies that match the criteria you have specified. Similarly, companies will be able to see your profile if there is a match.',
    nb: 'Når du oppretter en profil i løsningen, får du tilgang til annonser fra virksomheter som matcher de kriteriene du har spesifisert. På samme måte vil virksomheter kunne se din profil hvis det er en match.',
  },
  BoardmatchDescription3: {
    en: 'The company will then have the opportunity to send a message expressing their interest in initiating a dialogue.',
    nb: 'Virksomheten vil da ha mulighet til å sende en melding om at de er interessert i å innlede en dialog.',
  },
  BoardmatchDescription4: {
    en: 'You can choose whether your name should be visible to others or if you prefer to be anonymous. Only the companies that have published an advertisement and that match your profile will have access to view it.',
    nb: 'Du kan velge om ditt navn skal være synlig for andre eller om du ønsker å være anonym. Kun de virksomhetene som har publisert en annonse og som matcher med profilen din, vil ha tilgang til å se den.',
  },
  BoardmatchDescriptionOrg: {
    en: 'Boardmatch is a feature that connects businesses looking for candidates for board positions, committees, advisory boards and other corporate roles, with candidates seeking such roles.',
    nb: 'Styrematch er en funksjon som kobler virksomheter som er ute etter kandidater til styret, komiteer, advisory board og andre selskapsverv, med kandidater som er ute etter slike verv.',
  },
  BoardmatchDescription2Org: {
    en: 'When you publish an advertisement through «Boardmatch Org», your organization will gain access to a selection of registered candidates who meet the criteria you have defined. Similarily, candidates who create a profile will be presented with advertisements that match their profile.',
    nb: 'Når du publiserer en stillingsannonse gjennom «Styrematch org», vil virksomheten din få tilgang til et utvalg av registrerte kandidater som møter de kriteriene dere har definert. På tilsvarende vis vil kandidater som oppretter en profil bli presentert for annonser som matcher deres profil.',
  },
  BoardmatchDescription3Org: {
    en: 'This allows candidates with a match to send a message to the organization to express their interest in initiating a dialogue.',
    nb: 'Dette gir kandidatene som har en match mulighet til å sende en melding til virksomheten for å uttrykke sin interesse i å innlede en dialog.',
  },
  BoardmatchDescription4Org: {
    en: 'Candidates can choose whether they want to have a profile where their name is visible, or if they prefer to remain anonymous. Your organization can also use the system to build and maintain a network of potential candidates.',
    nb: 'Kandidater kan velge om de ønsker å ha en profil der navnet er synlig for andre, eller om de foretrekker å være anonym. Din virksomhet kan også benytte systemet til å bygge og vedlikeholde et nettverk med potensielle kandidater.',
  },
  HowItWorks: {
    en: 'How it works',
    nb: 'Slik fungerer det',
  },
  HowItWorksDesc: {
    en: 'The candidate creates a profile and the company creates an advertisement. Our algorithms find matches, and dialogue can be initiated.',
    nb: 'Kandidaten oppretter profil og virksomheten lager en annonse. Våre algoritmer finner match, og dialog kan innledes.',
  },
  RegisterAsCandidate: {
    en: 'Register as candidate',
    nb: 'Register deg som kandidat',
  },
  RegisterOrganization: {
    en: 'Register organization',
    nb: 'Registrer organisasjon',
  },
  GoToCandidateProfile: {
    en: 'Go to candidate profile',
    nb: 'Gå til din kandidatprofil',
  },
  GoToOrgPage: {
    en: 'Go to advertisements',
    nb: 'Gå til deres stillingsannonser',
  },
  AddCandidateProfile: {
    en: 'Create profile',
    nb: 'Opprett profil',
  },
  NoCandidateProfile: {
    en: 'You have not created a profile yet.',
    nb: 'Du har ikke opprettet en profil ennå.',
  },
  Profile: {
    en: 'Profile',
    nb: 'Profil',
  },
  Published: {
    en: '(Published)',
    nb: '(Publisert)',
  },
  PublishedTooltip: {
    en: 'Your profile is published and visible to businesses.',
    nb: 'Profilen er publisert og synlig for virksomheter.',
  },
  PublishedAdTooltip: {
    en: 'Your avdertisement is published and visible to candidates.',
    nb: 'Annonsen er publisert og synlig for kandidater.',
  },
  NotPublished: {
    en: '(Not published)',
    nb: '(Ikke publisert)',
  },
  NotPublishedTooltip: {
    en: 'To publish your profile, check "Public profile" when editing.',
    nb: 'For å publisere profilen din, huk av "Publisert profil" når du redigerer.',
  },
  NotPublishedAdTooltip: {
    en: 'To publish your advertisement, check "Publish advertisement" when editing.',
    nb: 'For å publisere annonsen, huk av "Publiser annonse" når du redigerer.',
  },
  PublishAdToSeeMatch: {
    en: 'Publish an advertisement to find candidates.',
    nb: 'Publiser en annonse for å finne kandidater.',
  },
  PublishProfileToSeeMatch: {
    en: 'Publish your profile to find a match.',
    nb: 'Publiser din profil for å finne en match.',
  },
  RerouteToConnectedProfile: {
    en: 'Go to their profile',
    nb: 'Gå til deres profil',
  },
  ConnectedProfileTooltip: {
    en: 'Go to their profile',
    nb: 'Gå til deres profil',
  },
  Anonymous: {
    en: 'Anonymous',
    nb: 'Anonym',
  },
  AnonymousProfile: {
    en: 'Make profile anonymous',
    nb: 'Gjør profil anonym',
  },
  OrganizationName: {
    en: 'Organizaiton name',
    nb: 'Organisasjonens navn',
  },
  Name: {
    en: 'Name',
    nb: 'Navn',
  },
  DeleteProfile: {
    en: 'Delete profile',
    nb: 'Slett profil',
  },
  DeleteFromNetwork: {
    en: 'Delete from network',
    nb: 'Slett fra nettverk',
  },
  EditInfo: {
    en: 'Edit info',
    nb: 'Rediger info',
  },
  Edit: {
    en: 'Edit',
    nb: 'Rediger',
  },
  AboutMe: {
    en: 'About me',
    nb: 'Om meg',
  },
  BasicInfo: {
    en: 'Basic info',
    nb: 'Basisinformasjon',
  },
  YearFounded: {
    en: 'Year founded',
    nb: 'År stiftet',
  },
  Birthdate: {
    en: 'Birthdate',
    nb: 'Fødselsdato',
  },
  Age: {
    en: 'Age',
    nb: 'Alder',
  },
  Residence: {
    en: 'Residence',
    nb: 'Bosted',
  },
  CountryOfResidence: {
    en: 'Country of residence',
    nb: 'Bostedsland',
  },
  Candidates: {
    en: 'Candidates',
    nb: 'Kandidater',
  },
  Nationality: {
    en: 'Nationality',
    nb: 'Nasjonalitet',
  },
  SelectMultiple: {
    en: 'Select multiple',
    nb: 'Velg flere',
  },

  DatePickerShareholders: {
    en: 'Historic view',
    nb: 'Historisk visning',
  },
  SharesAmount: {
    en: 'Amount of shares',
    nb: 'Antall Aksjer',
  },
  SelectMultipleTasksTooltip: {
    en: 'Select and delete multiple tasks at once',
    nb: 'Velg og slett flere oppgaver på engang',
  },
  SelectMultipleEventsTooltip: {
    en: 'Select and delete multiple events at once',
    nb: 'Velg og slett flere hendelser på engang',
  },
  SubTaskLinkOf: {
    en: 'Remove link to subtask',
    nb: 'Fjern kobling til underoppgave',
  },
  DependencyLinkOff: {
    en: 'Remove link to dependent task',
    nb: 'Fjern kobling til avhengig oppgave',
  },
  NewInquiry: {
    en: 'New Inquiry',
    nb: 'Ny henvendelse',
  },
  NoInquiries: {
    en: 'You have not yet recieved inquiries from any businesses.',
    nb: 'Du har ikke mottatt noen henvendelser fra virksomheter ennå.',
  },
  NoInquiriesOrg: {
    en: 'No candidates have sent you inquiries yet.',
    nb: 'Ingen kandidater har sendt dere noen henvendelser ennå.',
  },
  AS: {
    en: 'Joint-Stock Company',
    nb: 'Aksjeselskap',
  },
  ENK: {
    en: 'Sole Proprietorship',
    nb: 'Enkeltpersonforetak',
  },
  ES: {
    en: 'Condominium Assosiaction',
    nb: 'Eierseksjonssameie',
  },
  ANS: {
    en: 'General Partnership',
    nb: 'Ansvarlig selskap',
  },
  DA: {
    en: 'Partnership with Shared Liability',
    nb: 'Delt ansvar selskap',
  },
  NUF: {
    en: 'Norwegian Branch of Foreign Company',
    nb: 'Norsk avdeling av utenlandsk foretak',
  },
  SA: {
    en: 'Cooperative',
    nb: 'Samvirkeforetak',
  },
  IKS: {
    en: 'Intermunicipal Company',
    nb: 'Interkommunalt selskap',
  },
  IKSR: {
    en: 'Intermunicipal Company Representative',
    nb: 'Interkommunalt selskap Representant',
  },
  KS: {
    en: 'Limited Partnership',
    nb: 'Kommandittselskap',
  },
  BA: {
    en: 'Housing Cooperative',
    nb: 'Borettslag',
  },
  SP: {
    en: 'Limited Liability Company',
    nb: 'Selskap med begrenset ansvar',
  },
  STI: {
    en: 'Foundation',
    nb: 'Stiftelse',
  },
  FLI: {
    en: 'Association/Organization',
    nb: 'Forening/lag/innretning',
  },
  VOF: {
    en: 'Charitable or Nonprofit Organization',
    nb: 'Veldedig eller allmennyttig foretak',
  },
  GFS: {
    en: 'Mutual Insurance Company',
    nb: 'Gjensidig forsikringsselskap',
  },
  HF: {
    en: 'Health Enterprise',
    nb: 'Helseforetak',
  },
  SF: {
    en: 'State Enterprise',
    nb: 'Statsforetak',
  },
  KF: {
    en: 'Municipal Enterprise',
    nb: 'Kommunalt foretak',
  },
  FKF: {
    en: 'County Municipal Enterprise',
    nb: 'Fylkeskommunalt foretak',
  },
  Message: {
    en: 'Message',
    nb: 'Melding',
  },
  WantedRole: {
    en: 'Wanted role',
    nb: 'Ønsket type verv',
  },
  WantedRoleQuestion: {
    en: 'What type of role do you wish to fill?',
    nb: 'Hvilken type rolle ønsker dere å fylle?',
  },
  WhichWantedRole: {
    en: 'What type of position do you want?',
    nb: 'Hvilken type verv ønsker du deg?',
  },
  NetworkWhichWantedRole: {
    en: 'What type of position would be relevant for the candidate?',
    nb: 'Hvilken type verv kan være relevant for kandidaten?',
  },
  WantedGender: {
    en: 'Wanted gender',
    nb: 'Krav til kjønn',
  },
  WantedAge: {
    en: 'Preferred age interval',
    nb: 'Ønsket aldersintervall',
  },
  WantedEducation: {
    en: 'Preferred education level/experience',
    nb: 'Preferert utdanningsnivå/erfaring',
  },
  PersonalProfile: {
    en: 'My page',
    nb: 'Min side',
  },
  Role: {
    en: 'Role',
    nb: 'Rolle',
  },
  'role.key': {
    en: 'Role Key',
    nb: 'Rolle Nøkkel',
  },
  Business: {
    en: 'Business',
    nb: 'Bransje',
  },
  WorkLanguage: {
    en: 'Work language',
    nb: 'Språk i stillingen',
  },
  Website: {
    en: 'Website',
    nb: 'Hjemmeside',
  },
  ExpandedHistorySpan: {
    en: ' expanded access to message history - ',
    nb: ' utvidet innsyn i meldingshistorikken - ',
    sv: '',
  },
  LimitedHistorySpan: {
    en: ' limited access to message history - ',
    nb: ' begrenset innsyn i meldingshistorikken - ',
    sv: '',
  },
  ViewMessagesAfter: {
    en: ' sees messages from ',
    nb: ' ser meldinger fra ',
    sv: '',
  },
  ViewAllMessages: {
    en: ' sees all messages',
    nb: ' ser alle meldinger',
    sv: '',
  },
  ViewOnlyMessages: {
    en: ' sees only messages from ',
    nb: ' ser kun meldinger fra ',
    sv: '',
  },
  TitleOfAnounce: {
    en: 'Title of the advertisement',
    nb: 'Tittel på annonsen',
  },
  'profile.location?': {
    en: 'Profile Location?',
    nb: 'Profilsted?',
  },
  ShowInterest: {
    en: 'Show interest',
    nb: 'Meld interesse',
  },
  ShowInterestDescCandidate: {
    en: 'The candidate will be notified that you would like to make contact. You can write a message to the candidate in the field below.',
    nb: 'Kandidaten vil bli informert om at dere er interesserte i å komme i kontakt. Du kan skrive en melding til kandidaten i feltet under.',
  },
  ShowInterestDescOrg: {
    en: 'The organization will be notified that you are interested in this position. You can write a message to the organization in the field below.',
    nb: 'Selskapet vil bli informert om at du er interessert i stillingen. Du kan legge ved en melding til selskapet i feltet nedenfor.',
  },
  ShowProfile: {
    en: 'Show Profile',
    nb: 'Vis profil',
  },
  Inquirys: {
    en: 'Inquiries',
    nb: 'Henvendelser',
  },
  Network: {
    en: 'Network',
    nb: 'Nettverk',
  },
  AddNewCandidate: {
    en: 'Add Candidate',
    nb: 'Legg til kandidat',
  },
  AddNewCandidateManual: {
    en: 'Add manually',
    nb: 'Legg til manuelt',
  },
  NoCandidatesInNetwork: {
    en: 'No candidates have been added to your network',
    nb: 'Ingen kandidater er lagt til i ditt nettverk',
  },
  AddToCurrentProfile: {
    en: 'Add to current advertisement',
    nb: 'Legg til i valgt annonse',
  },
  FirstName: {
    en: 'First name',
    nb: 'Fornavn',
  },
  LastName: {
    en: 'Last name',
    nb: 'Etternavn',
  },
  ContactInfo: {
    en: 'Contact info',
    nb: 'Kontaktinfo',
  },
  Email: {
    en: 'E-mail',
    nb: 'E-post',
  },
  PhoneNumber: {
    en: 'Phone number',
    nb: 'Telefonnummer',
  },
  InquiryDate: {
    en: 'Inquiry Date',
    nb: 'Forespørselsdato',
  },
  Contacted: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  LastChanged: {
    en: 'Last changed',
    nb: 'Sist endret',
  },
  CreateNewProfile: {
    en: 'Register profile',
    nb: 'Registrer profil',
  },
  NoProfile: {
    en: 'No profiles have been created.',
    nb: 'Dere har ikke opprettet en profil ennå.',
  },
  NoAdvert: {
    en: 'No advertisements have been created.',
    nb: 'Dere har ikke opprettet en annonse ennå.',
  },
  PublicProfile: {
    en: 'Publish profile',
    nb: 'Publiser profil',
  },
  PublicAdvert: {
    en: 'Publish advertisement',
    nb: 'Publiser annonse',
  },
  'IN DIALOG': {
    en: 'In dialog',
    nb: 'I dialog',
  },
  CONTACTED: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  'NOT CONTACTED': {
    en: 'Not contacted',
    nb: 'Ikke kontaktet',
  },
  ERROR: {
    en: 'ERROR',
    nb: 'FEIL',
  },
  Messages: {
    en: 'Messages',
    nb: 'Meldinger',
  },
  AddNewMessage: {
    en: 'Write a message',
    nb: 'Skriv en melding',
  },
  NewMessage: {
    en: 'Write message...',
    nb: 'Skriv melding...',
  },
  EditMessage: {
    en: 'Edit message',
    nb: 'Rediger melding',
  },
  EditAd: {
    en: 'Edit advertisement',
    nb: 'Rediger annonse',
  },
  EditProfile: {
    en: 'Edit profile',
    nb: 'Rediger profil',
  },
  DeleteInquiry: {
    en: 'Delete inquiry',
    nb: 'Slett henvendelse',
  },
  DeleteMessage: {
    en: 'Delete message',
    nb: 'Slett melding',
  },
  none: {
    en: 'None',
    nb: 'Ingen',
  },
  CompanyOnStockMarket: {
    en: 'Choose if the company is registered on a stock exchange',
    nb: 'Velg om selskapet er registrert på et regulert marked (børs)',
  },
  IsRegisteredOnAnotherForeignRegister: {
    en: 'Select if the company is registered on a another register for beneficial owners',
    nb: 'Velg om selskapet er registrert i et utenlandsk register over reelle rettighetshavere',
  },

  'markedstype.euronextOslo': {
    en: 'Euronext Oslo',
    nb: 'Euronext Oslo',
  },
  'markedstype.euronextExpandOslo': {
    en: 'Euronext Expand Oslo',
    nb: 'Euronext Expand Oslo',
  },
  EditedMessage: {
    en: '(edited)',
    nb: '(redigert)',
  },
  InquiryNotes: {
    en: 'Notes',
    nb: 'Notater',
  },
  DescLimit: {
    en: '(Max 750 characters)',
    nb: '(Maks 750 tegn)',
  },
  descPlaceholder: {
    en: 'Description...',
    nb: 'Beskrivelse...',
  },
  CandidateProfile: {
    en: 'Candidate Profile',
    nb: 'Kandidatprofil',
  },
  MyProfile: {
    en: 'My profile',
    nb: 'Min profil',
  },
  NetworkCandidateProfile: {
    en: 'Candidate Profile (Network)',
    nb: 'Kandidatprofil (Nettverk)',
  },
  CandidateDescription: {
    en: 'A short description of you',
    nb: 'En kort beskrivelse av deg',
  },
  CandidateDescriptionNetwork: {
    en: 'A short description of the candidate',
    nb: 'En kort beskrivelse av kandidaten',
  },
  Gender: {
    en: 'Gender',
    nb: 'Kjønn',
  },
  FEMALE: {
    en: 'Female',
    nb: 'Kvinne',
  },
  MALE: {
    en: 'Male',
    nb: 'Mann',
  },
  ANY: {
    en: 'Any gender',
    nb: 'Ikke spesifisert',
  },
  OTHERGENDER: {
    en: 'Other',
    nb: 'Annet',
  },
  CONVERSATIONAL: {
    en: 'Conversational',
    nb: 'Grunnleggende',
  },
  FLUENT: {
    en: 'Fluent',
    nb: 'Flytende',
  },
  LinkedIn: {
    en: 'LinkedIn',
    nb: 'LinkedIn',
  },
  Language: {
    en: 'Language',
    nb: 'Språk',
  },
  LanguageHeader: {
    en: 'Which languages do you speak?',
    nb: 'Hvilke språk behersker du?',
  },
  LanguageHeaderNetwork: {
    en: 'Which languages does the candidate speak?',
    nb: 'Hvilke språk behersker kandidaten?',
  },
  Proficiency: {
    en: 'Proficiency',
    nb: 'Ferdighetsnivå',
  },
  'Add Language': {
    en: 'Add Language',
    nb: 'Legg til språk',
  },
  Education: {
    en: 'Education',
    nb: 'Utdanning',
  },
  EducationHeader: {
    en: 'What educations do you have?',
    nb: 'Hvilke utdanninger har du?',
  },
  AddEducation: {
    en: 'Add education',
    nb: 'Legg til utdanning',
  },
  AddIndustryExperience: {
    en: 'Add industry experience',
    nb: 'Legg til bransjeerfaring',
  },
  'educ.key': {
    en: 'Education Key',
    nb: 'Utdanningsnøkkel',
  },
  AndOther: {
    en: 'and other(s)',
    nb: 'og andre',
  },
  OrgbrainBoardSchool: {
    en: 'Orgbrain/Board School',
    nb: 'Orgbrain/Styreskolen',
  },
  NetworkCompletedOrgbrainBoardSchool: {
    en: 'Is the candidate certified through Orgbrain/Board School?',
    nb: 'Er kandidaten sertifisert gjennom Orgbrain/Styreskolen?',
  },
  NetworkCompletedOtherBoardCourses: {
    en: 'Has the candidate completed other board courses?',
    nb: 'Har kandidaten fullført andre styrekurs?',
  },
  CompletedOrgbrainBoardSchool: {
    en: 'Are you certified through Orgbrain/Board School?',
    nb: 'Er du sertifisert gjennom Orgbrain/Styreskolen?',
  },
  CompletedBoardCourses: {
    en: 'Have you completed any board courses?',
    nb: 'Har du fullført styrekurs?',
  },
  CompletedOtherBoardCourses: {
    en: 'Have you completed other board courses?',
    nb: 'Har du fullført andre styrekurs?',
  },
  CourseType: {
    en: 'Course Type',
    nb: 'Kurstype',
  },
  AddCourse: {
    en: 'Add Course',
    nb: 'Legg til kurs',
  },
  NoCourses: {
    en: 'No Courses',
    nb: 'Ingen kurs',
  },
  AdTitleTooltip: {
    en: 'Give the advertisement a title, for example what you are looking for (for example «We seek a chairman to SaaS company in growth»)',
    nb: 'Her gir du annonsen en tittel, for eksempel hva dere søker (for eksempel «Vi søker styreleder til SaaS selskap i vekst»).',
  },
  AdAboutTooltip: {
    en: 'Here you can give a short description of your business and explain why this position is interesting to apply for.',
    nb: 'Her kan du gi en kort beskrivelse av virksomheten din og forklare hvorfor stillingen er interessant å søke på.',
  },
  Traits: {
    en: 'Personal traits',
    nb: 'Personlige egenskaper',
  },
  TraitsQuestion: {
    en: 'Which personal traits are most important to you?',
    nb: 'Hvilke personlige egenskaper er viktigst for dere?',
  },
  TraitsSubheader: {
    en: 'Pick those that are most applicable to you.',
    nb: 'Velg de du mener passer best for deg.',
  },
  NetworkTraitsSubheader: {
    en: 'Pick those that are most applicable to the candidate.',
    nb: 'Velg de du mener passer best for kandidaten.',
  },
  'trait.key': {
    en: 'Trait Key',
    nb: 'Egenskapsnøkkel',
  },
  Industry: {
    en: 'Industry',
    nb: 'Bransje',
  },
  BusinessType: {
    en: 'Business type',
    nb: 'Type virksomhet',
  },
  BusinessLocation: {
    en: 'Location',
    nb: 'Lokalisering',
  },
  IndustryExperience: {
    en: 'Industry experience',
    nb: 'Bransjeerfaring',
  },
  IndustryExperienceHeader: {
    en: 'Enter relevant industry experience',
    nb: 'Oppgi relevant bransjeerfaring',
  },
  'experience.key': {
    en: 'Experience Key',
    nb: 'Erfaringsnøkkel',
  },
  Competancy: {
    en: 'Areas of expertise',
    nb: 'Kompetanseområder',
  },
  CompetancyHeader: {
    en: 'Enter your competancy',
    nb: 'Oppgi dine kompetanseområder',
  },
  CompetancyQuestion: {
    en: 'What competency are you seeking?',
    nb: 'Hvilken kompetanse er dere ute etter?',
  },
  'skill.key': {
    en: 'Skill Key',
    nb: 'Ferdighetsnøkkel',
  },
  Experience: {
    en: 'Relevant experience',
    nb: 'Relevant erfaring',
  },
  RelevantExperienceHeader: {
    en: 'Enter relevant experience',
    nb: 'Oppgi relevante verv og roller',
  },
  OrgType: {
    en: 'Org. type',
    nb: 'Org.type',
  },
  FromDate: {
    en: 'From date',
    nb: 'Fra dato',
  },
  ToDate: {
    en: 'To date',
    nb: 'Til dato',
  },
  From: {
    en: 'From',
    nb: 'Fra',
  },
  To: {
    en: 'To',
    nb: 'Til',
  },
  from: {
    en: 'from',
    nb: 'fra',
  },
  to: {
    en: 'to',
    nb: 'til',
  },
  FromYear: {
    en: 'From year',
    nb: 'Fra år',
  },
  ToYear: {
    en: 'To year',
    nb: 'Til år',
  },
  VOCATIONAL: {
    en: 'Vocational',
    nb: 'Yrkesfaglig',
  },
  BACHLOR: {
    en: 'Bachelor',
    nb: 'Bachelor',
  },
  MASTER: {
    en: 'Master',
    nb: 'Master',
  },
  EXPERIENCE: {
    en: 'Experience',
    nb: 'Bransjeerfaring',
  },
  ALL: {
    en: 'All',
    nb: 'Alle',
  },
  YOUNG: {
    en: 'Under 35 years',
    nb: 'Under 35 år',
  },
  MIDDEL: {
    en: '35 to 60 years',
    nb: '35 til 60 år',
  },
  OLD: {
    en: 'Over 60 years',
    nb: 'Over 60 år',
  },
  Country: {
    en: 'Country',
    nb: 'Land',
  },
  AddExperience: {
    en: 'Add Experience',
    nb: 'Legg til erfaring',
  },
  InternationalExperience: {
    en: 'International experience',
    nb: 'Internasjonal erfaring',
  },
  InternationalExperienceQ: {
    en: 'Required international experience',
    nb: 'krav om internasjonal erfaring',
  },
  InternationalExperienceImportance: {
    en: 'Is international experience important?',
    nb: 'Er det viktig med internasjonal erfaring?',
  },
  InternationalExperienceYears: {
    en: 'International experience years',
    nb: 'Internasjonale erfaringsår',
  },
  InternationalExperienceQuestion: {
    en: 'Do you have international experience?',
    nb: 'Har du internasjonal erfaring?',
  },
  WantedRemuneration: {
    en: 'Wanted remuneration',
    nb: 'Ønsket honorar',
  },
  BoardmatchHeadline: {
    en: 'New inquiry on Boardmatch',
    nb: 'Ny henvendelse på Styrematch',
  },

  BoardmatchHeaderToOrg: {
    en: 'Boardmatch - new candidate has shown interest',
    nb: 'Styrematch - ny kandidat har vist interesse',
  },
  BoardmatchHeaderToCandidate: {
    en: 'Boardmatch - someone has shown interest in your profile',
    nb: 'Styrematch - noen har vist interesse for din profil',
  },
  GoToAdvertisment: {
    en: 'Log in',
    nb: 'Logg inn',
  },
  BoardmatchEmailToOrg1: {
    en: 'A new candidate has shown interest in your advertisement on Boardmatch and wishes to start a dialogue.',
    nb: 'En ny kandidat har vist interesse for annonsen din på Styrematch og ønsker å starte en dialog.',
  },
  BoardmatchEmailToOrg2: {
    en: 'Log in to Orgbrain to see who it is.',
    nb: 'Logg inn på Orgbrain for å se hvem det gjelder.',
  },
  BoardmatchEmailToCandidate1: {
    en: 'A business has shown interest in your profile on Boardmatch and wishes to start a dialogue.',
    nb: 'En virksomhet har vist interesse for profilen din på Styrematch og ønsker å starte en dialog. ',
  },
  BoardmatchEmailToCandidate2: {
    en: 'Log in to Orgbrain to see who it is.',
    nb: 'Logg inn på Orgbrain for å se hvem det gjelder.',
  },
  NoneMunicipality: {
    en: 'Not given ',
    nb: 'Ikke angitt ',
  },
  AtSchool: {
    en: 'from ',
    nb: 'fra ',
  },
  In: {
    en: 'in ',
    nb: 'i ',
  },
  School: {
    en: 'School',
    nb: 'Skole',
  },
  MinusSharesFound: {
    en: 'Investor with negative shares found',
    nb: 'Investor med negative antall funnet',
  },
  MinusSharesInRegisteryDescription: {
    en: 'The number of shares in the registry is negative for one or more of the investors in the registry. This can cause problems if not solved before the import is completed.',
    nb: 'Antall aksjer i registeret er negative for en eller flere av investorene i registeret. Dette kan føre til problemer om ikke løst før importeringen gjennobmføres.',
  },
  ExpectedRemunerationQuestion: {
    en: 'Are you willing to work for free in certain cases?',
    nb: 'Er du villig til å jobbe frivillig i noen sammenhenger?',
  },
  ExpectedRemunerationAmount: {
    en: 'Expectations regarding remuneration',
    nb: 'Forventninger om honorar',
  },
  ExpectedRemuneration: {
    en: 'Expectation of remuneration',
    nb: 'Forventning til honorar',
  },
  NOREM: {
    en: 'No Remuneration',
    nb: 'Ingen honorar',
  },
  remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  RemunerationOrg: {
    en: 'Remuneration',
    nb: 'Honorering',
  },
  OrgProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  OurPage: {
    en: 'Our page',
    nb: 'Vår side',
  },
  CreateNewAd: {
    en: 'Create new advertisement',
    nb: 'Opprett stillingsannonse',
  },
  DeleteAd: {
    en: 'Delete advertisement',
    nb: 'Slett annonse',
  },
  AllAds: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  Ads: {
    en: 'Advertisements',
    nb: 'Annonser',
  },
  Advert: {
    en: 'Job advertisement',
    nb: 'Stillingsannonse',
  },
  Ad: {
    en: 'Advertisement',
    nb: 'Annonse',
  },
  FindCandidate: {
    en: 'Find candidates',
    nb: 'Finn kandidater',
  },
  FindBoard: {
    en: 'Find positions',
    nb: 'Finn verv',
  },
  Personal: {
    en: 'Personal',
    nb: 'Personlig',
  },
  OrgHomeInfoBox: {
    en: 'Here you can create advertisements to find candidates sith a profile in Orgbrain. When an advertisement is created, you get an overview of candidates that fit to your ad. You can also create and maintain an overview of candidates in your network.',
    nb: 'Her kan du lage annonser for å finne kandidater som har en profil i Orgbrain. Når en annonse er opprettet, får du en oversikt over kandidater som passer til annonsen din. Du kan også lage og holde ved like en oversikt over kandidater i ditt nettverk.',
  },
  OrganizationProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  DescHeader: {
    en: 'About the business',
    nb: 'Om virksomheten',
  },
  Turnover: {
    en: 'Turnover',
    nb: 'Omsetning',
  },
  NumEmployees: {
    en: 'Number of Employees',
    nb: 'Antall Ansatte',
  },
  OrgNationality: {
    en: 'Organization Nationality',
    nb: 'Organisasjonsnasjonalitet',
  },
  OrgNationalityQuestion: {
    en: 'Is the organization',
    nb: 'Er organisasjonen',
  },
  Norwegian: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  Nordic: {
    en: 'Nordic',
    nb: 'Nordisk',
  },
  International: {
    en: 'International',
    nb: 'Internasjonal',
  },
  LocationImportance: {
    en: 'Location Importance',
    nb: 'Stedsbetydning',
  },
  LocationImportanceQuestion: {
    en: 'Is it important that the candidate lives geographically nearby?',
    nb: 'Er det viktig at kandidaten bor geografisk nærme?',
  },
  WantedTitle: {
    en: 'Preferred education/work title',
    nb: 'Preferert utdanning/yrkestittel',
  },
  WorkTitle: {
    en: 'Work title',
    nb: 'Yrkestittel',
  },
  OrganizationType: {
    en: 'Organization Type',
    nb: 'Organisasjonstype',
  },
  BoardCourse: {
    en: 'Completed Board Course',
    nb: 'Fullført styrekurs',
  },
  WantedMeetings: {
    en: 'Expected amount of meetings in a year',
    nb: 'Forventet antall møter i året',
  },
  PhysicalMeets: {
    en: 'Physical meetings',
    nb: 'Fysiske møter',
  },
  DigitalMeets: {
    en: 'Digital meetings',
    nb: 'Digitale møter',
  },
  Remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  'rem.key': {
    en: 'Remuneration Key',
    nb: 'Lønnsnøkkel',
  },
  NoneValidCandidateProfile: {
    en: 'None Valid Candidate Profile',
    nb: 'Ingen gyldig kandidatprofil',
  },
  AddToContactList: {
    en: 'Add to network',
    nb: 'Legg til i nettverk',
  },
  AlreadySentInquiry: {
    en: 'Inquiry sent',
    nb: 'Henvendelse er sendt',
  },
  AlreadyAddedToNetwork: {
    en: 'In your network',
    nb: 'Ligger i ditt nettverk',
  },
  Duration: {
    en: 'Duration',
    nb: 'Varighet',
  },
  SendInquiry: {
    en: 'Send inquiry',
    nb: 'Send henvendelse',
  },
  ANALYTICAL: {
    en: 'Analytical',
    nb: 'Analytisk',
  },
  STRATEGIC: {
    en: 'Strategic',
    nb: 'Strategisk',
  },
  TEAM_PLAYER: {
    en: 'Team Player',
    nb: 'Lagspiller',
  },
  RELATIONSHIP_BUILDER: {
    en: 'Relationship Builder',
    nb: 'Relasjonsbygger',
  },
  SOLUTION_ORIENTED: {
    en: 'Solution Oriented',
    nb: 'Løsningsorientert',
  },
  CURIOUS: {
    en: 'Curious',
    nb: 'Nysgjerrig',
  },
  PROACTIVE: {
    en: 'Proactive',
    nb: 'Initiativrik',
  },
  INFLUENCER: {
    en: 'Influencer',
    nb: 'Påvirker',
  },
  RELIABLE: {
    en: 'Reliable',
    nb: 'Pålitelig',
  },
  EXECUTION_POWER: {
    en: 'Execution Power',
    nb: 'Gjennomføringskraft',
  },
  FOCUSED: {
    en: 'Focused',
    nb: 'Fokusert',
  },
  SALES: {
    en: 'Sales and Sales management',
    nb: 'Salg og salgsledelse',
  },
  MARKETING: {
    en: 'Marketing',
    nb: 'Markedsføring',
  },
  STRATEGY: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ECONOMICS: {
    en: 'Economics',
    nb: 'Økonomi',
  },
  LEADERSHIP: {
    en: 'Leadership',
    nb: 'Ledelse',
  },
  COMMUNICATION: {
    en: 'Communication',
    nb: 'Kommunikasjon',
  },
  LABOR_ORGANIZATIONS: {
    en: 'Labor and Employer Organizations',
    nb: 'Arbeidstaker- og arbeidsgiverorganisasjoner',
  },
  ARCHITECTURE_AND_INTERIOR: {
    en: 'Architecture, Area and Interior',
    nb: 'Arkitektur, areal og interiør',
  },
  ARCHIVE_AND_LIBRARY: {
    en: 'Archive and Library',
    nb: 'Arkiv og bibliotek',
  },
  BANKING_AND_INSURANCE: {
    en: 'Banking, Finance and Insurance',
    nb: 'Bank, finans og forsikring',
  },
  EDUCATION: {
    en: 'Children, School and Education',
    nb: 'Barn, skole og undervisning',
  },
  AUTOMOTIVE: {
    en: 'Automotive, Vehicles and Workshop',
    nb: 'Bil, kjøretøy og verksted',
  },
  AUTO_PRODUCTION: {
    en: 'Automobile and Component Production',
    nb: 'Bil- og bildelproduksjon',
  },
  RETAIL: {
    en: 'Retail and Commerce',
    nb: 'Butikk og varehandel',
  },
  CONSTRUCTION: {
    en: 'Construction and Building',
    nb: 'Bygg og anlegg',
  },
  MAINTENANCE_SERVICES: {
    en: 'Maintenance Services',
    nb: 'Drift og vedlikeholdstjenester',
  },
  ANIMAL_HEALTH: {
    en: 'Animals and Animal Health',
    nb: 'Dyr og dyrehelse',
  },
  REAL_ESTATE: {
    en: 'Real Estate',
    nb: 'Eiendom',
  },
  ELECTRONICS: {
    en: 'Electronics',
    nb: 'Elektronikk',
  },
  EVENTS_AND_ARRANGEMENTS: {
    en: 'Events and Arrangements',
    nb: 'Event og arrangementer',
  },
  PHARMACY_AND_MEDICINE: {
    en: 'Pharmacy and Medicine',
    nb: 'Farmasi og legemiddel',
  },
  FISHERIES_AND_AQUACULTURE: {
    en: 'Fisheries and Aquaculture',
    nb: 'Fiskeri og oppdrett',
  },
  PUBLISHING_AND_PRINTING: {
    en: 'Publishing and Printing',
    nb: 'Forlag og trykkeri',
  },
  RESEARCH_AND_SCIENCE: {
    en: 'Research and Science',
    nb: 'Forskning, utdanning og vitenskap',
  },
  DEFENSE_AND_MILITARY: {
    en: 'Defense and Military',
    nb: 'Forsvar og militær',
  },
  WHOLESALE_TRADE: {
    en: 'Wholesale Trade',
    nb: 'Grossisthandel import/eksport',
  },
  HR_AND_RECRUITMENT: {
    en: 'HR and Recruitment',
    nb: 'HR, organisasjonsutvikling og rekruttering',
  },
  HEALTHCARE: {
    en: 'Healthcare',
    nb: 'Helse og omsorg',
  },
  HOTEL_AND_ACCOMMODATION: {
    en: 'Hotel and Accommodation',
    nb: 'Hotell og overnatting',
  },
  CRAFT_SERVICES: {
    en: 'Craft Services',
    nb: 'Håndverkstjenester',
  },
  IT: {
    en: 'IT',
    nb: 'IT',
  },
  IT_HARDWARE: {
    en: 'IT Hardware',
    nb: 'IT-maskinvare',
  },
  IT_SOFTWARE: {
    en: 'IT Software',
    nb: 'IT-programvare',
  },
  NON_PROFIT_ORGANIZATIONS: {
    en: 'Non-Profit Organizations',
    nb: 'Ideelle organisasjoner',
  },
  SPORTS_AND_FITNESS: {
    en: 'Sports and Fitness',
    nb: 'Idrett og trening',
  },
  INDUSTRIAL_PRODUCTION: {
    en: 'Industrial Production',
    nb: 'Industri og produksjon',
  },
  INTERNET_SERVICES: {
    en: 'Internet Services',
    nb: 'Internettbaserte tjenester',
  },
  AGRICULTURE_AND_FORESTRY: {
    en: 'Agriculture and Forestry',
    nb: 'Jordbruk og skogbruk',
  },
  LEGAL_SERVICES: {
    en: 'Legal Services',
    nb: 'Juridiske tjenester',
  },
  CHURCH_AND_MINISTRY: {
    en: 'Church and Ministry',
    nb: 'Kirke og menighet',
  },
  CHEMICAL_INDUSTRY: {
    en: 'Chemical Industry',
    nb: 'Kjemisk industri',
  },
  CONSULTING: {
    en: 'Consulting',
    nb: 'Konsulent og rådgivning',
  },
  POWER_AND_ENERGY: {
    en: 'Power and Energy',
    nb: 'Kraft og energi',
  },
  ARTS_AND_CULTURE: {
    en: 'Arts and Culture',
    nb: 'Kunst og kultur',
  },
  AVIATION: {
    en: 'Aviation',
    nb: 'Luftfart',
  },
  MARITIME_AND_OFFSHORE: {
    en: 'Maritime and Offshore',
    nb: 'Maritim og offshore',
  },
  MARKET_RESEARCH: {
    en: 'Market Research',
    nb: 'Markedsanalyse',
  },
  MARKETING_AND_ADVERTISING: {
    en: 'Marketing and Advertising',
    nb: 'Markedsføring og annonsering',
  },
  FOOD_PRODUCTION: {
    en: 'Food Production',
    nb: 'Matvareproduksjon og -industri',
  },
  MEDIA_AND_CONTENT_PRODUCTION: {
    en: 'Media and Content Production',
    nb: 'Medie- og innholdsproduksjon',
  },
  MEDICAL_EQUIPMENT: {
    en: 'Medical Equipment',
    nb: 'Medisinsk utstyr og rekvisita',
  },
  METALS_AND_MINERALS: {
    en: 'Metals and Minerals',
    nb: 'Metaller og mineraler',
  },
  ENVIRONMENTAL_SERVICES: {
    en: 'Environmental Services',
    nb: 'Miljøtjenester',
  },
  MUSEUMS_AND_CULTURAL_HERITAGE: {
    en: 'Museums and Cultural Heritage',
    nb: 'Museer, gallerier og kulturminne',
  },
  PUBLIC_ADMINISTRATION: {
    en: 'Public Administration',
    nb: 'Offentlig administrasjon',
  },
  OIL_AND_GAS: {
    en: 'Oil and Gas',
    nb: 'Olje og gass',
  },
  ORGANIZATIONS: {
    en: 'Organizations',
    nb: 'Organisasjoner',
  },
  PR_AND_COMMUNICATION: {
    en: 'PR and Communication',
    nb: 'PR, informasjon og kommunikasjon',
  },
  POLICE_AND_SECURITY: {
    en: 'Police and Security',
    nb: 'Politi og sikkerhet',
  },
  TRAVEL_AND_TOURISM: {
    en: 'Travel and Tourism',
    nb: 'Reise og turisme',
  },
  WASTE_MANAGEMENT: {
    en: 'Waste Management',
    nb: 'Renovasjon og renhold',
  },
  FOOD_AND_BEVERAGE: {
    en: 'Food and Beverage',
    nb: 'Restaurant, mat og uteliv',
  },
  SHIPPING: {
    en: 'Shipping',
    nb: 'Shipping',
  },
  CATERING: {
    en: 'Catering',
    nb: 'Storhusholdning og catering',
  },
  TEXTILE: {
    en: 'Textile',
    nb: 'Tekstil',
  },
  TELECOMMUNICATIONS: {
    en: 'Telecommunications',
    nb: 'Telekommunikasjon',
  },
  TRANSPORT_AND_LOGISTICS: {
    en: 'Transport and Logistics',
    nb: 'Transport og logistikk',
  },
  WOOD_INDUSTRY: {
    en: 'Wood Industry',
    nb: 'Trevareindustri',
  },
  ENTERTAINMENT: {
    en: 'Entertainment',
    nb: 'Underholdning',
  },
  HVAC: {
    en: 'HVAC',
    nb: 'VVS',
  },
  WELLNESS: {
    en: 'Wellness',
    nb: 'Velvære',
  },
  HEAVY_INDUSTRY: {
    en: 'Heavy Industry',
    nb: 'Verksindustri',
  },
  FINANCE_AND_ACCOUNTING: {
    en: 'Finance and Accounting',
    nb: 'Økonomi og regnskap',
  },
  STRATEGIC_PARTNER_SALES: {
    en: 'Strategic Partner Sales',
    nb: 'Strategisk partnersalg',
  },
  BRANDING: {
    en: 'Branding',
    nb: 'Merkevarebygging',
  },
  CAPITAL_RAISING: {
    en: 'Capital Raising',
    nb: 'Kapitalinnhenting',
  },
  INITIAL_PUBLIC_OFFERING: {
    en: 'Initial Public Offering',
    nb: 'Børsnotering',
  },
  OTHER_INDUSTRY: {
    en: 'Other',
    nb: 'Annet',
  },
  OTHER: {
    en: 'Other',
    nb: 'Annet',
  },
  POLITICS: {
    en: 'Politics',
    nb: 'Politikk',
  },
  SALG: {
    en: 'Sales and Sales management',
    nb: 'Salg og salgsledelse',
  },
  ADMINISTRATION: {
    en: 'Administration',
    nb: 'Administrasjon',
  },
  HR: {
    en: 'Human Resources',
    nb: 'HR',
  },
  FOU: {
    en: 'Research and Development',
    nb: 'FOU',
  },
  SUSTAINABILITY_ESG: {
    en: 'Sustainability/ESG',
    nb: 'Bærekraft/ESG',
  },
  COMPETENCY_SECURITY_FIRM: {
    en: 'Securities firm',
    nb: 'Verdipapirforetak',
  },
  COMPETENCY_ASSET_MNGMNT: {
    en: 'Asset management',
    nb: 'Fondsforvaltning',
  },
  COMPETENCY_POLITICS: {
    en: 'Politics',
    nb: 'Politikk',
  },
  COMPETENCY_JUSS: {
    en: 'Law',
    nb: 'Juss',
  },
  INTERNATIONALIZATION: {
    en: 'Internationalization',
    nb: 'Internasjonalisering',
  },
  PROGRAMMING: {
    en: 'Programming',
    nb: 'Programmering',
  },
  AI: {
    en: 'Artificial Intelligence',
    nb: 'Kunstig intelligens',
  },
  BLOCKCHAIN: {
    en: 'Blockchain',
    nb: 'Blockchain',
  },
  MERGERSANDACQUISITIONS: {
    en: 'Mergers and Acquisitions',
    nb: 'Fusjon og oppkjøp',
  },
  TECHNOLOGY: {
    en: 'Technology',
    nb: 'Teknologi',
  },
  CULTUREANDENTERTAINMENT: {
    en: 'Culture and Entertainment',
    nb: 'Kultur og underholdning',
  },
  EDUCATION_TRAINING: {
    en: 'Education/Training',
    nb: 'Utdanning/opplæring',
  },
  SERVICE_CUSTOMERSUPPORT: {
    en: 'Service/Customer Support',
    nb: 'Service/kundestøtte',
  },
  CHAIRMANOFTHEBOARD: {
    en: 'Chairman of the Board',
    nb: 'Styreleder',
  },
  BOARDMEMBER: {
    en: 'Board Member',
    nb: 'Styremedlem',
  },
  ALTERNATEMEMBER: {
    en: 'Deputy Member',
    nb: 'Varamedlem',
  },
  NOMINATIONCOMMITTEE: {
    en: 'Nomination Committee',
    nb: 'Valgkomité',
  },
  REMUNERATIONCOMMITTEE: {
    en: 'Remuneration Committee',
    nb: 'Godgjørelsesutvalg',
  },
  CONTROLCOMMITTEE: {
    en: 'Control Committee',
    nb: 'Kontrollutvalg',
  },
  AUDITCOMMITTEE: {
    en: 'Audit Committee',
    nb: 'Revisjonsutvalg',
  },
  ADVISORYBOARD: {
    en: 'Advisory Board',
    nb: 'Advisory board',
  },
  MANAGINGDIRECTOR: {
    en: 'Managing Director',
    nb: 'Daglig leder',
  },
  ADVISOR: {
    en: 'Advisor',
    nb: 'Rådgiver',
  },
  MENTOR: {
    en: 'Mentor',
    nb: 'Mentor',
  },
  DEPUTYCHAIR: {
    en: 'Deputy Chair',
    nb: 'Styrets nestleder',
  },
  OBSERVER: {
    en: 'Observer',
    nb: 'Observatør',
  },
  BOARDSECRETARY: {
    en: 'Board Secretary',
    nb: 'Styresekretær',
  },
  INVESTOR: {
    en: 'Investor',
    nb: 'Investor',
  },
  ENGLISH: {
    en: 'English',
    nb: 'Engelsk',
  },
  SPANISH: {
    en: 'Spanish',
    nb: 'Spansk',
  },
  GERMAN: {
    en: 'German',
    nb: 'Tysk',
  },
  FRENCH: {
    en: 'French',
    nb: 'Fransk',
  },
  SWEDISH: {
    en: 'Swedish',
    nb: 'Svensk',
  },
  CHINESE: {
    en: 'Chinese',
    nb: 'Kinesisk',
  },
  RUSSIAN: {
    en: 'Russian',
    nb: 'Russisk',
  },
  ITALIAN: {
    en: 'Italian',
    nb: 'Italiensk',
  },
  JAPANESE: {
    en: 'Japanese',
    nb: 'Japansk',
  },
  ARABIC: {
    en: 'Arabic',
    nb: 'Arabisk',
  },
  NORTHERNSAMI: {
    en: 'Northern Sami',
    nb: 'Nordsamisk',
  },
  PORTUGUESE: {
    en: 'Portuguese',
    nb: 'Portugisisk',
  },
  DUTCH: {
    en: 'Dutch',
    nb: 'Nederlandsk',
  },
  LATIN: {
    en: 'Latin',
    nb: 'Latin',
  },
  GREEK: {
    en: 'Greek',
    nb: 'Gresk',
  },
  DANISH: {
    en: 'Danish',
    nb: 'Dansk',
  },
  FINNISH: {
    en: 'Finnish',
    nb: 'Finsk',
  },
  POLISH: {
    en: 'Polish',
    nb: 'Polsk',
  },
  TURKISH: {
    en: 'Turkish',
    nb: 'Tyrkisk',
  },
  KOREAN: {
    en: 'Korean',
    nb: 'Koreansk',
  },
  HEBREW: {
    en: 'Hebrew',
    nb: 'Hebraisk',
  },
  PERSIAN: {
    en: 'Persian',
    nb: 'Persisk',
  },
  URDU: {
    en: 'Urdu',
    nb: 'Urdu',
  },
  HINDI: {
    en: 'Hindi',
    nb: 'Hindi',
  },
  THAI: {
    en: 'Thai',
    nb: 'Thai',
  },
  VIETNAMESE: {
    en: 'Vietnamese',
    nb: 'Vietnamesisk',
  },
  INDONESIAN: {
    en: 'Indonesian',
    nb: 'Indonesisk',
  },
  HUNGARIAN: {
    en: 'Hungarian',
    nb: 'Ungarsk',
  },
  CZECH: {
    en: 'Czech',
    nb: 'Tsjekkisk',
  },
  ROMANIAN: {
    en: 'Romanian',
    nb: 'Rumensk',
  },
  NORWEGIAN: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  LinkedInTooltip: {
    en: 'Add a link to your linkedIn in this format: https://www.linkedin.com/in/YOUR USER/',
    nb: 'Legg til en link til din LinkedIn profil i dette formatet: https://www.linkedin.com/in/YOUR USER/',
  },
  TitleToolTip: {
    en: 'Enter a title for your ad, for example what you are looking for',
    nb: 'Legg inn en tittel på din annonse, for eksempel hva du søker etter',
  },
  AllProfiles: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  NoSelectedProfile: {
    en: 'No selected advertisement',
    nb: 'Ingen annonse valgt',
  },
  WantToDelete: {
    en: 'Are you sure you want to delete? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette? Dette kan ikke angres.',
  },
  WantToDeleteHeader: {
    en: 'Warning',
    nb: 'Advarsel',
  },
  WantToDeleteAd: {
    en: 'Are you sure you want to delete the advertisement? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette annonsen? Dette kan ikke angres.',
  },
  WantToDeleteProfile: {
    en: 'Are you sure you want to delete your profile? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette din profil? Dette kan ikke angres.',
  },
  WantToDeleteMsg: {
    en: 'Are you sure you want to delete this message? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette meldingen? Dette kan ikke angres.',
  },
  WantToDeleteInquiry: {
    en: 'Are you sure you want to delete this inquiry? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette denne henvendelsen? Dette kan ikke angres.',
  },
  AnErrorHaveOcurred: {
    en: 'An error have occured',
    nb: 'Det har oppstått en feil',
  },
  CheckIfYourEventsAreConsistentIfThisErrorPersistsContactSupport: {
    en: 'Check if your events are consistent. If this error persists, contact support.',
    nb: 'Sjekk om hendelser er konsistente. Hvis denne feilen vedvarer, kontakt support.',
  },
  NoSubscriptionsEmission: {
    en: 'You currently have no subscriptions, historic or ongoing...',
    nb: 'Du har for øyeblikket ingen bestillingsblanketter, verken historiske eller pågående...',
  },
  ShareClassOnly: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  CompanyOnly: {
    en: 'Company',
    nb: 'Selskap',
  },
  Ownershare: {
    en: 'Share of ownership',
    nb: 'Eierandel',
  },
  EstimatedValue: {
    en: 'Estimated value',
    nb: 'Estimert verdi',
  },
  Portfolio: {
    en: 'Portfolio',
    nb: 'Portefølje',
  },
  FromNetwork: {
    en: 'From network',
    nb: 'Fra nettverk',
  },
  STARTUP: {
    en: 'Start-up business',
    nb: 'Oppstartselskap',
  },
  SMB: {
    en: 'SMB',
    nb: 'SMB',
  },
  BIGBUSINESS: {
    en: 'Big business',
    nb: 'Stor virksomhet',
  },
  LISTED: {
    en: 'Listed',
    nb: 'Børsnotert',
  },
  ASSOCIATION: {
    en: 'Association',
    nb: 'Forening',
  },
  FOUNDATION: {
    en: 'Foundation',
    nb: 'Stiftelse',
  },
  SPORTS: {
    en: 'Sports',
    nb: 'Idrett',
  },
  PAYED: {
    en: 'Paid for in money',
    nb: 'Betalt i penger',
  },
  NOTPAYED: {
    en: 'Unpaid position',
    nb: 'Ikke betalt verv',
  },
  PAYEDSTOCKS: {
    en: 'Options, stocks, other',
    nb: 'Opsjoner, aksjer, annet',
  },
  NEGOTIATION: {
    en: 'After further agreement',
    nb: 'Etter nærmere avtale',
  },
  Latest: {
    en: 'Latest',
    nb: 'Nyeste',
  },
  BestMatch: {
    en: 'Best match',
    nb: 'Beste match',
  },
  HideContactedOrgs: {
    en: 'Hide contacted organizations',
    nb: 'Skjul kontaktede organisasjoner',
  },
  ProfileDeleted: {
    en: 'Profile deleted',
    nb: 'Profil slettet',
  },
  ErrorOnDelete: {
    en: 'Error on deleting',
    nb: 'Feil ved sletting',
  },
  InquiryDeleted: {
    en: 'Inquiry deleted',
    nb: 'Henvendelse slettet',
  },
  ErrorOnDeleteInquiry: {
    en: 'Error on deleting inquiry',
    nb: 'Feil ved sletting av henvendelse',
  },
  PostedInquiry: {
    en: 'Posted inquiry',
    nb: 'Henvendelse sendt',
  },
  ErrorFetchInquiry: {
    en: 'Error fetching inquiry',
    nb: 'Feil ved henting av henvendelse',
  },
  DeletedMessageAction: {
    en: 'Message deleted',
    nb: 'Slettet melding',
  },
  ExportTransactions: {
    en: 'Download events',
    nb: 'Last ned hendelser',
  },
  ImportTransactions: {
    en: 'Upload events',
    nb: 'Last opp hendelser',
  },
  ImportTransactionSpreadSheet: {
    en: 'Upload spreadsheet',
    nb: 'Last opp regneark',
  },
  RF1086: {
    en: 'Shareholder register statement',
    nb: 'Aksjonærregisteroppgaven',
  },

  ErrorDeletingMessage: {
    en: 'Error on deleting message',
    nb: 'Feil ved sletting av melding',
  },
  PostedMessage: {
    en: 'Message sent',
    nb: 'Melding sendt',
  },
  ErrorPostingMessage: {
    en: 'Error on sending message',
    nb: 'Feil ved sending av melding',
  },
  SavedNotes: {
    en: 'Saved notes',
    nb: 'Internt notat lagret',
  },
  NotesText: {
    nb: 'Internt notat',
    en: 'Internal note',
    sv: 'Internt not',
  },
  ErrorSavingNotes: {
    en: 'Error on saving notes',
    nb: 'Feil ved lagring av notat',
  },
  CandidateEmail: {
    en: 'Email: ',
    nb: 'Email: ',
  },
  CandidatePhone: {
    en: 'Phone number: ',
    nb: 'Telefon nr: ',
  },
  ProfileSaved: {
    en: 'Profile saved',
    nb: 'Lagret profil',
  },
  ErrorProfileSaved: {
    en: 'Error saving profile',
    nb: 'Feil ved lagring av profil',
  },
  ProfileNotValid: {
    en: 'Profile not valid',
    nb: 'Profilen er ugyldig',
  },
  ErrorFetchingProfile: {
    en: 'Error fetching profile',
    nb: 'Feil ved henting av profil',
  },
  NetworkCandidateProfileDeleted: {
    en: 'Network candidate deleted',
    nb: 'Nettverkskandidat slettet',
  },
  NoneValidProfileId: {
    en: 'Ivalid profile ID',
    nb: 'Ugyldig profil-ID',
  },
  SavedAd: {
    en: 'Saved advertisement',
    nb: 'Lagret annonse',
  },
  ErrorSavingAd: {
    en: 'Error on saving advertisement',
    nb: 'Feil ved lagring av annonse',
  },
  DuplicateToNetwork: {
    en: 'Can not add duplicates to network',
    nb: 'Kan ikke lagre duplikater til nettverk',
  },
  SavedToNetwork: {
    en: 'Saved to network',
    nb: 'Lagret i nettverk',
  },
  ErrorSavingToNetwork: {
    en: 'Error on saving to network',
    nb: 'Feil ved lagring i nettverk',
  },
  PublishProfile: {
    en: 'Publish profile',
    nb: 'Publiser profil',
  },
  PublishProfileDesc: {
    en: 'You can publish your profile for businesses, or save it as a draft only visible to yourself.',
    nb: 'Du kan publisere profilen din for virksomheter, eller lagre den som utkast kun synlig for deg selv.',
  },
  PublishAd: {
    en: 'Publish advertisement',
    nb: 'Publiser annonse',
  },
  PublishAdDesc: {
    en: 'You can publish the advertisement for candidates, or save it as a draft only visible to your organisation.',
    nb: 'Du kan publisere annonsen for kandidater, eller lagre den som utkast kun synlig for din organisasjon.',
  },
  SaveAsDraft: {
    en: 'Save as draft',
    nb: 'Lagre som utkast',
  },
  SaveAndPublish: {
    en: 'Save and publish',
    nb: 'Lagre og publiser',
  },
  MoreInfo: {
    en: 'More information',
    nb: 'Mer informasjon',
  },
  GetStarted: {
    en: 'Get started',
    nb: 'Kom i gang',
  },
  NoMatchesOrg: {
    en: "It looks like there aren't any matches right now, but new candidates get added frequently. Keep paying attention!",
    nb: 'Det ser ut som det ikke er noen match akkurat nå, men nye kandidater legges stadig til. Følg med!',
  },
  NoMatchesCandidate: {
    en: "It looks like there aren't any matches right now, but new opportunities appear frequently. Keep paying attention!",
    nb: 'Det ser ut som det ikke er noen match akkurat nå, men nye muligheter dukker stadig opp. Følg med!',
  },
  InterestingCandidate: {
    en: 'Is the candidate interesting?',
    nb: 'Er kandidaten interessant?',
  },
  InterestingCandidateInfoBox: {
    en: 'Here you have the option to mark a candidate as interesting, not interesting, or maybe. This will not be visible to the candidate. Please note that if you mark a candidate as "No", the candidate will disappear from your match.',
    nb: 'Her har du muligheten til å merke en kandidat som interessant, ikke interessant, eller kanskje. Dette vil ikke være synlig for kandidaten. Vær oppmerksom på at hvis du markerer en kandidat som "Nei", vil kandidaten forsvinne fra din match.',
  },
  InterestingAd: {
    en: 'Is the ad interesting?',
    nb: 'Er annonsen interessant?',
  },
  InterestingAdInfoBox: {
    en: 'Here you have the option of marking an ad as interesting, not interesting, or maybe. This will not be visible to the advertiser. Please note that if you mark an ad as "No", the ad will disappear from your match.',
    nb: 'Her har du muligheten til å merke en annonse som interessant, ikke interessant, eller kanskje. Dette vil ikke være synlig for annonsøren. Vær oppmerksom på at hvis du markerer en annonse som "Nei", vil annonsen forsvinne fra din match.',
  },
  MaybeInteresting: {
    en: 'Maybe',
    nb: 'Kanskje',
  },
  ShowOnlyRatedProfiles: {
    en: 'Only show rated profiles',
    nb: 'Vis bare vurderte profiler',
  },
  ShowOnlyRatedAds: {
    en: 'Only show rated advertisements',
    nb: 'Vis bare vurderte annonser',
  },
  Years: {
    en: ' years',
    nb: ' år',
  },
  MUSIC: {
    en: 'Music',
    nb: 'Musikk',
  },
  AddToNetworkTooltip: {
    en: 'Here you can add the candidate to your network in Boardmatch. This will not be visible to the candidate.',
    nb: 'Her kan du legge kandidaten til ditt nettverk i Styrematch. Dette vil ikke være synlig for kandidaten.',
  },
  ShowInterestCandidateTooltip: {
    en: 'Here you can send a message to the candidate.',
    nb: 'Du kan her sende en melding til kandidaten.',
  },
  ShowInterestOrgTooltip: {
    en: 'Here you can send a message to the business.',
    nb: 'Du kan her sende en melding til virksomheten.',
  },
  WantToDeleteFromNetwork: {
    en: 'Are you sure you want to delete this candidate from your network? This cannot be undone.',
    nb: 'Er du sikker på at du vil slette denne kandidaten fra nettverket ditt? Dette kan ikke angres.',
  },
  NetworkConnectedToOrgbrain: {
    en: 'This candidate is connected to an Orgbrain account.',
    nb: 'Denne kandidaten er koblet til en Orgbrain-bruker.',
  },
  AllOwners: {
    en: 'All owners',
    nb: 'Alle eiere',
  },
  OpenOrderForms: {
    en: 'Open order forms',
    nb: 'Åpne bestillingsblanketter',
  },
  HistoricalOrderForms: {
    en: 'Historical order forms',
    nb: 'Historiske bestillingsblanketter',
  },
  OpenOrderFormsInfo: {
    en: 'See all open order forms you have received for capital increases that have not yet been completed.',
    nb: 'Her kan du se alle åpne bestillingsblanketter du har mottatt for kapitalforhøyelser som enda ikke er fullført.',
  },
  HistoricalOrderFormsInfo: {
    en: 'Here you can see all historical order forms, both completed and canceled.',
    nb: 'Her kan du se alle historiske bestillingsblanketter, både fullførte og avbrutte.',
  },
  ShareClassSaved: {
    en: 'Share class saved',
    nb: 'Aksjeklasse lagret',
  },
  ErrorSavingShareClass: {
    en: 'Error on saving share class',
    nb: 'Feil ved lagring av aksjeklasse',
  },
  OK: {
    en: 'OK',
    nb: 'OK',
  },
  ParValue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  VoteWeight: {
    en: 'Vote weight',
    nb: 'Stemmevekt',
  },
  ErrorCreatingParValueEvent: {
    en: 'Error creating par value event',
    nb: 'Feil ved opprettelse av endring av pålydende hendelse',
  },
  Seller: {
    en: 'Seller',
    nb: 'Avhender',
  },
  InfoAboutSeller: {
    en: 'Information about seller',
    nb: 'Informasjon om avhender',
  },
  InfoAboutShares: {
    en: 'Information about shares',
    nb: 'Info om aksjer',
  },
  Individual: {
    en: 'Individual',
    nb: 'Individ',
  },
  BoardMembers: {
    en: 'Board Members',
    nb: 'Styremedlemmer',
  },
  SpreadsheetMustShowHistoryOfTransaction: {
    en: 'The spreadsheet must show the history of stock transactions, capital increases, etc.',
    nb: 'Regnearket må vise historikken over hendelser i selskapet.',
  },
  ChooseAMethodToImportThe: {
    en: 'Choose a method to import the ',
    nb: 'Velg en metode for å legge inn ',
  },
  AtACertainTimeUsuallyNowWithNoHistory: {
    en: 'at a certain point in time.',
    nb: 'på et bestemt tidspunkt.',
  },
  PaidAmount: {
    en: 'Amount paid',
    nb: 'Betalt',
  },
  Settings: {
    en: 'Settings',
    nb: 'Innstillinger',
  },
  TermsOfUse: {
    en: 'Terms of use',
    nb: 'Brukervilkår',
  },
  Client: {
    en: 'Client',
    nb: 'Klient',
  },
  Roles: {
    en: 'Roles',
    nb: 'Roller',
  },
  aiTitle: { en: 'Artificial intelligence (AI)  ', nb: 'Kunstig intelligens (AI) ' },
  aiTooltip: {
    en: 'Enable AI to write better texts',
    nb: 'Aktiver AI for å skrive bedre tekster',
  },
  aiBodyText: {
    en: 'Get help from artificial intelligence to write better texts and perform other tasks. You can find more information about the use of AI in our ',
    nb: 'Få hjelp fra kunstig intelligens til å skrive bedre tekster og til å utføre øvrige oppgaver. Du kan finne mer informasjon om bruken av AI i våre ',
  },
  aiTermsLink: { en: 'terms of use', nb: 'brukervilkår' },
  emailLabel: { en: 'Email', nb: 'E-postadresse' },
  totpLabel: { en: 'Authenticator App', nb: 'Authenticator app' },
  addTotpButton: { en: 'Add', nb: 'Legg til' },
  changeTotpButton: { en: 'Change', nb: 'Endre' },
  contactTitle: { en: 'Account', nb: 'Konto' },
  other: { en: 'Other', nb: 'Annet' },
  calendarAPI: { en: 'Calendar API', nb: 'Kalender API' },
  phoneLabel: { en: 'Mobile (SMS 2FA)', nb: '2FA ved bruk av SMS' },
  name: { en: 'Name', nb: 'Navn' },
  nameInfo: {
    nb: 'Her kan du endre navnet på din bruker. For eksempel dersom du har endret ditt etternavn. ',
    en: 'Here you can change the display name of your user. For example, if you have changed your surname.',
  },
  emailInfo: {
    nb: `Her har du mulighet til å endre den e-postadresse som din brukerkonto er koblet til.
      Du kan bare endre til en e-postadresse som ikke allerede er i bruk hos Orgbrain. Dersom det er nødvendig å slå sammen to aktive kontoer, vennligst ta kontakt med Orgbrains kundestøtte.
      `,
    en: `Here you have the option to change the e-mail address to which your user account is linked.
      You can only change to an email address that is not already in use at Orgbrain. If it is necessary to merge two active accounts, please contact Orgbrain's customer support.
      `,
  },
  AnnualPlan: {
    en: 'Annual plan',
    nb: 'Årsplan',
  },
  SearchOrgbrain: {
    en: 'Search Orgbrain',
    nb: 'Søk i Orgbrain',
  },
  passwordInfoBox: {
    nb: `Her har du mulighet til å endre passord som din brukerkonto er koblet til.`,
    en: `Here you have the option to change the password to which your user account is linked.`,
  },
  sportsidInfo: {
    en: `Here you can register authentication  using SportsID`,
    nb: `Her kan du registrere autentisering ved bruk av SportsID`,
  },
  bankidInfo: {
    en: `Here you can register the two-factor authentication (2FA) using BankID`,
    nb: `Her kan du registrere tofaktorautentiseringen (2FA) ved bruk av BankID`,
  },
  phoneInfo: {
    en: `Here you can enter and change your mobile number. This can be used, among other things, for two-factor authentication (2FA)`,
    nb: `Her kan du legge inn og endre mobilnummer. Dette kan blant annet benyttes ved tofaktorautentisering (2FA)`,
  },
  toptInfo: {
    en: `Here you can register the two-factor authentication (2FA) using an app that supports Google Authenticator and other apps that support TOTP (Time-based One-time Password).`,
    nb: `Her kan du registrere tofaktorautentiseringen (2FA) ved bruk av app gir støtte for Google Authenticator og andre apper som støtter TOTP (Time-based One-time Password).
      `,
  },
  editingLocked: {
    nb: 'Lås opp for å endre',
    en: 'Unlock to make changes',
  },
  editingEnabled: {
    en: 'Editing enabled',
    nb: 'Redigering aktivert',
  },
  passwordLable: {
    en: 'Password',
    nb: 'Passord',
  },
  passwordInfo: {
    nb: 'Som en sikkerhetsmekanisme har det blitt sent en e-post til deg med en lenke for å endre passord.',
    en: 'As a security measure, an email has been sent to you with a link to change your password.',
  },
  passwordMailSent: {
    en: 'Mail sent',
    nb: 'E-post sendt',
  },
  passwordSubject: {
    nb: 'Endre passord',
    en: 'Change password',
  },
  orgbrainPasswordTitle: { en: 'Orgbrain password', nb: 'Passord' },
  ResetPassword: {
    en: 'Reset',
    nb: 'Tilbakestill',
  },
  CopyLink: {
    en: 'Copy link',
    nb: 'Kopier lenke',
  },
  SetNewParvalue: {
    en: 'Set new par value',
    nb: 'Sett ny pålydende verdi',
  },
  EditParValue: {
    en: 'Edit par value',
    nb: 'Rediger pålydende',
  },
  NoneLabel: {
    en: 'All labels',
    nb: 'Alle merkelapper',
  },
  RecipientsTooltip: {
    en: 'Select the recipients of the message',
    nb: 'Velg mottakere av meldingen',
  },
  BOARD: {
    en: 'The board',
    nb: 'Styret',
  },
  BOARDTooltip: {
    en: 'The message is only visible to users who have boardaccess.',
    nb: 'Melding blir kun synlig for de som har styretilgang.',
  },
  AttachementInEmail: {
    nb: 'Vedlegg synlig i e-post',
    en: 'Attachments visible in email',
  },
  AttachementInEmailTooltip: {
    nb: 'OBS: Dersom du krysser av her, vil alle vedlegg bli synlig i e-post som sendes mottakerne.',
    en: 'NOTE: If you check this box, all attachments will be visible in the email sent to the recipients.',
  },
  PublishMessage: {
    en: 'An email and notification will be sent indicating that this message has been published.',
    nb: 'En e-post og varsel vil bli sendt om at denne meldingen er publisert.',
  },
  PublishMessageNoExtraInEmail: {
    en: 'The email will only contain the title of the message. Be careful not to include sensitive information in the message title.',
    nb: 'E-posten vil kun inneholde tittelen til meldingen. Vær forsiktig med å legge sensitiv informasjon i meldingstittelen.',
  },
  PublishMessageAttachementInEmailText: {
    en: 'The email will contain the message title and the attached files belonging to the message. Be careful not to include sensitive information in the attachments or the message title.',
    nb: 'E-posten vil inneholde meldingstittelen og vedlagte filer som tilhører meldingen. Vær forsiktig med å legge sensitiv informasjon i vedleggene og meldingstittlen.',
  },
  PublishMessageDescriptionInEmailText: {
    en: 'The email will contain the description and the title of the message. Be careful not to include sensitive information in the message.',
    nb: 'E-posten vil inneholde beskrivelsen og meldingstittelen som tilhører meldingen. Vær forsiktig med å legge sensitiv informasjon i meldingen.',
  },
  PublishMessageAllInEmailText: {
    en: 'The email will contain the description and the attachments belonging to the message. Be careful not to include sensitive information in the message or attachments.',
    nb: 'E-posten vil inneholde beskrivelsen og vedleggene som tilhører meldingen. Vær forsiktig med å legge sensitiv informasjon i meldingen eller vedleggene.',
  },
  messageIsAvailableInEmail: {
    en: 'The message is visible in  the email',
    nb: 'Meldingen er synlig i e-post',
  },
  StartConversation: {
    en: 'Start conversation',
    nb: 'Start samtale',
  },
  CopyMessageLinkTooltip: {
    en: 'Copy the link of the message to clipboard',
    nb: 'Kopier lenken til denne meldingen til utklippstavlen',
  },
  CopyMessageLink: {
    en: 'Copy link',
    nb: 'Kopier lenke',
  },
  DeleteMessageTooltip: {
    en: 'Delete this message permanently',
    nb: 'Slett denne meldingen permanent',
  },
  nameSortTooltip: {
    en: 'Sort alphabetically by name of the shareholder (first name and company name)',
    nb: 'Sorteres alfabetisk etter navn på aksjonæren (fornavn og selskapsnavn)',
  },
  aboutBoardSchoolTooltip: {
    en: 'Read more about the Board School',
    nb: 'Les mer om Styreskolen',
  },
  identifiedBeneficialOwners: {
    en: 'The company believes that there are beneficial owners',
    nb: 'Virksomheten mener det er reelle rettighetshavere',
  },
  noneBeneficialOwners: {
    en: 'The company believes that there are no beneficial owners',
    nb: 'Virksomheten mener det ikke er noen reelle rettighetshavere',
  },
  none_identifiedBeneficialOwners: {
    en: 'The company believes that there are one or more beneficial owners, but can not identify these',
    nb: 'Virksomheten mener det finnes en eller flere reelle rettighetshavere, men kan ikke identifisere disse',
  },
  nullBeneficialOwnersError: {
    en: 'No status selected',
    nb: 'Ingen status valgt',
  },

  nullBeneficialOwners: {
    en: 'No status selected',
    nb: 'Ingen status valgt',
  },

  nullBeneficialOwnersShort: {
    en: 'No status selected',
    nb: 'Ingen status valgt',
  },
  SendInBeneficalOwnerTitle: {
    en: 'Send information to Altinn',
    nb: 'Send informasjon til Altinn',
  },
  SendInBeneficalOwnerText: {
    en: 'You can now log in to Altinn and submit status information about beneficial owners.',
    nb: 'Du kan nå logge inn i Altinn og sende inn statusinformasjon om reelle rettighetshavere.',
  },
  identifiedBeneficialOwnersShort: {
    en: 'Identified beneficial owners',
    nb: 'Identifisert reelle rettighetshavere',
  },
  noneBeneficialOwnersShort: { en: 'No beneficial owners', nb: 'Ingen reelle rettighetshavere' },
  none_identifiedBeneficialOwnersShort: {
    en: 'Not identified beneficial owners',
    nb: 'Ikke identifisert reelle rettighetshavere',
  },
  LeadingPeople: {
    en: 'Leading people',
    nb: 'Ledende personer',
  },
};
