<div class="container" [ngStyle]="{ 'max-width': buttons.length * 180 + 'px' }">
  <div
    class="w-100 d-flex"
    *ngFor="let button of buttons; let i = index; let first = first; let last = last"
  >
    <button
      class="segment"
      [matTooltip]="button.tooltip"
      [ngClass]="{
        'first-segment': first,
        'last-segment': last,
        selected: button.selected
      }"
      (click)="onButtonClick(button)"
    >
      <mat-icon class="icon" *ngIf="button.icon">{{ button.icon }}</mat-icon>
      <mat-icon class="icon" *ngIf="button.selected">check</mat-icon>
      <p
        class="orgbrain-bold-gray-text orgbrain-default-font"
        [ngClass]="{ 'selected-text': button.selected }"
      >
        {{ button.name }}
      </p>
    </button>
    <div *ngIf="!last" class="border"></div>
  </div>
</div>
