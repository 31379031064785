import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SnackMessComponent } from './snack-mess/snack-mess.component';
export { BisnodeService } from './bisnode.service';
export { MessageService } from './message.service';

export { SnackMessComponent } from './snack-mess/snack-mess.component';
export { TitleBarComponent } from './style/title-bar/title-bar.component';
export { SharedUtilService } from './util.service';

import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrgBrainDialogsModule } from './orgbrain-dialogs/orgbraindialogs.module';
import { PrettyjsonPipe } from './prettyjson.pipe';
import { TitleBarComponent } from './style/title-bar/title-bar.component';
import { ThousandseparatordisplayPipe } from './thousandseparatordisplay.pipe';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { NgChartsModule } from 'ng2-charts';
import { DebugDirective } from './directives/debug.directive';
import { HideTabHeaderDirective } from './directives/hideTabHeader.directive';
import { StyleDirective } from './directives/style.directive';
import { LangSelectComponent } from './lang-select/lang-select.component';
import { LocaleDatePipe } from './locale-date.pipe';
import { MoneydisplayPipe } from './moneydisplay.pipe';
import { LoadingSpinnerComponent } from './orgbrain-dialogs/loading-spinner/loading-spinner.component';
import { SimpleSpinnerComponent } from './orgbrain-dialogs/simple-spinner/simple-spinner.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TreeViewComponent } from './tree-view/tree-view.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BankIdCheatComponent } from './bank-id-cheat/bank-id-cheat.component';
import { ComponentContainerComponent } from './component-container/component-container.component';
import { InfoBoxOpenButtonComponent } from './orgbrain-dialogs/info-box/info-box-open-button.component';
import { InfoBoxComponent } from './orgbrain-dialogs/info-box/info-box.component';
import { TextPromptComponent } from './orgbrain-dialogs/text-prompt/support-note.component';
import { OrgbrainHelpComponent } from './orgbrain-help/orgbrain-help.component';
import { PowerStepperComponent } from './power-stepper/power-stepper.component';
import { OrgbrainToastComponent } from './style/orgbrain-toastr';

export { BrregHelperService } from './brreg-helper.service';
export * from './date-util.service';
export * from './device.service';
export * from './landing-params.service';
export * from './locale-service';
export * from './mongoose-util.service';
export * from './orgbrain-dialogs/confirmdialog.component';
export * from './orgbrain-dialogs/info';
export * from './orgbrain-dialogs/ogbrainDialog.service';
export * from './orgbrain-dialogs/orgbraindialogs.module';
export { TextPromptComponent } from './orgbrain-dialogs/text-prompt/support-note.component';
export { OrgbrainLogoComponent } from './orgbrain-logo/orgbrain-logo.component';
export { PageNotFoundDialogComponent } from './page-not-found-dialog/page-not-found-dialog.component';
export { PageNotFoundComponent } from './page-not-found/page-not-found.component';
export * from './phoneValidator';
export * from './prettyjson.pipe';
export * from './safe-html.pipe';
export * from './savefile';
export * from './style/title-bar/title-bar-button';
export * from './thousandseparatordisplay.pipe';
export * from './uuid.service';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { OrgbrainLogoComponent } from './orgbrain-logo/orgbrain-logo.component';
import { PageNotFoundDialogComponent } from './page-not-found-dialog/page-not-found-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AsyncPipe, JsonPipe } from '@angular/common';

import { MatTableModule } from '@angular/material/table';

import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { BottomButtonsComponent } from './style/bottom-buttons/bottom-buttons.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { AutoFocusDirective } from './auto-focus.directive';
import { BeautifulPaginationComponent } from './beautiful-pagination/beautiful-pagination.component';
import { OrgbrainMatTableDataSource } from './OrgbrainDataSource';
import { SimpleSegmentedButtonComponent } from './segmented-buttons/segmented-buttons.component';
import { OrgbrainTableComponent } from './style/orgbrain-table/orgbrain-table.component';
import { TestResultComponent } from './test-result/test-result.component';
import { WarningBoxComponent } from './warning-box/warning-box.component';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    OrgBrainDialogsModule,
    MatTooltipModule,
    MatCardModule,
    DragDropModule,
    PortalModule,
    NgChartsModule,
    MatCheckboxModule,
    MatInputModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    MatChipsModule,
    ThousandseparatordisplayPipe,
    MatDividerModule,
    MoneydisplayPipe,
    MatDialogModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    RouterModule,
    AsyncPipe,
    JsonPipe,
    MatTableModule,
  ],

  declarations: [
    PageNotFoundComponent,
    StyleDirective,
    DebugDirective,
    AutoFocusDirective,
    HideTabHeaderDirective,
    SnackMessComponent,
    TitleBarComponent,
    PrettyjsonPipe,
    LocaleDatePipe,
    SafeHtmlPipe,
    TreeViewComponent,
    LangSelectComponent,
    SimpleSpinnerComponent,
    OrgbrainToastComponent,
    PowerStepperComponent,
    TextPromptComponent,
    OrgbrainHelpComponent,
    BankIdCheatComponent,
    ComponentContainerComponent,
    OrgbrainToastComponent,
    OrgbrainLogoComponent,
    PageNotFoundDialogComponent,
    SimpleSegmentedButtonComponent,
    // ButtonsComponent,
    // PopupMenuComponent,
    // TextStylesComponent,
    BottomButtonsComponent,
    BeautifulPaginationComponent,
    OrgbrainTableComponent,
    TestResultComponent,
    WarningBoxComponent,
  ],

  exports: [
    PageNotFoundComponent,
    OrgbrainLogoComponent,
    ThousandseparatordisplayPipe,
    TitleBarComponent,
    LocaleDatePipe,
    OrgBrainDialogsModule,
    PrettyjsonPipe,
    SimpleSegmentedButtonComponent,
    TreeViewComponent,
    LangSelectComponent,
    LoadingSpinnerComponent,
    DragDropModule,
    SafeHtmlPipe,
    MoneydisplayPipe,
    SimpleSpinnerComponent,
    StyleDirective,
    DebugDirective,
    HideTabHeaderDirective,
    PowerStepperComponent,
    MatMenuModule,
    TextPromptComponent,
    OrgbrainHelpComponent,
    BottomButtonsComponent,
    BankIdCheatComponent,
    OrgbrainToastComponent,
    ComponentContainerComponent,
    PageNotFoundDialogComponent,
    InfoBoxComponent,
    InfoBoxOpenButtonComponent,
    BeautifulPaginationComponent,
    OrgbrainTableComponent,
    AutoFocusDirective,
    TestResultComponent,
    WarningBoxComponent,
  ],
})
export class LibAngularUtilModule {}

export * from './services/style-preferences.service';

export * from './beautiful-pagination/beautiful-pagination.component';
export * from './orgbrain-layout.service';
export { StepDeclaration } from './power-stepper/power-stepper.component';
export * from './reloadHelper';
export * from './services/component-registry.service';
export * from './services/decoration.service';
export * from './services/snack2toastr.service';
export {
  OrgbrainTableData,
  OrgbrainTableMetaItem,
} from './style/orgbrain-table/orgbrain-table.component';
export * from './style/orgbrain-toastr';
export * from './test-result/test-result.component';
export { OrgbrainMatTableDataSource };

export * from './segmented-buttons/segmented-buttons.component';
