import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeDate',
})
export class LocaleDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any, pattern: string = 'mediumDate', locale): any {
    const localeToUse = locale || (this as any).locale;
    try {
      // If this pipe initializes first - it produces errors
      // In the next change detection loop the correct value is displayed, errors are hidden

      const datePipe: DatePipe = new DatePipe(localeToUse);
      return datePipe.transform(value, pattern);
    } catch (err: any) {
      //  return 'samets' + super.transform(value, pattern);
      if (pattern === 'longDate') {
        const date = new Date(value);

        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };

        return date.toLocaleDateString(localeToUse, options);
      } else if (pattern === 'mediumDate') {
        const date = new Date(value);

        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };

        return date.toLocaleDateString(localeToUse, options);
      } else {
        return super.transform(value, pattern);
      }
    }
  }
}
