export enum SharePermissionEnum {
  READONLY = 'READONLY',
  FULL = 'FULL',
  REMOVE = 'REMOVE',
}

// Used by the filebrowser but the name propagtes to the backend
export enum BaseSteps {
  VisibleToAll = 'VisibleToAll',
  DataRoom = 'DataRoom',
  MeetingFiles = 'MeetingFiles',
  PersonalFiles = 'PersonalFiles',
  _SHARED_WITH_ME_ = '_SHARED_WITH_ME_',
  _SHARED_I_CONTROL_ = '_SHARED_I_CONTROL_',
}

export interface ACLPrivileges {
  READ?: boolean;
  WRITE?: boolean;
}

export function aclPrivesToString(acl): string {
  let ret = '';
  if (acl.READ) {
    ret += 'R';
  }
  if (acl.WRITE) {
    ret += 'W';
  }
  return ret;
}
export interface ACLMap {
  [group: string]: ACLPrivileges;
}
export enum DirentNameEnum {
  VINILLA = 'VINILLA',
  USER_ID = 'USER_ID',
  MEETING_ID = 'MEETING_ID',
  ORGANIZATION_ID = 'ORGANIZATION_ID',
  KEY = 'KEY',
  KEY_MUTABLE = 'KEY_MUTABLE',
}

// This is really the same as DirentNode  but it was in the wrong lib for reuse
// We could try to deprecate DirentNode and use this instead.
// export type DirentNode = {
//   _id?: MongoId;
//   parentId: MongoId;
//   organizationId: MongoId;
//   hash?: string;
//   name: string;
//   oldName?: string;
//   sharedWithOrganizations?: MongoId[];
//   signedFileKey?: string;
//   sharedAssociationRules?: any[];
//   deleted?: boolean;
//   isFolder: boolean;
//   acl: ACLMap; // Access Control List  (group: { READ: true, WRITE: true })
//   ecl?: ACLMap; // Exclude Access Control List
//   scl?: ACLMap; // Shared Access Control List
//   createdBy: MongoId;
//   nameType: DirentNameEnum;
//   fileSize: number;
//   contentType?: string;
//   fileReviewMetaSideOne?: any;
//   fileReviewMetaSideTwo?: any;
//   modifiedBy?: MongoId;
//   dirty?: boolean;
//   createdAt?: Date;

//   // local fields   see context before using any of this.
//   displayName?: string; // temp to store translated name.
// };
