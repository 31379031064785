<div class="orgbrain-style" style="min-width: 250px">
  <orgbrain-dialog-title [title]="data.title" (closeClick)="dialogRef.close(true)">
  </orgbrain-dialog-title>

  <div class="d-flex justify-between flex-col">
    <mat-dialog-content class="info-box"
      ><p [innerHtml]="trustedHtml" class="mat-body"></p>
      <p [innerHtml]="errorHtml" class="mat-body"></p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="mb-0 pb-0">
      <button
        mat-flat-button
        color="primary"
        class="action-button"
        cdkFocusInitial
        mat-dialog-close
      >
        OK
      </button>
    </mat-dialog-actions>
  </div>
</div>
