import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class SegmentedButton {
  name: string;
  icon: string;
  key: any;
  tooltip: string;
  selected: boolean;
}

@Component({
  selector: 'orgbrain-simple-segmented-buttons',
  templateUrl: './segmented-buttons.component.html',
  styleUrls: ['./segmented-buttons.component.scss'],
})
export class SimpleSegmentedButtonComponent implements OnInit {
  //Untranslated button name
  @Input() buttons: SegmentedButton[];
  //Icons to show along with button names
  @Output() selectedButton: EventEmitter<SegmentedButton> = new EventEmitter();

  @Input() mode: 'radio' | 'checkbox' = 'radio';

  ngOnInit(): void {}

  onButtonClick(button: SegmentedButton) {
    if (this.mode === 'radio') {
      this.buttons.forEach((x) => (button === x ? (x.selected = true) : (x.selected = false)));
    } else {
      button.selected = !button.selected;
    }
    this.selectedButton.emit(button);
  }
}
