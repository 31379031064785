<!-- <div mat-dialog-title class="dialog-title-with-close-button">
  <h1 style="margin-top: 16px">
    {{ data.title }}
  </h1>
  <button
    mat-icon-button
    color="primary"
    class="dialog-close-button"
    (click)="dialogRef.close(false)"
  >
    <mat-icon>cancel</mat-icon>
  </button>
</div> -->

<div class="orgbrain-style">
  <ng-container *ngIf="data.imageUrl">
    <div class="image-container">
      <img class="image-style" [src]="data.imageUrl" />
    </div>
  </ng-container>

  <orgbrain-dialog-title [title]="data.title" [dialogRef]="data.noCancel ? null : dialogRef">
  </orgbrain-dialog-title>

  <mat-dialog-content class="info-box body-text-1"
    ><p [innerHtml]="trustedHtml" class="mat-body"></p>
  </mat-dialog-content>
  <div [ngClass]="{ 'button-row-full': !layout.mobile, 'button-col': layout.mobile, 'mb-0': true }">
    <ng-container *ngFor="let option of data.options">
      <button
        mat-flat-button
        [color]="getColor(option)"
        cdkFocusInitial
        (click)="select(option.key)"
        [matTooltip]="option.tip"
        [style]="getStyle(option)"
        [color]="option.color"
      >
        {{ option.disp }}
      </button>
    </ng-container>
  </div>
</div>
