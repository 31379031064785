import { CompanyTerminologyEnum } from './companyTypesWithTypeChecking';

export enum SecurityGroupName {
  BASIC = 'BASIC',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

export const securityGroupNameLevelMap: { [key in SecurityGroupName]: number } = {
  BASIC: 1,
  MODERATE: 2,
  HIGH: 3,
};
export type BooleanMap = { [key: string]: boolean };

export type RoleBooleanMap = { [key in Role]: boolean };
export type PermissionBooleanMap = { [key in PermissionEnum]: boolean };
export type OptionalPermissionBooleanMap = { [key in PermissionEnum]?: boolean };
export type OptionalRoleBooleanMap = { [key in Role]?: boolean };

export enum Role {
  ADMIN = 'ADMIN',
  USER_ADMIN = 'USER_ADMIN',
  CHAIR = 'CHAIR',
  BOARDMEMBER = 'BOARDMEMBER',
  CEO = 'CEO',
  BOARDSECRETARY = 'BOARDSECRETARY',
  DEPUTYCHAIR = 'DEPUTYCHAIR',
  DEPUTYMEMBER = 'DEPUTYMEMBER',
  OBSERVER = 'OBSERVER',
  SHAREHOLDER = 'SHAREHOLDER',
  AUDITOR = 'AUDITOR',
  CONTACTPERSON = 'CONTACTPERSON',
  SHAREHOLDER_PROXY = 'SHAREHOLDER_PROXY',
  BUYER = 'BUYER',
  SCHOOL = 'SCHOOL',
  OWNER = 'OWNER',
  SELLER = 'SELLER',
  SELLER_ADMIN = 'SELLER_ADMIN',
  BUYER_ADMIN = 'BUYER_ADMIN',
  ORGBRAIN_SUPPORT = 'ORGBRAIN_SUPPORT',
  BOARDMATCH_ADMIN = 'BOARDMATCH_ADMIN',
  ONBOARD_SUPPORT = 'ONBOARD_SUPPORT',
  OWNER_ADMIN = 'OWNER_ADMIN',
  ROLE_1 = 'ROLE_1',
  ROLE_2 = 'ROLE_2',
  ROLE_3 = 'ROLE_3',
  ROLE_4 = 'ROLE_4',
  ROLE_5 = 'ROLE_5',
  ROLE_6 = 'ROLE_6',
  CASHIER = 'CASHIER',
  POLICE_CERTIFICATE_RESPONSIBLE = 'POLICE_CERTIFICATE_RESPONSIBLE',
  YOUTHSPORTS_MANAGER = 'YOUTHSPORTS_MANAGER',
  COLLABORATOR = 'COLLABORATOR',
  // EVENT_MANAGER = 'EVENT_MANAGER',
}

export const nonEditableRoles: OptionalRoleBooleanMap = {
  OWNER: true,
  SHAREHOLDER: true,
};

export const roleSummaryDescriptionList: { [key in Role]: { en: string; nb?: string } } = {
  ADMIN: { en: 'Organization Administrator Summary' },
  USER_ADMIN: { en: 'User Management Summary' },
  CHAIR: { en: 'Chair Summary' },
  BOARDMEMBER: { en: 'Board member Summary' },
  CEO: { en: 'CEO Summary' },
  BOARDSECRETARY: { en: 'Board Secretary Summary' },
  DEPUTYCHAIR: { en: 'Deputy Chair Summary' },
  DEPUTYMEMBER: { en: 'Deputy Member Summary' },
  OBSERVER: { en: 'Observer Summary' },
  SHAREHOLDER: { en: 'Investor Summary' },
  AUDITOR: { en: 'Auditor Summary' },
  CONTACTPERSON: { en: 'Contact Person Summary' },
  SHAREHOLDER_PROXY: { en: 'Share Holder Proxy Summary' },
  BUYER: { en: 'Buyer Summary' },
  SCHOOL: { en: 'Boardschool user Summary' },
  OWNER: { en: 'Owner' },
  ORGBRAIN_SUPPORT: { en: 'Orgbrain Support' },
  OWNER_ADMIN: { en: 'Owner admin' },
  [Role.SELLER]: {
    en: 'SELLER',
  },
  [Role.SELLER_ADMIN]: {
    en: 'SELLER_ADMIN',
  },
  [Role.BUYER_ADMIN]: {
    en: 'BUYER_ADMIN',
  },
  [Role.BOARDMATCH_ADMIN]: {
    en: 'BOARDMATCH_ADMIN',
  },
  [Role.ROLE_1]: {
    en: 'ROLE_1',
  },
  [Role.ROLE_2]: {
    en: 'ROLE_2',
  },
  [Role.ROLE_3]: {
    en: 'ROLE_3',
  },
  [Role.ROLE_4]: {
    en: 'ROLE_4',
  },
  [Role.ROLE_5]: {
    en: 'ROLE_5',
  },
  [Role.ROLE_6]: {
    en: 'ROLE_6',
  },
  [Role.CASHIER]: {
    en: 'CASHIER',
  },
  [Role.POLICE_CERTIFICATE_RESPONSIBLE]: {
    en: 'POLICE_CERTIFICATE_RESPONSIBLE',
  },
  [Role.YOUTHSPORTS_MANAGER]: {
    en: 'YOUTHSPORTS_MANAGER',
  },
  [Role.ONBOARD_SUPPORT]: {
    en: 'ONBOARD SUPPORT',
  },
  [Role.COLLABORATOR]: {
    en: 'Collaborator',
    nb: 'Medarbeider',
  },
};

export function getFriendlyRoleName(role: Role) {
  return roleSummaryDescriptionList[role].en;
}

export type RoleMapType = { [role in Role]: OptionalPermissionBooleanMap };

export enum PermissionEnum {
  ADMIN = 'ADMIN',

  meetings = 'meetings',
  MEETING_ADMIN = 'MEETING_ADMIN', //  either GA_MEETING_ADMIN or BOARD_MEETING_ADMIN should gives this.
  GA_MEETING_ADMIN = 'GA_MEETING_ADMIN',
  BOARD_MEETING_ADMIN = 'BOARD_MEETING_ADMIN',
  CAN_VOTE_BOARDMEETING = 'CAN_VOTE_BOARDMEETING',
  CAN_VOTE_IN_THEORY_BOARDMEETING = 'CAN_VOTE_IN_THEORY_BOARDMEETING',
  ACCESS_TO_BOARD_MEETINGS = 'ACCESS_TO_BOARD_MEETINGS',
  ACCESS_TO_GA_MEETINGS = 'ACCESS_TO_GA_MEETINGS',

  organization = 'organization',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',

  shareholders = 'shareholders',
  SHAREHOLDER_ADMIN = 'SHAREHOLDER_ADMIN',
  SHAREHOLDER = 'SHAREHOLDER',
  VIEW_SHARES = 'VIEW_SHARES',
  VIEW_SHARE_ISSUES = 'VIEW_SHARE_ISSUES',
  INITIATE_ORGBRAIN_SIGNING = 'INITIATE_ORGBRAIN_SIGNING',
  INITIATE_BANK_ID_SIGNING = 'INITIATE_BANK_ID_SIGNING',
  tasks = 'tasks',
  TASKS_ADMIN = 'TASKS_ADMIN',

  news = 'news',
  NEWS_ADMIN = 'NEWS_ADMIN',
  GA_NEWS_ADMIN = 'GA_NEWS_ADMIN',
  ACCESS_TO_NEWS_FOR_BOARD = 'ACCESS_TO_NEWS_FOR_BOARD',
  ACCESS_TO_NEWS_FOR_SHAREHOLDERS = 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',

  files = 'files',
  DATAROOM_DOWNLOAD_ZIP = 'DATAROOM_DOWNLOAD_ZIP',
  DATAROOM_ADMIN = 'DATAROOM_ADMIN',
  DATAROOM_SEE_SHOW_DELETED = 'DATAROOM_SEE_SHOW_DELETED',
  DATAROOM_COPY_FILE = 'DATAROOM_COPY_FILE',
  DATAROOM_SEE_FILE_DETAILS = 'DATAROOM_SEE_FILE_DETAILS',
  DATAROOM_SEE_FILE_HISTORY = 'DATAROOM_SEE_FILE_HISTORY',
  DATAROOM_CREATE_TASK_FROM_FILE = 'DATAROOM_CREATE_TASK_FROM_FILE',
  DATAROOM_SEE_FILE_MENU = 'DATAROOM_SEE_FILE_MENU',
  DATAROOM_SET_FILE_STATUS = 'DATAROOM_SET_FILE_STATUS',

  OWNER = 'OWNER',

  CREATE_SIGNING_ORDER = 'CREATE_SIGNING_ORDER',
  SEE_CYCLIC_CALENDAR = 'SEE_CYCLIC_CALENDAR',
  SEE_FOLLOWUP_CASES = 'SEE_FOLLOWUP_CASES',
  SEE_ACTION_POINTS = 'SEE_ACTION_POINTS',
  SEE_USER_EMAILS = 'SEE_USER_EMAILS',
  SEE_MANAGE_SIGNATURES = 'SEE_MANAGE_SIGNATURES',
  SEE_USER_NAMES = 'SEE_USER_NAMES',
  SEE_ENTITY_SENSITIVE = 'SEE_ENTITY_SENSITIVE',
  CREATE_DD_GROUP = 'CREATE_DD_GROUP',
  CREATE_SUB_GROUP = 'CREATE_SUB_GROUP',
  ACCESS_TO_SECURITY_TAB = 'ACCESS_TO_SECURITY_TAB',
  ACCESS_TO_SETTINGS_TAB = 'ACCESS_TO_SETTINGS_TAB',
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  BUYER_ADMIN = 'BUYER_ADMIN',
  SELLER_ADMIN = 'SELLER_ADMIN',

  OPENAI_USER = 'OPENAI_USER',
  BOARDMATCH_ADMIN = 'BOARDMATCH_ADMIN',
  templates = 'templates',
  TEMPLATES_ADMIN = 'TEMPLATES_ADMIN',

  surveys = 'surveys',

  school = 'school',
  ORGBRAIN_ACADEMY = 'ORGBRAIN_ACADEMY',

  chat = 'chat',
  CONVERSATIONS_ADMIN = 'CONVERSATIONS_ADMIN',
  CONVERSATIONS_CREATE = 'CONVERSATIONS_CREATE',
  CONVERSATIONS_LIMITED = 'CONVERSATIONS_LIMITED',
}

export const ALL_PERMISSIONS = Object.values(PermissionEnum);

export const ALL_PERMISSIONS_MAP: OptionalPermissionBooleanMap = ALL_PERMISSIONS.reduce(
  (acc, perm) => {
    acc[perm] = true;
    return acc;
  },
  {}
);

export enum PermissionSecurityEnum {
  SOFT = 'SOFT',
  HARD = 'HARD',
  MIXED = 'MIXED',
  UNUSED = 'UNUSED',
  UNKNOWN = 'UNKNOWN',
}

export enum PermissionLevelEnum {
  TOP = 'TOP',
  FEATURE = 'FEATURE',
  HIDDEN = 'HIDDEN',
}

export type PermissionDescription = {
  description: string;
  en: string;
  nb: string;
  group: string;
  security: PermissionSecurityEnum;
  level: PermissionLevelEnum;
  category: PermissionEnum;
  dependencies: PermissionEnum[];
  tooltips: CompanyTerminology;
  defaultSecurityLevel: SecurityGroupName;
};

export type PermissionDescriptionMap = {
  [perm in PermissionEnum]: PermissionDescription;
};

// The en and nb  descriptions should be useful to describe the file group for this permission and also give an idea of what the permission allow the user to do.
// the group is used internally to store the file permission

export const permissionDescription: PermissionDescriptionMap = {
  surveys: {
    description: 'Access to surveys',
    en: 'SURVEYS',
    nb: 'SPØRRESKJEMA',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.surveys,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  meetings: {
    description: 'Access to meetings',
    en: 'MEETINGS',
    nb: 'MØTER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  templates: {
    description: 'Access to templates',
    en: 'TEMPLATES',
    nb: 'TILGANG TIL MALER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.templates,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  files: {
    description: 'Access to files app',
    en: 'DATAROOM',
    nb: 'DATAROM',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  news: {
    description: 'Access to news/messages',
    en: 'MESSAGES',
    nb: 'MELDINGER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user has access to messages',
        nb: 'Brukeren har tilgang til meldinger',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  school: {
    description: 'Access to boardschool',
    en: 'BOARDSCHOOL',
    nb: 'STYRESKOLEN',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.school,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user will have access to Orgbrain Board School',
        nb: 'Brukeren vil få tilgang til Orgbrain styreskole',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  shareholders: {
    description: 'Access to shareholders',
    en: 'SHAREHOLDERS',
    nb: 'EIER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.shareholders,
    dependencies: [PermissionEnum.VIEW_SHARES],
    tooltips: {
      default: {
        en: 'Shareholder module',
        nb: 'Shareholder module ',
      },
      BRL: {
        en: ' Members app',
        nb: ' Members app NB',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  tasks: {
    description: 'Access to tasks',
    en: 'TASKS',
    nb: 'OPPGAVER',
    group: 'tasks',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.tasks,
    dependencies: [PermissionEnum.SEE_ACTION_POINTS],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  TASKS_ADMIN: {
    description: 'Managing tasks',
    en: 'Tasks admin',
    nb: 'Oppgaver admin',
    group: 'task_admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.tasks,
    dependencies: [PermissionEnum.SEE_ACTION_POINTS, PermissionEnum.SEE_FOLLOWUP_CASES],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  SEE_FOLLOWUP_CASES: {
    description: ' Used to show/hide followup cases in frontend and guard in backend. ',
    en: 'Access to followup cases',
    nb: 'Tilgang til oppfølgingssaker',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.tasks,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SEE_ACTION_POINTS: {
    description: ' Used to show/hide action points in frontend. ',
    en: 'See ordinary tasks',
    nb: 'Se ordinære oppgaver',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.tasks,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  chat: {
    description: 'Access to chat',
    en: 'CHAT',
    nb: 'CHAT',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.chat,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  organization: {
    description: 'Access to organization',
    en: 'ORGANIZATION',
    nb: 'ORGANISASJON',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.organization,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  SHAREHOLDER: {
    description:
      "Used to give 'ga_meetings' file group. Also  used in checkUserHasShareholderAccess() which seems to allow empty array to be returned rather throw error for followup and action points",
    en: 'Investor',
    nb: 'Investor',
    group: 'ga_meetings',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
  OWNER: {
    description: ' Marking the role as Owner ',
    en: 'Owner',
    nb: 'Eier',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  VIEW_SHARES: {
    description: ' Backend guard  to view shares related stuff',
    en: 'View owner / member register',
    nb: 'Se eier-/medlemsregister',
    group: null,
    security: PermissionSecurityEnum.HARD,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user has access to view the shareholder register / member register',
        nb: 'Brukeren har tilgang til å se aksjeeierregisteret / medlemsregisteret',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
  VIEW_SHARE_ISSUES: {
    description: 'Access to share issue sub-module',
    en: 'Share Issues',
    nb: 'Kapitalforhøyelser',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  INITIATE_ORGBRAIN_SIGNING: {
    description: 'Orgbrain e-sign',
    en: 'Orgbrain e-sign',
    nb: 'Orgbrain e-sign',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.INITIATE_ORGBRAIN_SIGNING,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  INITIATE_BANK_ID_SIGNING: {
    description: 'BankId sign',
    en: 'BankId sign',
    nb: 'BankId sign',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.INITIATE_ORGBRAIN_SIGNING,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  ACCESS_TO_BOARD_MEETINGS: {
    description:
      "Used to give 'all_meeting' file group. Also guard for board meeting related stuff in backend.",
    en: 'Access to board meetings',
    nb: 'Tilgang til styremøter',
    group: 'all_meeting',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.SEE_FOLLOWUP_CASES],
    tooltips: {
      default: {
        en: 'The user has access to board meetings',
        nb: 'The user has access to board meetings',
      },
    },
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  ACCESS_TO_GA_MEETINGS: {
    description:
      "Used to give 'ga_meetings' file group. Also guard for GA meeting related stuff in backend. ",
    en: 'Access to General Meeting/Annual Meeting',
    nb: 'Generalforsamling/årsmøte-tilgang',
    group: 'ga_meetings',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.VIEW_SHARES],
    tooltips: {
      default: {
        en: 'The user has access to general assembly meetings / annual meetings',
        nb: 'Brukeren har tilgang til generalforsamlingsmøter / årsmøter',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  CAN_VOTE_BOARDMEETING: {
    description: 'Used to set voting right in frontend.',
    en: 'Board Meeting Voting rights',
    nb: 'Stemmerett',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.CAN_VOTE_IN_THEORY_BOARDMEETING],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },
  CAN_VOTE_IN_THEORY_BOARDMEETING: {
    description:
      'Another check that permits voting. Deputy members and observers do not have the other flag (CAN_VOTE_BOARDMEETING) but still can vote (if they have CAN_VOTE_IN_THEORY_BOARDMEETING and grantVotingRights flag)',
    en: 'Voting rights',
    nb: 'Stemmerett',
    group: null,
    security: PermissionSecurityEnum.HARD,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user always has the right to vote',
        nb: 'Brukeren har alltid stemmerett',
      },
    },
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  ORGANIZATION_ADMIN: {
    description: ' Used for adding and managing user logic in frontend and backend ',
    en: 'Add/remove users',
    nb: 'Legge til/fjerne brukere',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.organization,
    dependencies: [],
    tooltips: {
      default: {
        en: 'User can add and remove other users',
        nb: 'Bruker kan legge til og fjerne andre brukere',
      },
    },
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  ADMIN: {
    description: ' Legacy ADMIN permission. ',
    en: 'Administrator',
    nb: 'Administrator',
    group: 'admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.ADMIN,
    dependencies: [
      //  Until we deprecated this permission automatically add all other admin permissions
      PermissionEnum.ORGANIZATION_ADMIN,
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.GA_MEETING_ADMIN,
      PermissionEnum.BOARD_MEETING_ADMIN,
      PermissionEnum.SHAREHOLDER_ADMIN,
      PermissionEnum.TASKS_ADMIN,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.ACCESS_TO_BOARD_MEETINGS,
      PermissionEnum.NEWS_ADMIN,
      PermissionEnum.GA_NEWS_ADMIN,
      PermissionEnum.TEMPLATES_ADMIN,
      PermissionEnum.TEMPLATES_ADMIN,
      PermissionEnum.BOARDMATCH_ADMIN,
    ],
    tooltips: {
      default: {
        en: 'The user has all administrator rights',
        nb: 'Brukeren har alle administratorrettigheter',
      },
    },
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  MEETING_ADMIN: {
    description:
      ' Legacy meeting admin permission.  You get this if you have GA_MEETING_ADMIN or BOARD_MEETING_ADMIN. ',
    en: 'MeetingAdministrator',
    nb: 'MeetingAdministrator',
    group: 'meeting_admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  GA_MEETING_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend available for GA meetings.',
    en: 'General Meeting/Annual Meeting admin',
    nb: 'Generalforsamling/årsmøte-admin',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.ACCESS_TO_GA_MEETINGS,
    ],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  BOARD_MEETING_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend  available for Board meetings..',
    en: 'Board Meeting admin',
    nb: 'Styremøte-admin',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.ACCESS_TO_BOARD_MEETINGS,
      PermissionEnum.SEE_FOLLOWUP_CASES,
    ],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  SHAREHOLDER_ADMIN: {
    description:
      ' Gives shareholder manager access. Create/edit :  entities / shares / BeneficialOwners',
    en: 'Owners admin',
    nb: 'Eier-administrator',
    group: null,
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.shareholders,
    dependencies: [
      PermissionEnum.SEE_ENTITY_SENSITIVE,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.SEE_ENTITY_SENSITIVE,
    ],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  CREATE_SIGNING_ORDER: {
    description: ' Allows user to create signing orders in frontend. ',
    en: 'Can create signing orders',
    nb: 'Kan opprette signeringsordrer',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.CREATE_SIGNING_ORDER,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SEE_CYCLIC_CALENDAR: {
    description: ' Used to show/hide cyclic calendar in frontend. ',
    en: 'Access to cyclic calendar',
    nb: 'Tilgang til årshjulsvisning',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  SEE_USER_EMAILS: {
    description: ' Used to show/hide user emails in frontend and guard in backend. ',
    en: 'See user emails',
    nb: 'Se brukers e-poster',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SEE_USER_EMAILS,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SEE_USER_NAMES: {
    description: ' Used to show/hide user names in frontend. ',
    // To make submenu item visible
    en: 'See user names',
    nb: 'Se brukernav',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SEE_USER_NAMES,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SEE_MANAGE_SIGNATURES: {
    // Makes sub menu item visible
    description: ' Used to show/hide manage signatures in frontend. ',
    en: 'View and manage signatures',
    nb: 'Se og administrer underskrifter',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.SEE_MANAGE_SIGNATURES,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SEE_ENTITY_SENSITIVE: {
    description: ' Used to show/hide entity sensitive data in frontend and backend guard. ',
    en: 'SEE_ENTITY_SENSITIVE',
    nb: 'SEE_ENTITY_SENSITIVE',
    group: null,
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  ACCESS_TO_NEWS_FOR_BOARD: {
    description: 'Access to News for Board',
    en: 'Access to news for board',
    nb: 'Tilgang til meldinger for styret',
    group: 'access_to_news_for_board',
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'ACCESS_TO_NEWS_FOR_BOARD',
        nb: 'ACCESS_TO_NEWS_FOR_BOARD',
      },
    },
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  ACCESS_TO_NEWS_FOR_SHAREHOLDERS: {
    description: 'Access to News for Shareholders',
    en: 'Access to news for shareholder',
    nb: 'Tilgang til meldinger for aksjeeiere',
    group: 'access_to_news_for_shareholders',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',
        nb: 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',
      },
    },
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  BUYER_ADMIN: {
    description: " Used to give file group 'buyer_admin'",
    en: 'Buyer admin',
    nb: 'Kjøper-admin',
    group: 'buyer_admin',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.BUYER_ADMIN,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  SELLER_ADMIN: {
    description: " Used to give file group 'seller_admin'",
    en: 'Seller admin',
    nb: 'Selger-admin',
    group: 'seller_admin',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SELLER_ADMIN,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  CREATE_DD_GROUP: {
    description: ' Used to give access to creation of Due diligence subgroup',
    en: 'Due diligence',
    nb: 'Due diligence',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.CREATE_DD_GROUP,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  CREATE_SUB_GROUP: {
    description: ' Used to give access to creation of subgroup',
    en: 'Subgroup',
    nb: 'Undergruppe',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.CREATE_SUB_GROUP,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  ACCESS_TO_SECURITY_TAB: {
    description: ' Used to give access to security tab',
    en: 'Security',
    nb: 'Sikkerhet',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.ACCESS_TO_SECURITY_TAB,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  ACCESS_TO_SETTINGS_TAB: {
    description: ' Used to give access to settings tab',
    en: 'Settings',
    nb: 'Innstillinger',
    group: null,
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.ACCESS_TO_SECURITY_TAB,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  BUYER: {
    description: " Used to give file group 'buyer'",
    en: 'Buyer',
    nb: 'Kjøper-innsyn',
    group: 'buyer',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.BUYER,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  SELLER: {
    description: " Used to give file group 'seller'",
    en: 'Seller',
    nb: 'Selger',
    group: 'seller',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SELLER,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  DATAROOM_DOWNLOAD_ZIP: {
    description: ' Used to show/hide download zip in frontend. Backend guard.',
    en: 'Dataroom zip',
    nb: 'Dataroom zip',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  ORGBRAIN_ACADEMY: {
    description: ' Academy access ',
    en: 'ORGBRAIN ACADEMY',
    nb: 'ORGBRAIN ACADEMY',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.ORGBRAIN_ACADEMY,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  TEMPLATES_ADMIN: {
    description: ' Allow admin for templates',
    en: 'Templates admin',
    nb: 'Maler admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.templates,
    dependencies: [],
    tooltips: null,
    group: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  DATAROOM_SEE_SHOW_DELETED: {
    description: ' Used to show/hide show deleted in frontend.',
    en: 'See "show deleted"',
    nb: 'Se "vis slettet"',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  DATAROOM_COPY_FILE: {
    description: ' Used to show/hide copy in frontend.',
    en: 'Copy file',
    nb: 'Kopier fil',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  DATAROOM_SEE_FILE_DETAILS: {
    description: ' Used to show/hide file details in frontend.',
    en: 'See file details',
    nb: 'Se fildetaljer',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  DATAROOM_SEE_FILE_HISTORY: {
    description: ' Used to show/hide file history in frontend.',
    en: 'See file history',
    nb: 'Se filhistorikk',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  DATAROOM_CREATE_TASK_FROM_FILE: {
    description: ' Used to show/hide create task from file in frontend.',
    en: 'Create task from a file',
    nb: 'Lag oppgave fra en fil',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  DATAROOM_SEE_FILE_MENU: {
    description: ' Used to show/hide file menu in frontend.',
    en: 'See file menu',
    nb: 'Se filmenyen',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },

  DATAROOM_SET_FILE_STATUS: {
    description: ' Used to show/hide set file status in frontend.',
    en: 'Set file status',
    nb: 'Angi filstatus',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.MODERATE,
  },

  NEWS_ADMIN: {
    description: ' Used to show/hide news admin features in frontend.',
    en: 'Messages admin',
    nb: 'Meldinger-admin',
    group: 'news_admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.news,
    dependencies: [PermissionEnum.news],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },
  GA_NEWS_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend available for GA meetings.',
    en: 'GA Messages admin',
    nb: 'GF Meldinger-admin',
    group: 'ga_news_admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.news,
    dependencies: [PermissionEnum.news],
    tooltips: null,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },

  [PermissionEnum.DATAROOM_ADMIN]: {
    description: ' Allow admin of data room',
    en: 'Data Room admin',
    nb: 'Datarom admin',
    group: '',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: undefined,
    defaultSecurityLevel: SecurityGroupName.HIGH,
  },
  [PermissionEnum.OPENAI_USER]: {
    description: 'openai access',
    en: 'openai access',
    nb: 'openai access',
    group: '',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.OPENAI_USER,
    dependencies: [],
    tooltips: undefined,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
  [PermissionEnum.BOARDMATCH_ADMIN]: {
    description: 'Boardmatch admin',
    en: 'Boardmatch admin',
    nb: 'Boardmatch admin',
    group: '',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.BOARDMATCH_ADMIN,
    defaultSecurityLevel: SecurityGroupName.BASIC,
    dependencies: [],
    tooltips: undefined,
  },
  [PermissionEnum.CONVERSATIONS_CREATE]: {
    description: 'Allowed to create conversations',
    en: 'Allowed to create conversations',
    nb: 'Allowed to create conversations',
    group: '',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.CONVERSATIONS_CREATE,
    dependencies: [],
    tooltips: undefined,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
  [PermissionEnum.CONVERSATIONS_ADMIN]: {
    description: 'Allowed to administrate conversations',
    en: 'Allowed to administrate conversations',
    nb: 'Allowed to administrate conversations',
    group: '',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.CONVERSATIONS_ADMIN,
    dependencies: [],
    tooltips: undefined,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
  [PermissionEnum.CONVERSATIONS_LIMITED]: {
    description: 'Limited access to conversations',
    en: 'Limited access to conversations',
    nb: 'Limited access to conversations',
    group: '',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.CONVERSATIONS_LIMITED,
    dependencies: [],
    tooltips: undefined,
    defaultSecurityLevel: SecurityGroupName.BASIC,
  },
};

const reverseMapGroupKeyToPermissionTemp = {};
for (const key of Object.keys(permissionDescription)) {
  if (permissionDescription[key]?.group) {
    reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group] =
      reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group] || [];
    reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group].push(key);
  }
}
export const reverseMapGroupKeyToPermission = reverseMapGroupKeyToPermissionTemp;

export const groupDescription: {
  [group: string]: PermissionDescriptionMap;
} = Object.keys(permissionDescription).reduce((acc, key) => {
  const item = permissionDescription[key];
  if (item.group) {
    acc[item.group] = item;
  }
  return acc;
}, {}) as any;

export type CompanyTerminology = {
  [orgtype in CompanyTerminologyEnum]?: { en: string; nb: string };
};
export type PermissionDescriptionTooltips = {
  [key in PermissionEnum]: CompanyTerminology;
};

export const permissionDescriptionTooltips: PermissionDescriptionTooltips = {} as any;

for (const key of Object.keys(PermissionEnum)) {
  const perm = PermissionEnum[key];
  const permDesc = permissionDescription[perm];
  if (permDesc && permDesc.tooltips) {
    permissionDescriptionTooltips[perm] = permDesc.tooltips;
  }
}

export const group2Enum: { [group: string]: PermissionEnum } = {};

for (const permEnum of Object.keys(permissionDescription)) {
  const group: string = permissionDescription[permEnum].group;
  if (group) {
    group2Enum[group] = PermissionEnum[permEnum];
  }
}

// This is all the enums noone bnot even bogan should fuck with this  !@@@@
export const FULL_PERMISSIONS: PermissionBooleanMap = Object.keys(PermissionEnum).reduce(
  (acc, item) => {
    acc[item] = true;
    return acc;
  },
  {}
) as PermissionBooleanMap;

export const FULL_PERMISSIONS_WITHOUT_DD = {
  ...FULL_PERMISSIONS,
};

// delete FULL_PERMISSIONS_WITHOUT_DD[PermissionEnum.CREATE_DD_GROUP];

export const NIF_STANDARD = {
  ...FULL_PERMISSIONS,
};

delete NIF_STANDARD[PermissionEnum.CREATE_DD_GROUP];
delete NIF_STANDARD[PermissionEnum.CREATE_SUB_GROUP];
delete NIF_STANDARD[PermissionEnum.shareholders];
delete NIF_STANDARD[PermissionEnum.SHAREHOLDER_ADMIN];
delete NIF_STANDARD[PermissionEnum.SHAREHOLDER];
delete NIF_STANDARD[PermissionEnum.VIEW_SHARES];
delete NIF_STANDARD[PermissionEnum.ACCESS_TO_NEWS_FOR_SHAREHOLDERS];
delete NIF_STANDARD[PermissionEnum.GA_MEETING_ADMIN];
delete NIF_STANDARD[PermissionEnum.GA_NEWS_ADMIN];
delete NIF_STANDARD[PermissionEnum.ACCESS_TO_GA_MEETINGS];
delete NIF_STANDARD[PermissionEnum.surveys];
delete NIF_STANDARD[PermissionEnum.ORGBRAIN_ACADEMY];
delete NIF_STANDARD[PermissionEnum.school];

export const SHAREHOLDER_BASIS_PERMISSIONS = {
  ...FULL_PERMISSIONS_WITHOUT_DD,
};

delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.ACCESS_TO_SECURITY_TAB];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.CREATE_SUB_GROUP];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.INITIATE_BANK_ID_SIGNING];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.VIEW_SHARE_ISSUES];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.meetings];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.ACCESS_TO_GA_MEETINGS];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.ACCESS_TO_BOARD_MEETINGS];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.tasks];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.surveys];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.ORGBRAIN_ACADEMY];
delete SHAREHOLDER_BASIS_PERMISSIONS[PermissionEnum.BOARDMATCH_ADMIN];

export const SHAREHOLDER_PRO_PERMISSIONS = {
  ...FULL_PERMISSIONS_WITHOUT_DD,
};

delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.ACCESS_TO_BOARD_MEETINGS];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.BOARD_MEETING_ADMIN];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.CREATE_SUB_GROUP];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.tasks];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.surveys];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.ORGBRAIN_ACADEMY];
delete SHAREHOLDER_PRO_PERMISSIONS[PermissionEnum.BOARDMATCH_ADMIN];

export const ACADEMY_2025_PERMISSIONS = {
  ...FULL_PERMISSIONS_WITHOUT_DD,
};

delete ACADEMY_2025_PERMISSIONS[PermissionEnum.ACCESS_TO_BOARD_MEETINGS];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.BOARD_MEETING_ADMIN];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.CREATE_SUB_GROUP];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.ACCESS_TO_SECURITY_TAB];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.ACCESS_TO_SETTINGS_TAB];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.meetings];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.shareholders];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.files];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.tasks];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.surveys];
delete ACADEMY_2025_PERMISSIONS[PermissionEnum.BOARDMATCH_ADMIN];

export const BOARD_EVALUATION_PERMISSIONS = {
  ...FULL_PERMISSIONS_WITHOUT_DD,
};

delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.ACCESS_TO_BOARD_MEETINGS];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.BOARD_MEETING_ADMIN];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.CREATE_SUB_GROUP];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.ACCESS_TO_SECURITY_TAB];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.ACCESS_TO_SETTINGS_TAB];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.meetings];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.shareholders];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.files];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.tasks];
delete BOARD_EVALUATION_PERMISSIONS[PermissionEnum.BOARDMATCH_ADMIN];

export const FULL_ROLES: RoleBooleanMap = Object.keys(Role).reduce((acc, item) => {
  acc[item] = true;
  return acc;
}, {}) as RoleBooleanMap;

//
export const DD_ONLY_ROLES: OptionalRoleBooleanMap = {
  SELLER: true,
  BUYER: true,
  SELLER_ADMIN: true,
  BUYER_ADMIN: true,
};

export const NIF_ONLY_ROLES: OptionalRoleBooleanMap = {
  YOUTHSPORTS_MANAGER: true,
  POLICE_CERTIFICATE_RESPONSIBLE: true,
  // CONTROL_COMMITTEE_CHAIR: true,
  // NOMINATION_COMMITTEE_CHAIR: true,
  CASHIER: true,
};

export const DD_ONLY_PERMISSIONS: OptionalPermissionBooleanMap = {
  [PermissionEnum.SELLER]: true,
  [PermissionEnum.BUYER]: true,
  [PermissionEnum.SELLER_ADMIN]: true,
  [PermissionEnum.BUYER_ADMIN]: true,
};

export type PermissionDependancyArrayMap = { [perm in PermissionEnum]?: PermissionEnum[] };
export type PermissionDependancyMap = {
  // for convience in front end we convert to this
  [perm in PermissionEnum]?: { [perm in PermissionEnum]?: boolean };
};

export const _permissionDependancyArrayMap: PermissionDependancyArrayMap = {};

for (const perm of Object.keys(permissionDescription)) {
  const permEnum = PermissionEnum[perm];
  const deps = permissionDescription[permEnum].dependencies;
  if (deps) {
    _permissionDependancyArrayMap[permEnum] = deps;
  }
}

for (const key of Object.keys(_permissionDependancyArrayMap)) {
  const deps = _permissionDependancyArrayMap[key];

  for (const perm of deps) {
    if (permissionDescription[perm].level !== PermissionLevelEnum.HIDDEN) {
      // console.error(` ${key}  depends on FEATURE  ${perm}`);
    }
  }
}
// These are used to hide roles or permission in the UI.

export const hiddenRoles: OptionalRoleBooleanMap = {
  CONTACTPERSON: true,
  ORGBRAIN_SUPPORT: true,
  CASHIER: true,
  POLICE_CERTIFICATE_RESPONSIBLE: true,
  YOUTHSPORTS_MANAGER: true,
  ONBOARD_SUPPORT: true,
  // ROLE_1: true,
  // ROLE_2: true,
  // ROLE_3: true,
  // ROLE_4: true,
  // ROLE_5: true,
  // ROLE_6: true,
};

export const hiddenPermissions: OptionalPermissionBooleanMap = {};

for (const key of Object.keys(permissionDescription)) {
  if (permissionDescription[key].level === PermissionLevelEnum.HIDDEN) {
    hiddenPermissions[key] = true;
  }
}
