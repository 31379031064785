/*
USAGE:

Backend
        publishToChannel({
            channelName: `${ChannelEnum.UserNotification}.${inviteeId}`,
            payload: { status: SignOrderStatusEnum.TASK_EXPIRED },
          });
        }


Frontend
        this.subscription = channelService
          .getChannel(`${ChannelEnum.UserNotification}.${userId}`)
          .pipe(debounceTime(500))
          .subscribe(async (_) => {
            await this.load();
          });

*/

import { SignOrderStatusEnum } from '@orgbrain/lib-signing';
import { NotificationCodeEnum } from './bellNotificationCodes';
import { MongoId } from './mongoId';
import { ParamsForBellTemplate } from './notifications';

// This is a mess of stuff good luck  . . . .
export type PublishToChannelPayload = {
  createItem: boolean; // Anything that creates a new item e.g. message OR notification
  pulse: boolean; // Heartbeat
  action?: 'recursiveHardDelete'; // More information for channels with many actions
  direntId?: string; // The id of a file item that has changed
  status:
    | SignOrderStatusEnum
    | OrganizationChannelStatusEnum
    | 'heartbeat'
    | 'done'
    | 'error'
    | 'TASK_COMPLETED';
  meetingUpdatesForCalendar: [
    {
      deleted: boolean;
      meetingId: MongoId;
      organizationId: string;
      meeting: any;
    }
  ];
  // Annotation
  source:
    | 'adminUpdateUser'
    | 'ensureOrganizationRole'
    | 'testUserBankidAndReset2fa'
    | 'updateManulUserIdentification';
  type: 'DELETE' | 'UPDATE';
  annotationIds: MongoId[];
  clientHash: string;
  error: string;
  code: NotificationCodeEnum;
  paramsForBellTemplate?: Partial<ParamsForBellTemplate>;
};

export enum OrganizationChannelStatusEnum {
  POLICY = 'POLICY',
}

//  Used in redis so lets try to avoid collisions
export enum ChannelEnum {
  HEARTBEAT = 'HEARTBEAT',

  // The user notifaction list has changed.   - userId
  UserNotification = 'UserNotification',

  // A user has been modified so we need to rebuild permission etc.    - userId
  UserModifyNotification = 'UserModifyNotification',

  // The status of a sign order has change so we can reaload in file views.  - orderId
  SigningNotification = 'SigningNotification',

  //  Unused.   - orderId
  // Should be used to update the status of a sign order in the sign order list.
  SigningNotificationV2 = 'SigningNotificationV2',

  // ANNOTATIONS have change so we need to reload in file views.  - direntId
  ANNOTATION = 'ANNOTATION',

  //  Emmitted during recursive delete - direntId
  //  Seems not to be used yet
  OrganizationFileFilesOp = 'OrganizationFileFilesOp',

  // Change in an organization.  - organizationId
  // payload should have context.
  // OrganizationChannelStatusEnum.POLICY   - policy has changed
  Organization = 'Organization',

  //Updates to conversations, when channel changes names, users added etc
  //This is a good hack to avoid making graphQl things myself
  ConversationUpdate = 'ConversationUpdate',

  // Stress testing channel. Not for production use.
  StressTesting = 'StressTesting',

  // use for sending email validation email
  EMAIL_SECRET_CLICK = 'EMAIL_SECRET_CLICK',
}
