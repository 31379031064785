<div mat-dialog-title class="dialog-title-with-close-button">
  <h1 style="margin-top: 16px">
    {{ data.title }}
  </h1>
</div>

<mat-dialog-content class="info-box">
  <p [innerHtml]="trustedHtml" class="mat-body"></p>
  <mat-progress-bar
    *ngIf="data.progress?.showProgressBar && data.progress.percentage"
    class="w-100"
    mode="determinate"
    [value]="data.progress?.percentage"
  >
  </mat-progress-bar>

  <div
    *ngIf="!data.progress || (data.progress?.showProgressBar && !data.progress.percentage)"
    class="d-flex w-100 justify-content-center align-items-center"
  >
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="data.progress && !data.progress?.hideTextProgress">
    {{ data.progress?.loaded }}<span *ngIf="data.progress?.total">/{{ data.progress?.total }}</span>
  </ng-container>

  {{ data?.progress?.error }}

  <mat-dialog-actions align="end" *ngIf="data.progress?.error">
    <button
      mat-flat-button
      color="primary"
      class="action-button"
      cdkFocusInitial
      mat-dialog-close
      id="confirm_dialog_button"
    >
      {{ data.dismiss || 'Dismiss' }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
