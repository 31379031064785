export enum ModuleEnum {
  search = 'search',
  home = 'home',
  meetings = 'meetings',
  shareholders = 'shareholders',
  communication = 'communication',
  files = 'files',
  tasks = 'tasks',
  chat = 'chat',
  boardschool = 'boardschool',
  surveys = 'surveys',
  academy = 'academy',
  boardmatch_org = 'boardmatch-org',
  organization = 'organization',
  expertHelp = 'expertHelp',
  boardmatch_candidate = 'boardmatch',
  investments = 'investments',
  templates = 'templates',
  configure = 'configure',
  admin = 'admin',
  client = 'client',
  external = 'external',
}

export const moduleToIcon = {
  [ModuleEnum.search]: 'search',
  [ModuleEnum.home]: 'home',
  [ModuleEnum.meetings]: 'gavel',
  [ModuleEnum.shareholders]: 'group',
  [ModuleEnum.communication]: 'feedback',
  [ModuleEnum.files]: 'folder',
  [ModuleEnum.tasks]: 'task',
  [ModuleEnum.chat]: 'chat',
  [ModuleEnum.boardschool]: 'school',
  [ModuleEnum.surveys]: 'inventory',
  [ModuleEnum.academy]: 'school',
  [ModuleEnum.boardmatch_org]: 'join_right',
  [ModuleEnum.organization]: 'domain',
  [ModuleEnum.expertHelp]: 'psychology',
  [ModuleEnum.boardmatch_candidate]: 'join_left',
  [ModuleEnum.investments]: 'monitoring',
  [ModuleEnum.templates]: 'templates',
  [ModuleEnum.configure]: 'settings',
  [ModuleEnum.admin]: 'shield_person',
  [ModuleEnum.client]: 'shield_person',
  [ModuleEnum.external]: 'partner_exchange',
};
